import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';



type Props = {
    setOpen: any;
    deletar: any;
    opendeletemodal: boolean;


};
export default function DeleteModal({opendeletemodal,setOpen,deletar}:Props) {

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const theme = useTheme();
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>

                <Dialog
                open={opendeletemodal}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
                >
                <DialogTitle id="responsive-dialog-title">
                   Deseja deletar ?
                </DialogTitle>
                    <DialogContent>
                    <DialogContentText>
                  Tem certeza que deseja deletar ?
                </DialogContentText>
                </DialogContent>
                <DialogActions>
                <Button autoFocus onClick={handleClose}>
                    Cancelar
                    </Button>
                    <Button onClick={deletar} autoFocus>
                Deletar
                </Button>
                </DialogActions>
                </Dialog>
    </>
);
}