import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { CustomAvatar } from "src/components/custom-avatar";
import {Card, Grid, IconButton, Typography} from '@mui/material';
// @mui
import { styled } from '@mui/material/styles';
import {useSettingsContext} from '../../../../components/settings';

import '../DetalhesAgendamento.css';

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// routes
import {PATH_DASHBOARD} from '../../../../routes/paths';
import axios from "../../../../utils/axios";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";

import Avatar from './avatar.png'

type Props = {
    AgenDados: any;
};


export default function CardInfosAtendimento({AgenDados}:Props) {

    return (

        
            <>
                <Card sx={{my:1}} className='CardInfoAtendimentoGeral'>
                    <Typography variant="subtitle2" >Valor do Atendimento</Typography>
                    <Typography variant="h3" >R${AgenDados.ValorTotal}</Typography>

                   

                    <div className='dadosCardInfoAtendimento'>
                        <Typography>Taxas Eyhe</Typography>
                        <Typography>25%</Typography>
                    </div>

                    <div className='dadosCardInfoAtendimento'>
                        <Typography>Total</Typography>
                        <Typography>R${AgenDados.ValorTotal}</Typography>
                    </div>

                    <div className='dadosCardInfoAtendimento'>
                        <Typography>Status</Typography>
                        <Typography>{AgenDados.Status}</Typography>
                    </div>

                </Card>

            </>
    );
}
