import * as React from 'react';
import {CssBaseline, Box, Container, Typography,Switch, Tooltip, Button, Link,Grid, FormControlLabel, Divider } from "@mui/material";
import { useForm, Controller } from 'react-hook-form';
import {useEffect, useState, useCallback, useRef} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

// @mui
import {styled} from '@mui/material/styles';
import Label from '../../../../components/label';
import {useSettingsContext} from '../../../../components/settings';
// eslint-disable-next-line import/no-cycle
import notificacaoVideo from '../img/notificacao_video.png'

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../../routes/paths';
import axios from "../../../../utils/axios";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";
import {useSnackbar} from "../../../../components/snackbar";
import DialogMudarStatus from './ModalStatus';
// eslint-disable-next-line import/no-cycle




// ----------------------------------------------------------------------


export default function ToggleStatus() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [statusa, setStatus] = useState('Disponivel');

    const { user,mudarstatus,getuser } = useAuthContext();


    const handleStatusChange = async (status: any) => {


        try {

            if (mudarstatus) {
                await mudarstatus({status});
            }


            setStatus(user?.StatusAtendimento);

           // document.location.reload();
           // navigate(0);
        } catch (error) {
            enqueueSnackbar(`${error.message}`, {variant: 'error'});
        }
    };

  



    useEffect(() => {

        setStatus(user?.StatusAtendimento);

    }, [user]);


    useEffect(() => {

        getuser();

    }, [getuser]);


    const [openDialogMudaStatus, setOpenDialogMudaStatus] = useState(false);

    const handleClickOpenDialogMudaStatus = () => {
      setOpenDialogMudaStatus(true);
    };
  
    const handleCloseDialogMudaStatus = () => {
      setOpenDialogMudaStatus(false);
    };

    return (
        <>
            <Divider/>
                <FormControlLabel
                    labelPlacement="start"
                    control={
                        <Switch
                        checked={statusa === 'Disponivel'}
                        onChange={()=>handleClickOpenDialogMudaStatus()}
                        />
                    }
                    label={
                        <Grid sx={{ mx: 1, my: 0}}>
                            
                            <Label
                                color={statusa === 'Disponivel' ? 'success' : 'error'}
                                sx={{ textTransform: 'uppercase' }}
                            >
                                {statusa === 'Disponivel' ? 'Online' : 'Offline' }
                            </Label>
                        </Grid>
                    }
                    sx={{ mx: 0, my: 1, width: 1, justifyContent: 'space-between' }}
                />
            <Divider/>
            

            <DialogMudarStatus openDialogMudarStatus={openDialogMudaStatus} handleCloseDialogMudarStatus={handleCloseDialogMudaStatus} />
            </>
    );
}