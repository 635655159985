import * as Yup from 'yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Iconify from 'src/components/iconify';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Paper,
} from '@mui/material';
import RHFtelefone from 'src/components/hook-form/RHFtelefone';
import RHFValor from 'src/components/hook-form/RHFValor';
import RHFValorProfissional from 'src/components/hook-form/RHFValorProfissional';
import Drawing from 'src/components/assinatura/assinatura';
// utils
import { fData } from '../../../utils/formatNumber';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// @types
import { IUserAccountGeneral } from '../../../@types/user';
// assets
import { countries } from '../../../assets/data';
// components
import Label from '../../../components/label';
import { CustomFile } from '../../../components/upload';
import { useSnackbar } from '../../../components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFSelectBandeiras,
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
} from '../../../components/hook-form';
import axios from '../../../utils/axios';
import useCopyToClipboard from '../../../hooks/useCopyToClipboard';
import CardPrecosAtendimentos from './componentesEditarPerfil/CardPrecosAtendimentos';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

// ----------------------------------------------------------------------

interface FormValuesProps extends Omit<IUserAccountGeneral, 'avatarUrl'> {
  avatarUrl: CustomFile | string | null;
}

type Props = {
  isEdit?: boolean;
  currentUser?: any;
};

export default function UserNewEditFormProfissional({ isEdit = false, currentUser }: Props) {
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();
  const { copy } = useCopyToClipboard();

  const NewUserSchema = Yup.object().shape({
    nome: Yup.string().required('Nome é obrigatorio'),
    email: Yup.string().required('Email é obrigatorio').email('Email têm de ser valido'),
    NumeroWhatsApp: Yup.string().required('whatsapp é obrigatorio'),
    estado: Yup.string().required('Estado é obrigatorio'),
    CodigoPais: Yup.string().required('Pais é obrigatorio'),
    cidade: Yup.string().required('Cidade é obrigatorio'),
    Biografia: Yup.string().required('Biografia é obrigatorio'),
    avatar: Yup.mixed().required('Avatar é obrigatorio'),
    CpfCNPJ: Yup.mixed().required('CPF é obrigatorio'),
    ValorAtendimento: Yup.mixed().required('Valor Atendimento é obrigatorio'),
  });

  const defaultValues = useMemo(
    () => ({
      nome: currentUser?.nome || '',
      email: currentUser?.email || '',
      NumeroWhatsApp: currentUser?.NumeroWhatsApp || '',
      estado: currentUser?.estado || '',
      cidade: currentUser?.cidade || '',
      CodigoPais: currentUser?.CodigoPais || '',
      Biografia: currentUser?.Biografia || '',
      CRP: currentUser?.crp || '',
      CpfCNPJ: currentUser?.CpfCNPJ || '',
      EPSI: currentUser?.EPSI || '',
      ValorAtendimento: currentUser?.ValorAtendimento || '',
      TempoExperiencia: currentUser?.TempoExperiencia || '',
      Formacao: currentUser?.Formacao || '',
      avatar: currentUser?.avatar || null,
      atendimentoGratuito: currentUser?.atendimentoGratuito || true,
      StatusAtendimento: currentUser?.StatusAtendimento || 'Disponivel',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (isEdit && currentUser) {
      reset(defaultValues);
    }
    if (!isEdit) {
      reset(defaultValues);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEdit, currentUser]);

  const [checked, setChecked] = useState([]);
  const [issalvo, setissalvo] = useState(false);
  const [perfilpublico, setperfilpublico] = useState<any>('');
  const [copiado, setcopiado] = useState<any>(false);

  const [issalvovideo, setissalvovideo] = useState(false);
  const [video, setvideo] = useState<any>(null);
  const [imagemassinaturadado, setimagemassinaturadado] = useState<any>(null);

  useEffect(() => {
    function urlamigavel(texto: any) {
      return texto
        .toString()
        .toLowerCase()
        .replace(/[àÀáÁâÂãäÄÅåª]+/g, 'a') // Special Characters #1
        .replace(/[èÈéÉêÊëË]+/g, 'e') // Special Characters #2
        .replace(/[ìÌíÍîÎïÏ]+/g, 'i') // Special Characters #3
        .replace(/[òÒóÓôÔõÕöÖº]+/g, 'o') // Special Characters #4
        .replace(/[ùÙúÚûÛüÜ]+/g, 'u') // Special Characters #5
        .replace(/[ýÝÿŸ]+/g, 'y') // Special Characters #6
        .replace(/[ñÑ]+/g, 'n') // Special Characters #7
        .replace(/[çÇ]+/g, 'c') // Special Characters #8
        .replace(/[ß]+/g, 'ss') // Special Characters #9
        .replace(/[Ææ]+/g, 'ae') // Special Characters #10
        .replace(/[Øøœ]+/g, 'oe') // Special Characters #11
        .replace(/[%]+/g, 'pct') // Special Characters #12
        .replace(/\s+/g, '-') // Replace spaces with -
        .replace(/^-+/, '') // Trim - from start of text
        .replace(/-+$/, ''); // Trim - from end of text
    }

    const url = `https://atendentes.eyhe.com.br/ver-perfil/${currentUser.id}/${urlamigavel(
      currentUser.nome
    )}`;

    setperfilpublico(url);
  }, [currentUser]);

  const onSubmit = async (data: FormValuesProps) => {
    try {
      const response = await axios.post('/areadoatendente/editarperfilprofissional', data);
      const { user } = response.data;
      enqueueSnackbar('Editado com sucesso!');
    } catch (error) {
      enqueueSnackbar(`${error.message}`, { variant: 'error' });
    }
  };

  const salvarsttaus = async (status: any) => {
    try {
      const response = await axios.post('/areadoatendente/mudarstatus', { status });

      enqueueSnackbar('Salvo com sucesso!');

      navigate(0);
    } catch (error) {
      enqueueSnackbar(`${error.message}`, { variant: 'error' });
    }
  };

  const [assuntosdados, setAssuntosdados] = useState<any[]>([]);

  const getavaliacoes = useCallback(async () => {
    try {
      const response = await axios.post(`/areadoatendente/getassuntos`);

      const { assuntos } = response.data;
      setAssuntosdados(assuntos);

      assuntos.forEach((item: any) => {
        setChecked((state: any) => ({ ...state, [item.id]: item.isativo }));
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getavaliacoes();
  }, [getavaliacoes]);

  useEffect(() => {
    setvideo(currentUser?.AVideo);
  }, [setvideo, currentUser]);

  useEffect(() => {
    setimagemassinaturadado(currentUser?.assinatura);
  }, [setvideo, currentUser]);

  const handleDrop = useCallback(
    async (acceptedFiles: File[]) => {
      const file = acceptedFiles[0];

      const base64 = await convertBase64(file);

      if (file) {
        setValue('avatar', base64, { shouldValidate: true });
      }
    },
    [setValue]
  );

  const handleDropvideo = async (event: any) => {
    setvideo(event.target.value);
  };

  const salvarAssuntos = async () => {
    setissalvo(true);
    try {
      const response = await axios.post('/areadoatendente/salvarassuntos', checked);

      enqueueSnackbar('Salvo com sucesso!');
      setissalvo(false);
    } catch (error) {
      enqueueSnackbar(`${error.message}`, { variant: 'error' });
    }
  };

  const salvarVideo = async () => {
    setissalvovideo(true);
    try {
      const response = await axios.post('/areadoatendente/salvarvideo', { video });

      enqueueSnackbar('Salvo com sucesso!');
      setissalvovideo(false);
    } catch (error) {
      enqueueSnackbar(`${error.message}`, { variant: 'error' });
      setissalvovideo(false);
    }
  };

  const [imagemassinatura, setimagem] = useState<any>('');
  const [assinaturasalva, setassinaturasalva] = useState(false);
  const salvarassinatura = async () => {
    setassinaturasalva(true);
    try {
      const response = await axios.post('/areadoatendente/salvarassinatura', { imagemassinatura });

      enqueueSnackbar('Salvo com sucesso!');
      setassinaturasalva(false);
    } catch (error) {
      enqueueSnackbar(`${error.message}`, { variant: 'error' });
      setassinaturasalva(false);
    }
  };

  const convertBase64 = (file: any) =>
    new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });

  const mudarcheck = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked((state: any) => ({ ...state, [event.target.name]: event.target.checked }));

    console.log(checked);
  };

  const limpar = () => {
    setimagemassinaturadado('');
  };

  const copiar = () => {
    setcopiado(true);
    copy(perfilpublico);
    enqueueSnackbar(`Copiado com sucesso!`, { variant: 'success' });
    setTimeout(() => {
      setcopiado(false);
    }, 1000);
  };

  return (
    <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Card sx={{ pt: 10, pb: 5, px: 3 }}>
            {isEdit && (
              <Label
                color={values.StatusAtendimento === 'Disponivel' ? 'success' : 'error'}
                sx={{ textTransform: 'uppercase', position: 'absolute', top: 24, right: 24 }}
              >
                {values.StatusAtendimento}
              </Label>
            )}

            <Box sx={{ mb: 5 }}>
              <RHFUploadAvatar
                name="avatar"
                maxSize={1145728}
                onDrop={handleDrop}
                helperText={
                  <Typography
                    variant="caption"
                    sx={{
                      mt: 2,
                      mx: 'auto',
                      display: 'block',
                      textAlign: 'center',
                      color: 'text.secondary',
                    }}
                  >Clique no circulo acima para mudar foto <br />
                    Permitidos formatos *.jpeg, *.jpg, *.png, *.gif
                    <br /> de tamanho máximo {fData(1145728)}
                  </Typography>
                }
              />
            </Box>


            <LoadingButton
                type="submit"
                fullWidth
                variant="contained"
                sx={{ py: 1.5, px: 3 }}
                color="primary"
                loading={isSubmitting}
            >
              {!isEdit ? (
                  <>
                    <Iconify icon="eva:plus-outline" sx={{ mr: 1 }} /> Criar Usuário
                  </>
              ) : (
                  <>
                    <Iconify icon="eva:checkmark-circle-outline" sx={{ mr: 1 }} /> Salvar alterações
                  </>
              )}
            </LoadingButton>
          </Card>

          <Button
            onClick={() => {
              navigate(PATH_DASHBOARD.rotina);
            }}
            variant="contained"
            sx={{ py: 1.5, my: 2, textTransform: 'uppercase' }}
            color="primary"
            fullWidth
          >
            {' '}
            <Iconify icon="eva:calendar-outline" sx={{ mr: 1 }} /> Configurar rotina de atendimento
          </Button>
        </Grid>

        <Grid item xs={12} md={8}>
          <Card sx={{ p: 3, mb: 2 }}>
            <Box sx={{ flexGrow: 1 }}>
              <Grid container>
                <Grid xs={8} md={8}>
                  <Item>
                    {' '}
                    <TextField
                      sx={{ width: '100%' }}
                      value={perfilpublico}
                      disabled
                      label="Perfil Público"
                    />
                  </Item>
                </Grid>

                <Grid xs={4} md={4}>
                  <Item>
                    <LoadingButton
                      onClick={copiar}
                      variant="contained"
                      sx={{ py: 2, px: 3, width: '100%' }}
                      color="primary"
                      loading={copiado}
                    >
                      <>Copiar Link </>
                    </LoadingButton>
                  </Item>
                </Grid>
              </Grid>
            </Box>
          </Card>

          <Card sx={{ p: 3 }}>
            <Typography sx={{ mb: 2 }} className="TitlesEditarPerfil">
              Informações básicas:{' '}
            </Typography>

            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
            >
              <RHFTextField name="nome" label="Nome" />
              <RHFTextField name="email" label="Email" />
              <RHFtelefone name="NumeroWhatsApp" label="Whatsapp" />

              <RHFSelectBandeiras name="CodigoPais" />

              <RHFTextField name="estado" label="Estado" />
              <RHFTextField name="cidade" label="Cidade" />
              <RHFTextField name="CRP" label="CRP" />
              <RHFTextField name="CpfCNPJ" label="CPF" />
              <RHFTextField name="EPSI" label="E-Psi" />
              <RHFTextField name="TempoExperiencia" label="Tempo de Experiência" />
            </Box>

            <Box
              rowGap={3}
              columnGap={2}
              display="grid"
              gridTemplateColumns={{
                xs: 'repeat(1, 1fr)',
                sm: 'repeat(2, 1fr)',
              }}
              sx={{ mt: 3 }}
            >
              <RHFTextField
                placeholder="Bio"
                label="Bio"
                multiline
                name="Biografia"
                rows={4}
                fullWidth
              />

              <RHFTextField
                placeholder="Formação"
                label="Formação"
                multiline
                name="Formacao"
                rows={4}
                fullWidth
              />
            </Box>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                variant="contained"
                sx={{ py: 1.5, px: 3 }}
                color="primary"
                loading={isSubmitting}
              >
                {!isEdit ? (
                  <>
                    <Iconify icon="eva:plus-outline" sx={{ mr: 1 }} /> Criar Usuário
                  </>
                ) : (
                  <>
                    <Iconify icon="eva:checkmark-circle-outline" sx={{ mr: 1 }} /> Salvar alterações
                  </>
                )}
              </LoadingButton>
            </Stack>
          </Card>

          <CardPrecosAtendimentos currentUser={currentUser} />

          <Card sx={{ p: 3, my: 3 }}>
            <Typography sx={{ mb: 2 }} className="TitlesEditarPerfil">
              Assuntos para atendimentos:{' '}
            </Typography>

            <FormGroup>
              <Box
                rowGap={1}
                columnGap={1}
                display="grid"
                gridTemplateColumns={{
                  xs: 'repeat(1, 1fr)',
                  sm: 'repeat(3, 1fr)',
                }}
              >
                {assuntosdados.map((assunto: any, index: any) => (
                  <FormControlLabel
                    key={index}
                    control={
                      <Checkbox
                        name={assunto.id}
                        checked={checked[assunto.id]}
                        onChange={mudarcheck}
                      />
                    }
                    label={assunto.nome}
                  />
                ))}
              </Box>
            </FormGroup>

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                variant="contained"
                sx={{ py: 1.5, px: 3 }}
                loading={issalvo}
                onClick={salvarAssuntos}
              >
                <Iconify icon="eva:checkmark-circle-outline" sx={{ mr: 1 }} /> Salvar alterações
              </LoadingButton>
            </Stack>
          </Card>

          <Card sx={{ p: 3, my: 3 }}>
            <Typography sx={{ mb: 2 }} className="TitlesEditarPerfil">
              Vídeo de apresentação:{' '}
            </Typography>

           

            <FormGroup>
              <TextField
                value={video}
                helperText="Coloque aqui a url de seu video."
                id="demo-helper-text-aligned"
                label="Url do video do Youtube"
                onChange={handleDropvideo}
              />
            </FormGroup>

            

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                type="submit"
                sx={{ py: 1.5, px: 3 }}
                variant="contained"
                onClick={salvarVideo}
                loading={issalvovideo}
              >
                <Iconify icon="eva:checkmark-circle-outline" sx={{ mr: 1 }} /> Salvar alterações
              </LoadingButton>
            </Stack>
          </Card>

          <Card sx={{ p: 3, my: 3 }}>
            <Typography sx={{ mb: 2 }} className="TitlesEditarPerfil">
              Sua assinatura:{' '}
            </Typography>

            {imagemassinaturadado === null || imagemassinaturadado === '' ? (
              <div>
                <Drawing width="100%" height="100px" setimagem={setimagem} />
              </div>
            ) : (
              <div>
                <img className="CanvaAssinatura" src={imagemassinaturadado} width="100%" alt="" />
                <Button onClick={limpar} color="primary">
                  {' '}
                  <Iconify icon="eva:repeat-fill" sx={{ mr: 1 }} /> Cria nova assinatura
                </Button>
              </div>
            )}

            <Stack alignItems="flex-end" sx={{ mt: 3 }}>
              <LoadingButton
                onClick={salvarassinatura}
                loading={assinaturasalva}
                sx={{ py: 1.5, px: 3 }}
                variant="contained"
              >
                <Iconify icon="eva:checkmark-circle-outline" sx={{ mr: 1 }} /> Salvar alterações
              </LoadingButton>
            </Stack>
          </Card>

          
        </Grid>
      </Grid>
    </FormProvider>
  );
}
