
import { useState,useEffect ,useRef,useCallback } from 'react';
// @mui

import { alpha, useTheme } from '@mui/material/styles';
import { Box, Card, Paper,FormControl,InputLabel,MenuItem,Select,Rating,Button, Typography, CardContent,SelectChangeEvent, Divider, IconButton } from '@mui/material';
import { CustomAvatar } from "src/components/custom-avatar";
import Iconify from 'src/components/iconify/Iconify';
import {useNavigate} from "react-router-dom";
import ProfilePicture from '../img/profile_picture.png';
import AvaliacaoIndividual from './AvaliacaoIndividual';
import { useSnackbar } from '../../../../components/snackbar';
import axios from '../../../../utils/axios';
import {PATH_DASHBOARD} from "../../../../routes/paths";


// utils

// components

// ----------------------------------------------------------------------



export default function Avaliacoes() {

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [avalia, setavalia] = useState<any>({});
    const [estrelas, setestrelas] = useState<any>(2);
    const [listaravaliacao, setlistaravaliacao] = useState<any[]>([]);

    const getavalia = useCallback(async () => {
        try {
            const response = await axios.post('/areadoatendente/avaliaca');

            const {avaliaca,avaliacao} = response.data;


            setavalia(avaliaca);
            setestrelas(avaliaca?.estrelas);
            setlistaravaliacao(avaliacao);


        } catch (error) {
            enqueueSnackbar(`${ error.message}`, { variant: 'error' });
        }
    },[enqueueSnackbar]);

    useEffect(() => {

        getavalia();

    }, [getavalia]);

  return (
    <>
        <div className='CardAvaliacoesDashboard'>
            
            <Typography className='ResumoHeaderAvaliacoesDashboard'>Resumo geral</Typography>
               
            <div className='AvaliacaoIndividualCard'>
                <Typography className='MediaAvaliacoesDashInicial'>{avalia?.estrelas}</Typography>
                <Rating name="read-only" value={estrelas} readOnly />
            </div>

            <Divider/>

            <div> 
                <Typography className='UltimasAvaliacoesTituloDashboard'>Últimas Avaliações</Typography>
                
                {listaravaliacao.map((avaliacaouni:any,index:any) => (
                <AvaliacaoIndividual avaliacaounica={avaliacaouni} key={index}/>
                ))}
                
            </div>


            <Button variant='outlined' fullWidth className='BtnVerAvaliacoes' sx={{py: 1.5}} onClick={()=>{ navigate(PATH_DASHBOARD.avaliacoes,{replace:true})}}>Ver todas avaliações</Button>

        </div>

    </>
  );
}
