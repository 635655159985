
import {useEffect, useState, useCallback, useMemo} from 'react';
import { Helmet } from 'react-helmet-async';
// sections
import { VerPerfil } from 'src/sections/@dashboard/VerPerfil';
import {useParams} from "react-router-dom";
import axios from "../utils/axios";





export default function VerPerfilPage() {
    const {idAtendente = ''} = useParams();
  const [info, setInfo] = useState<any>({ socialLinks:{facebookLink: '' , instagramLink: '', linkedinLink: '' , twitterLink: '' }  }); 
  const [posts, setPosts] = useState<any>({data:[]});
  const [Atendented, setAtendente] = useState<any>({});
  const [assunto, setassuntos] = useState<any>([]);
  const [avaliacoe, setavaliacoes] = useState<any>({data:[]});
  const [atedimento, setatedimento] = useState<any>(14);

    const getAtendentes = useCallback(async () => {
        try {
            const response = await axios.post('/areadoheroi/verPerfil', {
                idAtendente
            });

            const {post,Atendente,atedimentos,avaliacoes,assuntos} = response.data;

            setPosts(post);
            setAtendente(Atendente);
            setatedimento(atedimentos);
            setavaliacoes(avaliacoes);
            setassuntos(assuntos);

        } catch (error) {
            console.error(error);
        }
    }, [idAtendente]);



    useEffect(() => {

        getAtendentes();

    }, [getAtendentes]);

  return (
    <>
      <Helmet>
        <title> Ver Perfil - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

    <VerPerfil info={info} posts={posts} Atendente={Atendented} atedimentos={atedimento} avaliacoes={avaliacoe} assuntos={assunto} />
      
    </>
  );
}
