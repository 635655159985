import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Typography } from '@mui/material';
// components
import { useSettingsContext } from '../components/settings';
import { DetalhesAgendamento } from '../sections/@dashboard/DetalhesAgendamento';

// ----------------------------------------------------------------------
type Props = {
    socket: any;
};
export default function DetalhesAgendamentoPage() {

  return (
    <>
      <Helmet>
        <title> Detalhes do Agendamento do Atendente - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <DetalhesAgendamento/>
    </>
  );
}
