import * as React from 'react';
import {CssBaseline, Box, Container, Typography, Tooltip, Button, Link, Grid, IconButton, Paper} from "@mui/material";
import {useEffect, useState, useCallback, useRef} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';


import "./notifica.css";
// @mui
import {styled} from '@mui/material/styles';
import {useSettingsContext} from '../../../../components/settings';
// eslint-disable-next-line import/no-cycle
import notificacaoPagamento from './img/notificacao_pagamento.png'

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../../routes/paths';
import axios from "../../../../utils/axios";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";
import anjoChegou from "../../SalaEspera/img/anjoChegou.webp";
import anjoNaoChegou from "../../SalaEspera/img/anjoNaoChegou.webp";
// eslint-disable-next-line import/no-cycle

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


// ----------------------------------------------------------------------
type props = {
    fecharmodal: any,
    dados: any,
};


export default function NotificaAnjoNaoChegou({fecharmodal,dados}:props) {






    return (
        <>
            <CssBaseline/>
            <Container maxWidth="xl" className='GlobalChamadaEncerrada'>

                <div className='DialogPaddingAnjoNaoChegouSaladeEspera'>
                    <Grid
                        container
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                    >
                        <Item>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close"
                                onClick={fecharmodal}
                            >
                                <Iconify icon="eva:chevron-left-fill"/>
                            </IconButton>
                        </Item>

                        <Item>
                            <IconButton
                                edge="start"
                                color="inherit"
                                aria-label="close"
                                onClick={fecharmodal}
                            >
                                <Iconify icon="eva:close-fill"/>
                            </IconButton>
                        </Item>
                    </Grid>

                    <Grid
                        container
                        direction="column"
                        justifyContent="center"
                        alignItems="center"
                        className='MainDialogSaladeEsperaAnjoChegou'
                    >

                        <img src={anjoNaoChegou} className='ImagemSalaDeEsperaAnjoChegou' alt='Aguarde'/>

                        <Typography className='TituloSalaDeEsperaAnjoNaoChegou'> Este Anjo não pode atender </Typography>
                        <Typography className='SubTituloSalaDeEsperaAnjoNaoChegou'>Fique tranquilo, temos outras possibilidades </Typography>

                        <div className='CTAsSalaDeEsperaAnjoNaoChegou'>
                            <Button className='ButtonSalaDeEsperaAnjoNaoChegou' > <Iconify icon="eva:people-fill"/> Chamar Anjos de plantão</Button>
                            <Button className='ButtonSalaDeEsperaAnjoNaoChegou'> <Iconify icon="eva:calendar-outline"/> Agendar acolhimento</Button>
                        </div>

                        <div className='SubCTAsSalaDeEsperaAnjoNaoChegou'>
                            <Button className='ButtonOutlineSalaDeEsperaAnjoNaoChegou'> Como funciona os Anjos de plantão? </Button>
                        </div>
                    </Grid>



                </div>



            </Container>

            </>
    );
}