import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Accordion , AccordionSummary , AccordionDetails, Typography ,Divider, Grid , Button, Card, Paper , styled, FormGroup, FormControlLabel, Checkbox   } from "@mui/material";
import Iconify from "../../../../components/iconify";
import axios from "../../../../utils/axios";
import ChatNavItem from "../../chat/nav/ChatNavItem";
import '../Rotina.css'
// ----------------------------------------------------------------------


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  type Props = {
    diaDaSemana: any;
    abrirAcoesInteligentes: any;
    selecionarTodaManha: () => void;
    selecionarTodaTarde: () => void;
    selecionarTodaNoite: () => void;
    marcarTodosCheckbox: () => void;
    desmarcarTodosCheckbox: () => void;
    estadoManha: any;
    estadoTarde: any;
    estadoNoite: any;
  };

const IconSelecionaTudo = (
    <Iconify icon='eva:toggle-right-fill'/>
)

const IconDesmarcaTudo = (
    <Iconify icon='eva:toggle-left-outline'/>
)

const IconManhas = (
    <Iconify icon='eva:sun-outline'/>
)

const IconTardes = (
    <Iconify icon='eva:sun-fill'/>
)

const IconNoites = (
    <Iconify icon='eva:moon-outline'/>
)



export default function AcoesInteligentes({ diaDaSemana, abrirAcoesInteligentes, selecionarTodaManha, selecionarTodaTarde, selecionarTodaNoite, marcarTodosCheckbox, desmarcarTodosCheckbox, estadoManha, estadoTarde,estadoNoite }: Props) {

    return (
            <>
    
            {abrirAcoesInteligentes ?(
            <Card sx={{ p: 3 }}>
            <Typography className='TituloAcoesInteligentes'>Ações Inteligentes</Typography>
            <Typography className='DiaAcaoInteligente'>({diaDaSemana})</Typography>

                <Button sx={{my: 1}} variant='text' startIcon={IconSelecionaTudo} onClick={marcarTodosCheckbox}>
                    Marcar todos
                </Button>
                <Divider/>
                <Button sx={{my: 1}} variant='text' startIcon={IconDesmarcaTudo} onClick={desmarcarTodosCheckbox}>
                    Desmarcar todos
                </Button>
                <Divider/>
                <Button sx={{my: 1}} variant='text' startIcon={IconManhas} onClick={selecionarTodaManha}>
                    {estadoManha ? ('Desmarcar Manhã') : ('Marcar Manhã') }
                </Button>
                <Divider/>
                <Button sx={{my: 1}} variant='text' startIcon={IconTardes} onClick={selecionarTodaTarde}>
                    {estadoTarde ? ('Desmarcar Tarde') : ('Marcar Tarde') }
                </Button>
                <Divider/>
                <Button sx={{my: 1}} variant='text' startIcon={IconNoites} onClick={selecionarTodaNoite}>
                    {estadoNoite ? ('Desmarcar Noite') : ('Marcar Noite') }
                </Button>
        
            
            </Card>) : ('') }
            


            </>
    );
}
