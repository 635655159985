import { Helmet } from 'react-helmet-async';
// sections
import { EditarPerfil } from '../sections/@dashboard/EditarPerfil';



export default function EditarPerfilPage() {
  return (
    <>
      <Helmet>
        <title>Editar Perfil do Atendente - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <EditarPerfil/>
    </>
  );
}
