import {Divider, IconButton, List, MenuItem, Paper, Select, Stack, Tooltip, Typography, Popover, Button} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import Iconify from "../../../../components/iconify";

import  "./saladevideo.css";

type props = {
    UrlVoltar: any;
};
export default function HeaderSaladeVideo({UrlVoltar}:props) {


    const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
      };

      const handleClose = () => {
        setAnchorEl(null);
      };

      const open = Boolean(anchorEl);
      const id = open ? 'simple-popover' : undefined;



    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(1),
        textAlign: 'center',
        color: theme.palette.text.secondary,
    }));

    const UrlVoltar2 = () => {
        console.log('teste header');
        UrlVoltar();
    };
    
    
    return (
        <>
<div className='headervideo'>

        <Stack direction="row" spacing={2} justifyContent="space-between" className="ItemsHeaderVideo">
                <Item>
                    <Button
                    onClick={UrlVoltar2}
                    size="small"
                    variant="outlined"
                    color="primary"
                    >
                        <Iconify icon="eva:arrow-ios-back-fill" />
                        Chat
                    </Button>
                    
                    
                </Item>
                

            </Stack>


            

</div>



        </>
);
}
