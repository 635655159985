import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import  "./ComponetesAtedentes/atendentes.css";
import Atendente from './ComponetesAtedentes/atendente';

import FiltrarAtendente from './ComponetesAtedentes/FiltrarAtendente';
import axios from "../../../utils/axios";
import ChatNavItem from "../chat/nav/ChatNavItem";
// ----------------------------------------------------------------------


export default function Atendetentes() {
    const [Atendentes, setAtendentes] = useState<any[]>([]);
    const [assunto, setassunto] = useState<any>('');
    const [query, setquery] = useState<any>('');
    const [tipoprofissional, settipoprofissional] = useState<any>('');
    const [genero, setgenero] = useState<any>('');
    const [idade, setidade] = useState<any>('');
    const [avaliacao, setavaliacao] = useState<any>('');
    const [preco, setpreco] = useState<any>('');

    const getAtendentes = useCallback(async () => {
        try {
            const response = await axios.post('/areadoatendente/atendentes', {
                assunto,
                query,
                tipoprofissional,
                idade,
                genero,
                avaliacao,
                preco,
            });

            const {atendentes} = response.data;
            const {data} = atendentes;

            setAtendentes(data)

        } catch (error) {
            console.error(error);
        }
    }, [assunto,query,tipoprofissional,idade,genero,avaliacao,preco]);



    useEffect(() => {

        getAtendentes();

    }, [getAtendentes]);





    return (
            <>
    
                <FiltrarAtendente setassunto={setassunto} setquery={setquery} settipoprofissional={settipoprofissional} setgenero={setgenero} setidade={setidade} setpreco={setpreco} setavaliacao={setavaliacao}/>
                {Atendentes.map((Atendentevar:any,index:any) => (
                    <Atendente atendente={Atendentevar}  key={index}/>
                ))}



            </>
    );
}
