import { m } from 'framer-motion';
import { useState, useRef, useEffect } from 'react';
import useResponsive from "src/hooks/useResponsive";
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Card, Paper, Button, Typography, CardContent } from '@mui/material';
// utils
import { bgGradient } from '../../../../utils/cssStyles';
// components
import Image from '../../../../components/image';
import { MotionContainer, varFade } from '../../../../components/animate';
import Carousel, { CarouselArrowIndex } from '../../../../components/carousel';

import imageCarousel1 from '../img/Eyhe_banner_interno01.png'
import imageCarousel2 from '../img/Eyhe_banner_interno02.png'
import imageCarousel3 from '../img/Eyhe_banner_interno03.png'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
// ----------------------------------------------------------------------

export default function CarouselAnimation() {
  const theme = useTheme();

  const isDesktop = useResponsive('up', 'lg');

  const arraySliders = [
    {
      id: 1,
      image: imageCarousel1,
      title: '',
      description: ''
    },
    {
      id: 2,
      image: imageCarousel2,
      title: '',
      description: ''
    },
    {
      id: 3,
      image: imageCarousel3,
      title: '',
      description: ''
    }
  ];

  const carouselRef = useRef<Carousel | null>(null);

  const [currentIndex, setCurrentIndex] = useState(theme.direction === 'rtl' ? arraySliders.length - 1 : 0);

  const carouselSettings = {
    speed: 1100,
    dots: true,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (current: number, next: number) => setCurrentIndex(next),
  };

  useEffect(() => {
    const disableScroll = (e: TouchEvent) => {
      if (Math.abs(e.touches[0].clientX - startX) > 10) {
        e.preventDefault();
      }
    };

    let startX = 0;

    const carouselWrapperElement = document.getElementById('carousel-wrapper');

    if (carouselWrapperElement) {
      carouselWrapperElement.addEventListener('touchstart', (e) => {
        startX = e.touches[0].clientX;
      });

      carouselWrapperElement.addEventListener('touchmove', disableScroll, { passive: false });
    }

    return () => {
      if (carouselWrapperElement) {
        carouselWrapperElement.removeEventListener('touchstart', (e) => {
          startX = e.touches[0].clientX;
        });

        carouselWrapperElement.removeEventListener('touchmove', disableScroll);
      }
    };
  }, []);

  const handlePrev = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <Card>
      <div id="carousel-wrapper" style={{ overflow: 'hidden' }}>
        <Carousel ref={carouselRef} {...carouselSettings}>
          {arraySliders.map((item, index) => (
            <CarouselItem key={item.id} item={item} isActive={index === currentIndex} />
          ))}
        </Carousel>
      </div>
    </Card>
  );
}

// ----------------------------------------------------------------------

type CarouselItemProps = {
  item: {
    title: string;
    description: string;
    image: string;
  };
  isActive: boolean;
};

function CarouselItem({ item, isActive }: CarouselItemProps) {
  const { image, title } = item;

  return (
    <Paper sx={{ position: 'relative' }}>
      <Image alt={title} src={image} ratio="16/9" />

      <Box
        sx={{
          top: 0,
          width: 1,
          height: 1,
          position: 'absolute',
        }}
      />

      <CardContent
        component={MotionContainer}
        animate={isActive}
        action
        sx={{
          bottom: 0,
          width: 1,
          maxWidth: 720,
          textAlign: 'left',
          position: 'absolute',
          color: 'common.white',
        }}
      >
        <m.div variants={varFade().in}>
          <Typography variant="h3" gutterBottom>
            {item.title}
          </Typography>
        </m.div>

        <m.div variants={varFade().in}>
          <Typography variant="body2" gutterBottom>
            {item.description}
          </Typography>
        </m.div>
      </CardContent>
    </Paper>
  );
}
