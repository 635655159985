import { m } from 'framer-motion';
import {useState, useRef, useEffect, useCallback} from 'react';
import useResponsive from "src/hooks/useResponsive";
// @mui
import { alpha, useTheme } from '@mui/material/styles';
import { Box, Card, Paper, Button, Typography, CardContent } from '@mui/material';
// utils
import { bgGradient } from '../../../../utils/cssStyles';
// components
import Image from '../../../../components/image';
import { MotionContainer, varFade } from '../../../../components/animate';
import Carousel, { CarouselArrowIndex } from '../../../../components/carousel';

import imageCarousel1 from '../img/Eyhe_banner_interno01.png'
import imageCarousel2 from '../img/Eyhe_banner_interno02.png'
import imageCarousel3 from '../img/Eyhe_banner_interno03.png'

import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import ConteudoCardResumo from './ConteudoCardResumo';
import { ColorSchema } from '../../../../theme/palette';

import './CardResumoDash.css';
import axios from "../../../../utils/axios";

// ----------------------------------------------------------------------

export default function CardResumoDash() {
  const theme = useTheme();

  const isDesktop = useResponsive('up', 'lg');


  const [dados, setdados] = useState({
  atendimentosperdidos: 0, atendimentosRealizados: 0, atendimentosRealizadosporcetagem: 0 , atendimentosperdidosporcetagem: 0
  });

  const getagendamentos = useCallback(async () => {
    try {
      const response = await axios.post('/areadoatendente/insightsdash', {});

      const { chamadas } = response.data;

      setdados(chamadas);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getagendamentos();
  }, [getagendamentos]);





  type SliderItem = {
    id: number; // ID DO SLIDER
    title: string; // TITULO DO SLIDER
    image: string; // IMAGE (NÃO USAMOS)
    classeCardResumo: string; // CLASSE PERSONALIZADA
    valueNum: number; // VALOR INTEIRO 
    series: number; // PORCENTAGEM 
    icon: string; // ÍCONE DO CARD
    description: string; // DESCRIÇÃO (NÃO USAMOS)
  };

  const arraySliders: SliderItem[] = [
    {
      id: 1,
      title: 'Conversas Perdidas',
      image: imageCarousel1,
      classeCardResumo: 'CardResumoPerdidas',
      valueNum: dados.atendimentosperdidos,
      series: dados.atendimentosperdidosporcetagem,
      icon: 'eva:trending-down-fill',
      description: 'Perdidas'
    },
    {
      id: 2,
      title: 'Conversas Atendidas',
      image: imageCarousel2,
      classeCardResumo: 'CardResumoAtendidas',
      valueNum: dados.atendimentosRealizados,
      series: dados.atendimentosRealizadosporcetagem,
      icon: 'eva:trending-up-fill',
      description: 'Atendido'
    }
  ];

  const carouselRef = useRef<Carousel | null>(null);

  const [currentIndex, setCurrentIndex] = useState(theme.direction === 'rtl' ? arraySliders.length - 1 : 0);

  const carouselSettings = {
    speed: 1100,
    dots: true,
    arrows: false,
    autoplay: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    rtl: Boolean(theme.direction === 'rtl'),
    beforeChange: (current: number, next: number) => setCurrentIndex(next),
  };

  useEffect(() => {
    const disableScroll = (e: TouchEvent) => {
      if (Math.abs(e.touches[0].clientX - startX) > 10) {
        e.preventDefault();
      }
    };

    let startX = 0;

    const carouselWrapperElement = document.getElementById('carousel-wrapper');

    if (carouselWrapperElement) {
      carouselWrapperElement.addEventListener('touchstart', (e) => {
        startX = e.touches[0].clientX;
      });

      carouselWrapperElement.addEventListener('touchmove', disableScroll, { passive: false });
    }

    return () => {
      if (carouselWrapperElement) {
        carouselWrapperElement.removeEventListener('touchstart', (e) => {
          startX = e.touches[0].clientX;
        });

        carouselWrapperElement.removeEventListener('touchmove', disableScroll);
      }
    };
  }, []);

  const handlePrev = () => {
    carouselRef.current?.slickPrev();
  };

  const handleNext = () => {
    carouselRef.current?.slickNext();
  };

  return (
    <Card>
      <div id="carousel-wrapper" style={{ overflow: 'hidden' }}>
        <Carousel ref={carouselRef} {...carouselSettings}>
          {arraySliders.map((item, index) => (
            <CarouselItem key={item.id} item={item} isActive={index === currentIndex} />
          ))}
        </Carousel>
      </div>
    </Card>
  );
}

// ----------------------------------------------------------------------

type CarouselItemProps = {
  item: {
    title: string;
    description: string;
    image: string;
    valueNum: number;
    series: number;
    classeCardResumo: string;
    icon: string;
  };
  isActive: boolean;
};

function CarouselItem({ item, isActive }: CarouselItemProps) {
  const { image, title,description, valueNum,series,classeCardResumo, icon  } = item;

  return (
    <Box sx={{ height: '100%'}}>
      
        <ConteudoCardResumo
        className={classeCardResumo} 
          title={title}
          total={valueNum}
          icon={icon}
          chart={{
            series,
          }}
        />
     
    </Box>
  );
}
