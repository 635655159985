// @mui
import { styled } from '@mui/material/styles';
import { Link, Card, Typography, CardHeader, Stack } from '@mui/material';
// @types
import { IUserProfileAbout } from '../../../../@types/user';
// components
import Iconify from '../../../../components/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled(Iconify)(({ theme }) => ({
  width: 20,
  height: 20,
  marginTop: 1,
  flexShrink: 0,
  marginRight: theme.spacing(2),
}));

// ----------------------------------------------------------------------
interface Props {
  Atendente: any;
}
export default function ProfileAbout({
                                       Atendente,

}: Props) {
  return (
    <Card className='PerfilDoAtendenteCard'>
      <CardHeader title="Perfil do Atendente" />

      <Stack spacing={2} sx={{ p: 3 }}>
        <Typography variant="body2">{Atendente.ATexto}</Typography>

        <Stack direction="row">
          <StyledIcon icon="eva:pin-fill" />

          <Typography variant="body2">
            Mora em &nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
              {Atendente.estado}
            </Link>
          </Typography>
        </Stack>

        <Stack direction="row">
          <StyledIcon icon="eva:email-fill" />
          <Typography variant="body2">{Atendente.email}</Typography>
        </Stack>

        <Stack direction="row">
          <StyledIcon icon="ic:round-business-center" />

          <Typography variant="body2">
          Bacharel em &nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
              Psicologia - PUC
            </Link>
          </Typography>
        </Stack>

        <Stack direction="row">
          <StyledIcon icon="ic:round-business-center" />

          <Typography variant="body2">
          Mestre em &nbsp;
            <Link component="span" variant="subtitle2" color="text.primary">
              Psicopedagogia - UFF
            </Link>
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
