import { Helmet } from 'react-helmet-async';
// sections
import { Chat } from '../sections/@dashboard/chat';
import Atendetentes from "../sections/@dashboard/Atendentes/Atendetentes";



export default function AtendetesPage() {
  return (
    <>
      <Helmet>
        <title>Lista de  Atendentes | Eyhe </title>
      </Helmet>

      <Atendetentes  />
    </>
  );
}
