import { Suspense, lazy, ElementType } from 'react';
// components
import LoadingScreen from '../components/loading-screen';

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

// ----------------------------------------------------------------------

export const LoginPage = Loadable(lazy(() => import('../pages/LoginPage')));

export const PageDashfirst = Loadable(lazy(() => import('../pages/PageDashfirst')));
export const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')));
export const PageThree = Loadable(lazy(() => import('../pages/PageThree')));
export const PageFour = Loadable(lazy(() => import('../pages/PageFour')));
export const PageFive = Loadable(lazy(() => import('../pages/PageFive')));
export const PageSix = Loadable(lazy(() => import('../pages/PageSix')));

export const Page404 = Loadable(lazy(() => import('../pages/Page404')));

export const ChatPage = Loadable(lazy(() => import('../pages/ChatPage')));
export const ChatVideo = Loadable(lazy(() => import('../pages/ChatVideo')));
export const ChatAudio = Loadable(lazy(() => import('../pages/ChatAudio')));
export const Conversas = Loadable(lazy(() => import('../pages/ConversasPage')));
export const Atendentes = Loadable(lazy(() => import('../pages/AtendetesPage')));
export const salaespera = Loadable(lazy(() => import('../pages/PageSalaEspera')));
export const listagem = Loadable(lazy(() => import('../pages/PageDashfirstListagem')));
export const personalizarperfil = Loadable(lazy(() => import('../pages/PersonalizarPerfilPage')));
export const atendimentos = Loadable(lazy(() => import('../pages/AtendimentoPage')));
export const avaliacoes = Loadable(lazy(() => import('../pages/AvaliacoesPage')));
export const regras = Loadable(lazy(() => import('../pages/RegrasPage')));
export const cupons = Loadable(lazy(() => import('../pages/CuponsPage')));
export const insight = Loadable(lazy(() => import('../pages/InsightsPage')));
export const pacotes = Loadable(lazy(() => import('../pages/PacotesPage')));


export const financeiro = Loadable(lazy(() => import('../pages/FinanceiroPage')));
export const verPerfil = Loadable(lazy(() => import('../pages/VerPerfilPage')));
export const detalhesAgendamento = Loadable(lazy(() => import('../pages/DetalhesAgendamentoPage')));
export const agendamentos = Loadable(lazy(() => import('../pages/AgendamentosPage')));

export const rotina = Loadable(lazy(() => import('../pages/RotinaPage')));


export const chamadaaudio = Loadable(lazy(() => import('../pages/ChamadaAudioPage')));
export const chamadavideo = Loadable(lazy(() => import('../pages/ChamadaVideoPage')));

