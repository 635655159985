import {useEffect, useState, useCallback, useMemo} from 'react';
import {Divider, styled, Tabs,Radio ,RadioGroup ,FormControlLabel ,FormLabel , Tab, IconButton,Button,Link,TextField,InputLabel, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import useResponsive from 'src/hooks/useResponsive';
import Iconify from 'src/components/iconify';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';

import './Pacotes.css';
import CardListaPacotes from './ComponentePacotes/CardListaPacotes';
import DetalhesPacotes from './ComponentePacotes/DetalhesPacotes';
import axios from "../../../utils/axios";


// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }


export default function Pacotes() {
    const isDesktop = useResponsive('up', 'lg');
    const navigate = useNavigate();

    const [value, setValue] = useState(0);
  const [pacotesdados, setPacotes] = useState<any>([{tiposconversa:[]}]);
  const [tiposconversadados, settiposconversa] = useState<any>([]);
  const [pacote, setPacote] = useState<any>({});
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const [isEditing, setIsEditing] = useState(false);

  const handleEditClick = (pac:any) => {
    setIsEditing(true);
    setPacote(pac);
  }
  const handlecriarClick = () => {
    setIsEditing(true);
    setPacote({});
  };


  const getmessage = useCallback(async () => {

    try {
      const response = await axios.post(`/areadoatendente/getpacotes`,{

      });

      const {pacotes,tiposconversa} = response.data;
      setPacotes(pacotes);
      settiposconversa(tiposconversa);
    } catch (error) {
      console.error(error);
    }
  },[setPacotes]);




  useEffect(() => {

    getmessage();

  }, [getmessage]);

  const atualizar = () => {
    getmessage();
    setIsEditing(false);

  };

  const detalhesSessoes = [
    {
      nome: 'Pacote Exemplo 1',
      valor: 'R$120',
      quantidade: 6,
      tipoAtendimento: 'Chat + Chamada Áudio+Vídeo'
    },
    {
      nome: 'Pacote Exemplo 2',
      valor: 'R$150',
      quantidade: 8,
      tipoAtendimento: 'Chat + Chamada Áudio'
    },
    {
      nome: 'Pacote Exemplo 3',
      valor: 'R$190',
      quantidade: 10,
      tipoAtendimento: 'Chamada Vídeo'
    },
    {
      nome: 'Pacote Exemplo 4',
      valor: 'R$250',
      quantidade: 15,
      tipoAtendimento: 'Chamada Vídeo'
    },
    {
      nome: 'Pacote Exemplo 5',
      valor: 'R$450',
      quantidade: 20,
      tipoAtendimento: 'Chamada Vídeo'
    }
  ];



    

  return (
            <>
            {!isEditing ? (
              <Box sx={{ p: 2.5 }}>
              <Button
              variant="text"
              onClick={() => {
                  navigate(PATH_DASHBOARD.dashfirst, { replace: true });
              }}
              >
              {' '}
              <Iconify icon="eva:arrow-ios-back-outline" /> Dashboard{' '}
              </Button>

              <CustomBreadcrumbs
              heading="Lista de Pacotes"
              sx={{ px: 2.5 }}
              links={[
                  {
                  name: 'Dashboard',
                  href: PATH_DASHBOARD.root,
                  },
                  {
                  name: 'Lista de Pacotes',
                  href: PATH_DASHBOARD.root,
                  },
              ]}
              
              />
              
              <Box sx={{ width: '100%'}}>
                <Grid container>
                  {pacotesdados.map((sessao:any, index:number) => (
                    <CardListaPacotes NomePacote={sessao.Nome} ValorPacote={sessao.Valor} QtdAtendimentos={sessao.quantidade} atualizar={atualizar} TipoAtendimento={sessao.tipoconversa} onEditClick={handleEditClick} pacote={sessao}/>
                  ))}
                </Grid>
              </Box>

            
          
              <Button variant='contained' color='primary' fullWidth sx={{py: 1.5, mt: 3}} onClick={handlecriarClick} >Criar novo pacote</Button>

      </Box>     
        
            ) : (
              <DetalhesPacotes detalhesSessoes={pacote} isEditing={isEditing} pacotes={pacotesdados} atualizar={()=>{atualizar()}} tiposconversadados={tiposconversadados} setIsEditing={setIsEditing} />
            )}
              
              
         
            

            </>
    );
}
