import { Helmet } from 'react-helmet-async';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, Button, Box } from '@mui/material';
import Iconify from 'src/components/iconify';
import { useCallback, useEffect, useState } from 'react';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// _mock_
import { _analyticPost, _analyticOrderTimeline, _analyticTraffic } from '../../../_mock/arrays';
// components
import { useSettingsContext } from '../../../components/settings';
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
// sections
import {
  AnalyticsTasks,
  AnalyticsNewsUpdate,
  AnalyticsOrderTimeline,
  AnalyticsCurrentVisits,
  AnalyticsWebsiteVisits,
  AnalyticsTrafficBySite,
  AnalyticsWidgetSummary,
  AnalyticsCurrentSubject,
  AnalyticsConversionRates,
} from './componentesInsights';
import axios from '../../../utils/axios';

// ----------------------------------------------------------------------

export default function GeneralAnalyticsPage() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();
  const [dados, setdados] = useState({
    perdidas: { diahora: [], quantidade: [] },
    horarioschamadas: { atendimentosperdidos: 0, atendimentosRealizados: 0 },
  });

  const getagendamentos = useCallback(async () => {
    try {
      const response = await axios.post('/areadoatendente/insights', {});

      const { data } = response.data;

      setdados(data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    getagendamentos();
  }, [getagendamentos]);

  return (
    <>
      <Helmet>
        <title> Insights</title>
      </Helmet>

      <Box sx={{ p: 2.5 }}>
        <Button
          variant="text"
          onClick={() => {
            navigate(PATH_DASHBOARD.dashfirst, { replace: true });
          }}
        >
          {' '}
          <Iconify icon="eva:arrow-ios-back-outline" /> Dashboard{' '}
        </Button>

        <div className="BreadCrumbsListagemMaterial">
          <CustomBreadcrumbs
            heading="Insights"
            links={[
              {
                name: 'Dashboard',
                href: PATH_DASHBOARD.root,
              },
              {
                name: 'Insights',
              },
            ]}
          />
        </div>

        <Grid container spacing={3}>
          <Grid item xs={12} md={6} lg={8}>
            <AnalyticsWebsiteVisits
              title="Distribição de horário das chamadas"
              chart={{
                labels: dados.perdidas.diahora,
                series: [
                  {
                    name: 'Chamadas',
                    type: 'area',
                    fill: 'gradient',
                    data: dados.perdidas.quantidade,
                  },
                ],
              }}
            />
          </Grid>

          <Grid item xs={12} md={6} lg={4}>
            <AnalyticsCurrentVisits
              title="Chamadas perdidas"
              chart={{
                series: [
                  {
                    label: 'Chamadas realizadas',
                    value: dados.horarioschamadas.atendimentosRealizados,
                  },
                  {
                    label: 'Chamadas Perdidas',
                    value: dados.horarioschamadas.atendimentosperdidos,
                  },
                ],
                colors: [
                  theme.palette.primary.main,
                  theme.palette.error.main,
                  theme.palette.error.main,
                  theme.palette.warning.main,
                ],
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </>
  );
}
