
import {useState, useRef, useEffect} from 'react';
// @mui

import { alpha, useTheme } from '@mui/material/styles';
import { Box, Card, Paper,FormControl,InputLabel,MenuItem,Select,Rating,Button, Typography, CardContent,SelectChangeEvent, Divider, IconButton } from '@mui/material';
import { CustomAvatar } from "src/components/custom-avatar";
import Iconify from 'src/components/iconify/Iconify';
import ProfilePicture from '../img/profile_picture.png';
import {fDate} from "../../../../utils/formatTime";


// utils

// components

// ----------------------------------------------------------------------

type Props = {
    avaliacaounica: any;

};

export default function AvaliacaoIndividual({avaliacaounica}:Props) {


    const [avaliacao, setAvaliacao] = useState<number | null>(4);
    useEffect(() => {

        setAvaliacao(avaliacaounica.estrelas)

    },[avaliacaounica]);
  return (
    <>
        <div className='FotoeNomeAvaliacaoIndividualCard'>
            <div className='FotoeNomeAvaliacaoIndividualCard'>
                <CustomAvatar
                    className="FotoPerfilAtendente"
                    src={avaliacaounica.avatar}
                    sx={{ cursor: 'pointer', width: 40, height: 40 }}
                />

                <div className='NomeDataAvaliacaoIndividual'>
                    <Typography className='NomeAvaliacaoIndividual'>{avaliacaounica.nome}</Typography>
                    <Typography className='DataAvaliacaoIndividual'>{fDate(avaliacaounica.created_at)}</Typography>
                </div>

                </div>

                <Rating name="read-only" value={avaliacao} readOnly />
        </div>

    </>
  );
}