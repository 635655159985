import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import * as React from "react";
import {useSettingsContext} from '../../../components/settings';


import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// routes
import {PATH_DASHBOARD} from '../../../routes/paths';
import axios from "../../../utils/axios";
import {useAuthContext} from "../../../auth/useAuthContext";
import Iconify from "../../../components/iconify";
import EncerraSalaAudio from "./ComponetesSalaAudio/EncerraSalaAudio";
import AudioAtivo from "./ComponetesSalaAudio/AudioAtivo";
import ChatNotificacoes from "../chat/header/ChatNotificacoes";
import {useSnackbar} from "../../../components/snackbar";


// ----------------------------------------------------------------------


export default function SalaAudio() {
    const {themeStretch} = useSettingsContext();

    const { user,getuser } = useAuthContext();
    const navigate = useNavigate();

    const {pathname} = useLocation();

    const {tokenConversa = ''} = useParams();
    const { enqueueSnackbar } = useSnackbar();

    const [estadoSaladevideo, setbestadoSaladevideo] = useState('Ativo');

    const MudarStatus = (status:any) =>{
        setbestadoSaladevideo(status);
        console.log(`teste sala audio ${  status}`);
    }
    const [usera,setuser] = useState<any>({});
    
    const [valorsomar, setvalorsomar] = useState<number>(0);
    const [time, setTime] = useState(40000000);
    const [tipoconversa, settipoconversa] = useState<any>({ Duracao: 200 });

    const [openhist, setOpenhis] = useState(true);

    useEffect(() => {

        getuser();

    }, [getuser]);



    const getusers = useCallback(async () => {
        try {
            const response = await axios.get(`/areadoatendente/atendente/${  tokenConversa  }`);

            const {heroi} = response.data;


            setuser(heroi)

        } catch (error) {
            enqueueSnackbar(`${ error.message}`, { variant: 'error' });
            navigate(PATH_DASHBOARD.dashfirst);
        }
    }, [enqueueSnackbar, navigate, tokenConversa]);


    useEffect(() => {

        getusers();

    }, [getusers]);






    const getmessage = useCallback(async () => {
        try {
            const response = await axios.post('/areadoatendente/gettimeconversa', {
                tokenConversa,
            });

            const { timeconversa, tipoconversas } = response.data;

            if (timeconversa === 0) {
                setvalorsomar(0);
                sessionStorage.setItem(`conversafechada`, '1');
                navigate(PATH_DASHBOARD.chat.view(tokenConversa),{replace:true});
            }else{
                setTime(timeconversa);
                setvalorsomar(1);
            }




            settipoconversa(tipoconversas);
        } catch (error) {
            enqueueSnackbar(`${error.message}`, { variant: 'error' });
        }
    }, [enqueueSnackbar, navigate, tokenConversa]);

    useEffect(() => {
        getmessage();
    }, [getmessage]);





    useEffect(() => {
        const timer = setInterval(() => {
            (async function() {
                try {
                    const response = await axios.post('/areadoatendente/gettimeconversa', {
                        tokenConversa
                    });

                    const {timeconversa} = response.data;


                    if (timeconversa === 0) {
                        clearInterval(timer);
                        setvalorsomar(0);
                        sessionStorage.setItem(`conversafechada`, '1');
                        navigate(PATH_DASHBOARD.chat.view(tokenConversa),{replace:true});

                    }else{
                        setTime(timeconversa);
                        setvalorsomar(1);
                    }




                } catch (error) {
                    enqueueSnackbar(`${ error.message}`, { variant: 'error' });
                }
            })();
        }, 60000);





        return () => {
            clearInterval(timer);
        };
    }, [enqueueSnackbar, navigate, time, tokenConversa]);




    return (
            <>
                <ChatNotificacoes
                    time={time}
                    Atendente={usera}
                    tipoConversa={tipoconversa}
                    openhist={openhist}
                    setOpenhis={setOpenhis}
                />
                { estadoSaladevideo === "Ativo" ? (
                    <AudioAtivo EncerraAudio={MudarStatus} />
                ) : ''}

                { estadoSaladevideo === "Encerra" ? (
                    <EncerraSalaAudio Backaudio={MudarStatus}/>
                ) : ''}

            </>
    );
}
