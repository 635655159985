import React from 'react';
import { LinearProgress, Box, Typography } from '@mui/material';
import { LinearProgressProps } from '@mui/material/LinearProgress';

function formatTime(seconds:any) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;
  return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
}

function LinearProgressWithLabel({ value, ...props }: LinearProgressProps & { value: number }) {
  const totalSeconds = Math.round(value * (5 * 60)); // Converter o progresso para segundos
  const timeString = formatTime(totalSeconds);

  let color: 'success' | 'warning' | 'error' | undefined;
  if (totalSeconds < 180) {
    color = 'success';
  } else if (totalSeconds >= 180 && totalSeconds < 250) {
    color = 'warning';
  } else {
    color = 'error';
  }

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress variant="determinate" color={color}  {...props} value={value * 100} />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography variant="body2" color="light">{timeString}</Typography>
      </Box>
    </Box>
  );
}
type props = {
  colocaroffline: any;
};
export default function TimerDormir({colocaroffline}:props) {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const interval = setInterval(() => {
      setProgress((prevProgress) => {
        const newProgress = prevProgress + (1 / (5 * 60)); // Avanço de 1 segundo
        if(newProgress >= 1){
          clearInterval(interval);
          colocaroffline();
          return 0;
        }
          return newProgress;
        
     
      });
    }, 1000); // Atualizar a cada segundo

    return () => clearInterval(interval);
  }, [colocaroffline]);

  return <LinearProgressWithLabel value={progress} />;
}
