import {useState, useEffect, useCallback} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
    Box,
    Stack,
    Drawer,
    IconButton,
    IconButtonProps,
    Typography,
    Tooltip,
    Divider,
    List,
    MenuItem, Select
} from '@mui/material';
// hooks
import useResponsive from '../../../hooks/useResponsive';

// utils
import axios from '../../../utils/axios';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../routes/paths';
// @types
import { IChatContact, IChatConversationsState } from '../../../@types/chat';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
//
import ChatNavList from '../chat/nav/ChatNavList';
import ChatNavSearch from '../chat/nav/ChatNavSearch';
import ChatNavAccount from '../chat/nav/ChatNavAccount';
import ChatNavSearchResults from '../chat/nav/ChatNavSearchResults';
import MenuPopover from "../../../components/menu-popover";
import BadgeStatus, {BadgeStatusValue} from "../../../components/badge-status";
import {useAuthContext} from "../../../auth/useAuthContext";
import {useSnackbar} from "../../../components/snackbar";

// ----------------------------------------------------------------------

const StyledToggleButton = styled((props) => (
    <IconButton disableRipple {...props} />
))<IconButtonProps>(({ theme }) => ({
    left: 0,
    zIndex: 9,
    width: 32,
    height: 32,
    position: 'absolute',
    top: theme.spacing(13),
    borderRadius: `0 12px 12px 0`,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.customShadows.primary,
    '&:hover': {
        backgroundColor: theme.palette.primary.darker,
    },
}));

// ----------------------------------------------------------------------

const NAV_WIDTH = 320;

const NAV_COLLAPSE_WIDTH = 96;



export default function Conversas() {
    const { enqueueSnackbar } = useSnackbar();

    const [conversas, setConvesas] = useState<any[]>([]);




    const getmessage = useCallback(async () => {
        try {
            const response = await axios.post('/areadoatendente/atendimentos');

            const {atendimentos} = response.data;
            const {data} = atendimentos;

            setConvesas(data)

        } catch (error) {
            console.error(error);
        }
    },[]);


    console.log(conversas);

    useEffect(() => {

        getmessage();

    }, [getmessage]);


    const theme = useTheme();

    const STATUS = ['online', 'invisible', 'away'] as const;

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const [openNav, setOpenNav] = useState(true);

    const { user, logout } = useAuthContext();
    const [searchContacts, setSearchContacts] = useState('');

    const isCollapse = isDesktop && !openNav;

    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
    const [status, setStatus] = useState<BadgeStatusValue>('online');
    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };



    useEffect(() => {
        if (!isDesktop) {
            handleCloseNav();
        } else {
            handleOpenNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesktop]);

    const handleToggleNav = () => {
        setOpenNav(!openNav);
    };

    const handleOpenNav = () => {
        setOpenNav(true);
    };

    const handleCloseNav = () => {
        setOpenNav(false);
    };
    const handleLogout = async () => {
        try {
            logout();
            navigate(PATH_AUTH.login, { replace: true });
            handleClosePopover();
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Erro ao sair', { variant: 'error' });
        }
    };
    const handleChangeSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {
            const { value } = event.target;

            setSearchContacts(value);

            const response = await axios.post('/areadoatendente/atendimentos', {
                query: value
            });

            setConvesas(response.data.atendimentos.data);

        } catch (error) {
            console.error(error);
        }
    };

    const handleSelectContact = (result: any) => {
        setSearchContacts('');
        navigate(PATH_DASHBOARD.chat.view(result.idconvesa));
    };

    const renderContent = (
        <>
            <Box sx={{ p: 2.5 }}>


                    <ChatNavSearch
                        value={searchContacts}
                        onChange={handleChangeSearch}
                        onClickAway={() => setSearchContacts('')}
                    />

            </Box>
            <Scrollbar>

                <ChatNavList openNav
                             conversas={conversas}
                             onCloseNav={handleCloseNav}
                             tokenConversa='5454554545'
                />

            </Scrollbar>

        </>
    );

    return (
        <>
           {renderContent}
         </>
    )
}
