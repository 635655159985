import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { CustomAvatar } from "src/components/custom-avatar";
import {Card, Grid, IconButton, Typography} from '@mui/material';
// @mui
import { styled } from '@mui/material/styles';
import {useSettingsContext} from '../../../../components/settings';

import '../DetalhesAgendamento.css';

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// routes
import {PATH_DASHBOARD} from '../../../../routes/paths';
import axios from "../../../../utils/axios";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";

import Avatar from './avatar.png'

type Props = {
    AgenDados: any;

};

export default function CardAgendamento({AgenDados}:Props) {

        const [status, setStatus] = useState('realizado');

        useEffect(() => {

            setStatus(AgenDados.Status)
    
        }, [setStatus,AgenDados]);
          
        let cardClass = 'card';

        if (status === 'Finalizado') {
            cardClass = 'realizado';
            } else if (status === 'Agendado') {
            cardClass = 'agendado';
            } else if (status === 'Perdida') {
            cardClass = 'naoConfirmado';
            } else if (status === 'Cancelado') {
            cardClass = 'cancelado';
            }

    return (

        
            <>
                <Card className='CardAgendamentoGeral' sx={{my:1}}>
                    <div className='FotoeNomeCardAgendamento'>
                        <CustomAvatar
                            src={AgenDados.avatar}
                            alt={AgenDados?.nome}
                            name={AgenDados?.nome}
                            sx={{ cursor: 'pointer', width: 60, height: 60 }}
                        />

                        <div className='NomeeStatusCardAgendamento'>
                            <Typography sx={{textAlign: 'left'}}>{AgenDados?.nome}</Typography>

                            <Typography className={cardClass} >{status}</Typography>

                        </div>
                    </div>

                    <div className={`calendarioCardAgendamento ${cardClass}`}>
                        <Iconify icon='eva:calendar-outline' />
                    </div>
                    
                </Card>

            </>
    );
}
