import {useEffect, useState, useCallback, useMemo} from 'react';
// @mui
import {Divider,Tabs, Tab, IconButton,Button,TextField, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import { CustomAvatar } from "src/components/custom-avatar";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
// @types
import useResponsive from "src/hooks/useResponsive";
import Iconify from 'src/components/iconify/Iconify';
import { styled } from '@mui/material/styles';
import {useSettingsContext} from '../../../components/settings';

import { IUserProfile, IUserProfilePost } from '../../../@types/user';
//
import ProfileAbout from './home/ProfileAbout';
import ProfilePostCard from './home/ProfilePostCard';
import ProfilePostInput from './home/ProfilePostInput';
import ProfileFollowInfo from './home/ProfileFollowInfo';
import ProfileSocialInfo from './home/ProfileSocialInfo';
import ProfileCover from './ProfileCover';
import CoverProfile from './home/CoverProfile';
import FotoPerfil from './background_verperfil.jpg'

import './VerPerfil.css';
import './home/CoverProfile.css';
import Avaliacoesperfil from "./home/Avaliacoesperfil";
import ConversasSobre from "./home/ConversasSobre";
import {PATH_DASHBOARD} from "../../../routes/paths";

// ----------------------------------------------------------------------

type Props = {
  info: IUserProfile;
  posts: any;
  Atendente: any;
  atedimentos: any;
  avaliacoes: any;
  assuntos: any;
};


export default function VerPerfil({ info, posts,Atendente,atedimentos,avaliacoes,assuntos }: Props) {

  const { themeLayout } = useSettingsContext();
  const isDesktop = useResponsive('up', 'lg');
  const navigate = useNavigate();
  const [name, setName] = useState<any>({}); 
  const [role, setRole] = useState<any>({});
  const [cover, setCover] = useState<any>({});
  const [value, setValue] = useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  setValue(newValue);
  }



  const BotaoConversar = (
    
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      className='CTAVerPerfilGrid'
    >

      <Button variant='contained' color="success" className='BotaoCTAVerPerfil'>Conversar Agora</Button>
      <Typography>A primeira conversa é gratuita!</Typography>

      </Grid>
  )
  





const  nome  = (
  <Typography className="NomeCardTopoPrincipalCoverProfileDesktop">{Atendente.nome}</Typography>
);

const atendentedesde  = (
  <Typography className="AtendenteDesdeCoverProfileDesktop">{Atendente.created_at}</Typography>
);

  







  return (

    <>
    
    { isDesktop ? (
       <Grid container spacing={3}>

       <Grid item xs={12} md={12}>
       { isDesktop ? (
          <div className="CapaAtendenteCoverProfileDesktop">
          <div className="CapaAtendenteCoverProfileDesktopAzul">
              <div className="FotoeNomeAtendenteCoverProfileDesktop">

                <CustomAvatar
                    className="FotoPerfilAtendente"
                    src={Atendente.avatar}
                    alt={Atendente.nome}
                    name={Atendente.nome}
                    sx={{ cursor: 'pointer', width: 80, height: 80 }}
                />

                  <div className="NomeAtendenteCoverProfileDesktop">
                      {nome}
                      {atendentedesde}
                  </div>
                  
              </div>

              
          </div>

          <div className="tabsCoverProfile">
              <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="icon position tabs example"
                  >
                  <Tab icon={<Iconify icon="eva:home-outline"/>} iconPosition="start" label="Perfil" />
                  <Tab icon={<Iconify icon="eva:heart-fill"/>} iconPosition="start" label="Avaliações" />
                  <Tab icon={<Iconify icon="eva:people-fill"/>} iconPosition="start" label="Conversas Sobre" />
                  <Tab icon={<Iconify icon="eva:image-outline"/>} iconPosition="start" label="Diário" />
              </Tabs>
          </div>
         
  </div>  
       ) : (
                  '' 
       ) }
       </Grid>
 
        
        {value === 0 ? (
          <>
          <Grid item xs={12} md={4}>
            <Stack spacing={3}>
              <ProfileFollowInfo valor={Atendente.ValorAtendimento} tempo={Atendente.TempoAtendimento} atedimentos={atedimentos} />
    
              {BotaoConversar}
    
              <ProfileAbout
                  Atendente={Atendente}
              />
    
            
    
              <ProfileSocialInfo socialLinks={info.socialLinks} />
              
            </Stack>
          </Grid>
    
          <Grid item xs={12} md={8}>
            <Stack spacing={3}>
    
              <ProfilePostInput />
    
              {posts.data.map((post:any) => (
                <ProfilePostCard key={post.id} post={post} />
              ))}
            </Stack>
            
          </Grid>
          </>
        ) : ('')}
        
        {value === 1 ? (
          <>
            <Avaliacoesperfil avaliacoes={avaliacoes}/>
          </>
        ) : ('')}

        {value === 2 ? (
          <>
            <ConversasSobre assuntos={assuntos}/>
          </>
        ) : ('')}
     </Grid>
    ):(
      <>

<div className="TopoCoverProfileMobile">
                <div className="CardTopoPrincipalCoverProfile">
                <div className="FotoNomeCoverProfile">
                  <CustomAvatar
                      className="FotoPerfilAtendente"
                      src={Atendente.avatar}
                      alt={Atendente.nome}
                      name={Atendente.nome}
                      sx={{ cursor: 'pointer', width: 80, height: 80 }}
                  />
                    <div className="NomeCoverProfile">
                        {nome}
                        {atendentedesde}
                    </div>
                    
                    
                </div>

             
            </div>
                <div className="tabsCoverProfileMobile">
                        <Tabs
                            value={value}
                            onChange={handleChange}
                            aria-label="icon position tabs example"
                            >
                            <Tab icon={<Iconify icon="eva:home-outline"/>} iconPosition="start" label="Perfil" />
                            <Tab icon={<Iconify icon="eva:heart-fill"/>} iconPosition="start" label="Avaliações" />
                            <Tab icon={<Iconify icon="eva:people-fill"/>} iconPosition="start" label="Conversas Sobre" />
                            <Tab icon={<Iconify icon="eva:image-outline"/>} iconPosition="start" label="Diário" />
                        </Tabs>
                </div>
            </div>  

        {value === 0 ?(
          <>
          <Grid item xs={12} md={4}>
          <Stack spacing={3}>
            <ProfileFollowInfo valor={Atendente.ValorAtendimento} tempo={Atendente.TempoAtendimento} atedimentos={atedimentos} />
  
            {BotaoConversar}
  
            <ProfileAbout
              Atendente={Atendente}

            />
  
          
  
            <ProfileSocialInfo socialLinks={info.socialLinks} />
            
          </Stack>
        </Grid>
  
        <Grid item xs={12} md={8}>
          <Stack spacing={3}>
  
            <ProfilePostInput />
  
            {posts.data.map((post:any) => (
              <ProfilePostCard key={post.id} post={post} />
            ))}
          </Stack>
          
        </Grid>
        </>
        ) : ('')}

        {value === 1 ?(
          <>
         <Avaliacoesperfil avaliacoes={avaliacoes}/>
          </>
        ) : ('')}

        {value === 2 ? (
          <>
        <ConversasSobre assuntos={assuntos}/>
          </>
        ) : ('')}
      
      </>
    )}
   
    </>
  );
}
