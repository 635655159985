import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { CustomAvatar } from "src/components/custom-avatar";
import {Card, Grid, IconButton,Button, Typography} from '@mui/material';
// @mui
import { styled } from '@mui/material/styles';
import {useSettingsContext} from '../../../../components/settings';

import '../Agendamentos.css';

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// routes
import {PATH_DASHBOARD} from '../../../../routes/paths';
import axios from "../../../../utils/axios";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";

import Avatar from './avatar.png'
import {fHora} from "../../../../utils/formatTime";

type Props = {
    agendamento: any;

};

export default function CardAgendamento({agendamento}:Props) {

        const [status, setStatus] = useState('realizado');

    const navigate = useNavigate();


    useEffect(() => {

        setStatus(agendamento.Status)

    }, [setStatus,agendamento]);


    const agendamentounico = () => {
        navigate(PATH_DASHBOARD.detalhesagendamento(agendamento.id));
    };


    let cardClass = 'card';

        if (status === 'Finalizado') {
        cardClass = 'realizado';
        } else if (status === 'Agendado') {
        cardClass = 'agendado';
        } else if (status === 'Perdida') {
        cardClass = 'naoConfirmado';
        } else if (status === 'Cancelado') {
        cardClass = 'cancelado';
        }

    return (

        
            <>
                <Card className='CardAgendamentoGeral' onClick={agendamentounico} >
                    <div className='FotoeNomeCardAgendamento'>
                        <CustomAvatar
                            src={agendamento.avatar}
                            alt={agendamento?.nome}
                            name={agendamento?.nome}
                            sx={{ cursor: 'pointer', width: 60, height: 60 }}
                        />

                        <div className='NomeeStatusCardAgendamento'>
                            <Typography variant='h6'>{agendamento.nome}</Typography>

                            <Typography className={cardClass} sx={{display: 'flex' , alignItems: 'center', my: 0.5}}> <Iconify icon='eva:calendar-outline' sx={{mr: .5}} /> {agendamento.Status}</Typography>
                            <Typography className={cardClass} sx={{display: 'flex' , alignItems: 'center'}}> <Iconify icon='eva:clock-outline' sx={{mr: .5}} />  {agendamento.hora}</Typography>

                            <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      className={cardClass}
                      sx={{}}
                    >
                      <Iconify icon="eva:info-outline" sx={{ mr: 1 }} /> 
                      <Typography variant='subtitle2'>  Horário de Brasília</Typography>
                    </Grid>

                        </div>

                    </div>

                    <div className={`calendarioCardAgendamento ${cardClass}`}>
                        <Button variant='text'>  Opções <Iconify icon='eva:arrow-ios-forward-fill' sx={{ml: 1}} /></Button>
                    </div>

                    
                    
                </Card>

            </>
    );
}
