
import { useState,useEffect ,useRef,useCallback } from 'react';
// @mui

import { alpha, useTheme } from '@mui/material/styles';
import { Box, Card, Paper,FormControl,Chip,InputLabel,MenuItem,Select,Rating,Slider ,Button, Typography, CardContent,SelectChangeEvent, Divider, IconButton, Grid } from '@mui/material';
import { CustomAvatar } from "src/components/custom-avatar";
import Iconify from 'src/components/iconify/Iconify';
import {useNavigate} from "react-router-dom";
import ProfilePicture from '../img/profile_picture.png';
import AvaliacaoIndividual from './AvaliacaoIndividual';
import { useSnackbar } from '../../../../components/snackbar';
import axios from '../../../../utils/axios';
import {fDatacerta, fDate, fHora} from "../../../../utils/formatTime";
import {PATH_DASHBOARD} from "../../../../routes/paths";

import iconRS from '../img/icon-rs.png'

// utils

// components

// ----------------------------------------------------------------------


function valuetext(value: number) {
    return `${value}`;
  }
type Props = {
    lembrete: any;
};

export default function LembretesCard({lembrete}:Props) {
const navigator =   useNavigate();
    const irparaagedamento = () => {
        navigator(PATH_DASHBOARD.detalhesagendamento(lembrete.id))
    };

  return (
    <>
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div className='CardLembretesNotificacaoCard' onClick={irparaagedamento}>
            <Chip label="Lembrete" color='info' variant='outlined' size="small" icon={<Iconify icon='eva:alert-circle-outline' />} />
            <Typography variant='caption' display="block">Você tem um agendamento marcado!</Typography>

            <div className='ItensCardLembretesNotificacaoCard'>

                <div className='FotoNomeAssuntosLembrete'>
                    <CustomAvatar
                        className="FotoPerfilAtendente"
                        src={lembrete.avatar}
                        name={lembrete.nome}
                        sx={{ cursor: 'pointer', width: 40, height: 40, mr: 1.5 }}
                    />

                    <div>
                        <span style={{display: 'flex', alignItems: 'center'}}>
                            <Typography className='NotificacaoNomeCardNotificacaoCard' sx={{mr: 1}}>{lembrete.nome}</Typography>   
                            {lembrete.OrigemCampanha === 'rs' ? (<img src={iconRS} alt="Origem RS" width={20} />) : ('')}
                        </span>

                        <Typography className='NotificacaoAssuntoCardNotificacaoCard'>Ansiedade | Depressão</Typography>
                    </div>
                </div>

                <div className='BtnIcon_CardNotificacaoCard'>
                    <Button>Opções <Iconify icon='eva:arrow-ios-forward-fill' sx={{ml: 0.5}} /> </Button>
                </div>
            </div>

            <div className='LembreteDataeHoraAtendimentoNotificacao'>

                <div className='Data_LembreteDataeHoraAtendimentoNotificacao'>
                    <Iconify icon="eva:calendar-outline" />
                    <Typography>{lembrete.DataInicio}</Typography>
                </div>

                <div className='Horario_LembreteDataeHoraAtendimentoNotificacao'>
                    <Iconify icon="eva:clock-outline" />
                    <Typography>{lembrete.HoraInicio}</Typography>
                </div>

                <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{mt: 1.5}}
                    >
                      <Iconify icon="eva:info-outline" sx={{ mr: 1, color: '#637381' }} /> 
                      <Typography variant='subtitle2' sx={{color: '#637381'}}>  Horário de Brasília</Typography>
                    </Grid>
                 

                 

            </div>
          

            
            

        </div>

    </>
  );
}