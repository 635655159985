import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { CustomAvatar } from "src/components/custom-avatar";
import {Card, Grid, IconButton, Typography,Button, Rating, Dialog, DialogActions , DialogContent, DialogContentText , DialogTitle , TextField} from '@mui/material';
// @mui

import dayjs, { Dayjs } from 'dayjs';
import { DemoContainer , DemoItem  } from '@mui/x-date-pickers/internals/demo';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';

import 'dayjs/locale/pt-br';
import { ptBR } from '@mui/x-date-pickers/locales';


import { styled } from '@mui/material/styles';
import {useSettingsContext} from '../../../../components/settings';

import '../DetalhesAgendamento.css';

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// routes
import {PATH_DASHBOARD} from '../../../../routes/paths';
import axios from "../../../../utils/axios";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";

import Avatar from './avatar.png'
import CardAgendamento from './cardAgendamento';

type Props = {
    AgenDados: any;
    enviarhora: any;
};
export default function CardRemarcarAtendimento({AgenDados,enviarhora}:Props) {
    const [value, setValue] = useState<Dayjs | null>(dayjs());
    const [openDate, setOpenDate] = useState(false);
    const [selectedTime, setSelectedTime] = useState(dayjs()); // Inicialize com um valor padrão, por exemplo, o horário atual

    const handleOpenDate = () => {
      setOpenDate(true)
    }
    const handleCloseDate = () => {
      setOpenDate(false);
        enviarhora(`${value?.format('YYYY-MM-DD')} ${selectedTime.format('HH:mm')}`)
    }




    const [openHour, setOpenHour] = useState(false);

    const handleOpenHour = () => {
      setOpenHour(true)
    }
    const handleCloseHour = () => {
      setOpenHour(false);

        enviarhora(`${value?.format('YYYY-MM-DD')} ${selectedTime.format('HH:mm')}`)

    }
    
    const currentTime = dayjs(); // Obtenha o horário atual usando dayjs

    const handleTimeChange = (newTime: any) => {
      setSelectedTime(newTime);
    };


    useEffect(() => {

        enviarhora(`${value?.format('YYYY-MM-DD')} ${selectedTime.format('HH:mm')}`)

    }, [enviarhora, selectedTime, value]);


    return (

            <>
               
                <Card className='CardRemarcarAtendimentoGeral'>
                    <Typography variant="subtitle2">Remarcar Atendimento</Typography>
                    <Typography variant="h3">#{AgenDados.id}</Typography>

                    <Typography sx={{py: 1.5}}>Nova Data</Typography>
                    <Button variant="outlined" sx={{mb: 0.5, p: 1.5}} fullWidth onClick={handleOpenDate}> {value?.format('DD/MM/YYYY')} </Button>

                    <Typography sx={{py: 1.5}}>Novo Horário</Typography>
                    <Button variant="outlined" sx={{mb: 0.5, p: 1.5}} fullWidth onClick={handleOpenHour}> {selectedTime.format('HH:mm')} </Button>

                </Card>

                <Dialog
                  open={openDate}
                  onClose={handleCloseDate}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    Que outro dia seria ideal para você realizar este atendimento?
                  </DialogTitle>
                  <DialogContent> 
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
                  <DemoContainer components={['DatePicker', 'DatePicker']}>
                    <DatePicker
                      label="Selecione uma data"
                      value={value}
                      onChange={(newValue) => setValue(newValue)}
                      format="DD/MM/YYYY"
                      sx={{width: '100%'}}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseDate} variant='contained' sx={{p: 1.5}} autoFocus>
                      Selecionar data
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={openHour}
                  onClose={handleCloseHour}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                  className='DialogInputHorarioAgendamento'
                >
                  <DialogTitle id="alert-dialog-title">
                    Que horário seria ideal para você realizar este atendimento?
                  </DialogTitle>
                  <DialogContent> 


                  <LocalizationProvider dateAdapter={AdapterDayjs} >
                    <DemoContainer
                      components={['MobileTimePicker']} 
                    sx={{width: '100%'}} >
                      <DemoItem label="Selecione um horário" > 
                        <MobileTimePicker defaultValue={selectedTime} ampm={false}  onChange={handleTimeChange} sx={{width: '100%'}}/>
                      </DemoItem>
                    </DemoContainer>
                  </LocalizationProvider>


                  </DialogContent>
                  <DialogActions>
                    <Button onClick={handleCloseHour} variant='contained' sx={{p: 1.5}} autoFocus>
                      Selecionar Horário
                    </Button>
                  </DialogActions>
                </Dialog>

            </>
    );
}
