import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {Accordion , AccordionSummary , AccordionDetails,Button, Typography , Grid , Card, Paper , styled, FormGroup, FormControlLabel, Checkbox, Divider   } from "@mui/material";
import CustomBreadcrumbs from 'src/components/custom-breadcrumbs/CustomBreadcrumbs';
import Iconify from "../../../components/iconify";
import axios from "../../../utils/axios";
import ChatNavItem from "../chat/nav/ChatNavItem";
import './Rotina.css'
import DiaSemana from './componentesRotina/DiaSemana';
import AcoesInteligentes from './componentesRotina/AcoesInteligentes';

// ----------------------------------------------------------------------
import {PATH_AUTH, PATH_DASHBOARD} from '../../../routes/paths';


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

const ExpandMoreIcon = (
    <Iconify icon='eva:arrow-ios-downward-fill'/>
)



export default function Rotina() {
   
  const navigate = useNavigate();

    return (
    <>
      <Button variant="text" onClick={()=>{ navigate(PATH_DASHBOARD.dashfirst,{replace:true})}}> <Iconify icon="eva:arrow-ios-back-outline"/> Dashboard </Button>
            
          <div className="BreadCrumbsListagemMaterial">
            <CustomBreadcrumbs
            heading="Rotina"
            links={[
                {
                name: 'Dashboard',
                href: PATH_DASHBOARD.root,
                },
                {
                name: 'Rotina',
                href: PATH_DASHBOARD.rotina,
                }
            ]}
            />
            </div>

    <Grid container spacing={3}>     
            <DiaSemana diaDaSemana='Segunda-Feira' slugDiasemana="2"/>
     
            <DiaSemana diaDaSemana='Terça-Feira' slugDiasemana="3"/>

            <DiaSemana diaDaSemana='Quarta-Feira' slugDiasemana="4"/>

            <DiaSemana diaDaSemana='Quinta-Feira' slugDiasemana="5"/>

            <DiaSemana diaDaSemana='Sexta-Feira' slugDiasemana="6"/>

            <DiaSemana diaDaSemana='Sábado' slugDiasemana="7"/>

            <DiaSemana diaDaSemana='Domingo' slugDiasemana="1"/>
        
      </Grid>
    </>
    );
}
