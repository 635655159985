import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';
import { DyteButton, provideDyteDesignSystem } from '@dytesdk/react-ui-kit';
import { useEffect } from 'react';
import Room from './Room';

export default function AudioRoom({socket,usera}:any) {
    const { meeting } = useDyteMeeting();
    const roomJoined = useDyteSelector((meetinga) => meetinga.self.roomJoined);

    useEffect(() => {
        meeting.self.on('roomLeft', () => {
            // handle navigation to other screen here after user has left the room.
        });

        provideDyteDesignSystem(document.body, {
            theme: 'dark',
        });
    }, [meeting]);


    return <Room socket={socket} usera={usera} />;
}