import * as React from 'react';
import {useState} from "react";
import {
    Divider,
    Tabs,
    Tab,
    IconButton,
    Button,
    TextField,
    InputLabel,
    Slide,
    DialogProps,
    List,
    MenuItem,
    FormControl,
    OutlinedInput,
    Alert,
    InputAdornment,
    Paper,
    Box,
    Grid,
    Badge,
    Avatar,
    Select,
    Stack,
    Rating,
    Tooltip,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions
  } from '@mui/material';
import LoadingButton from '@mui/lab/LoadingButton';
import luaImg from './img/moon-3d.png'
import './DialogDormir.css'
import TimerDormir from './TimerDormir';
import {useAuthContext} from "../../../auth/useAuthContext";
import {useSnackbar} from "../../../components/snackbar";
import axios from "../../../utils/axios";



type props = {
    handleCloseDormir: any;
    openDormir: any;
};


export default function DialogDormir({openDormir,handleCloseDormir}:props) {

    const { enqueueSnackbar } = useSnackbar();
    const { user,mudarstatus,getuser } = useAuthContext();
    const [loadingStatus, setLoadingStatus] = React.useState(false);
    const handleStatusChange = async (status: any) => {

        setLoadingStatus(true);

        try {

            if (mudarstatus) {
                await mudarstatus({status});
            }

            enqueueSnackbar(`Status foi alterado para ${status}`, {variant: 'success'});


            setLoadingStatus(false);
        } catch (error) {
            enqueueSnackbar(`${error.message}`, {variant: 'error'});
            setLoadingStatus(false);
        }
    };


    const simestouaqui = async () => {
        setLoadingStatus(true);
        try {
            const response = await axios.post('/areadoatendente/estoudisponivelclique');

            handleCloseDormir(false);
            enqueueSnackbar(`Obrigado por responder, desejamos  ótimos atendimentos. `, {variant: 'success'});
            setLoadingStatus(false);
        } catch (error) {
            enqueueSnackbar(`${error.message}`, {variant: 'error'});
            setLoadingStatus(false);
        }

    }
    const deixaroffline = () => {
        handleCloseDormir(false);
        handleStatusChange('Offline');
    }


  return (
   
      <Dialog
        open={openDormir}
        onClose={handleCloseDormir}
        disableEscapeKeyDown

        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        
      >
        <DialogContent className='DialogDormir'>
            <img src={luaImg} alt='Lua3D' className='lua3d' />

            <Typography className='titulomodaldormir' variant='h2' sx={{mb: 2.5, mt: -2}}>Você ainda está aí? </Typography>

            <Typography className='textomodaldormir' variant='body2' sx={{fontWeight: '200', mb: 2.5}}>Após às 20h, O Eyhe automaticamente verifica se seus Atendentes online estão realmente disponíveis.
                Esse aviso aparece a cada 30 minutos em sua Dashboard. Clique em SIM para confirmar sua presença e manter seu status online. Caso não seja clicado seu status se tornará offline em 5 minutos. </Typography>

            <Box sx={{mb: 2.5}}>
                <LoadingButton   loading={loadingStatus}  variant='contained' onClick={simestouaqui} color='success' sx={{mr: 1, px: 2, py: 1.5}}>Sim, estou aqui!</LoadingButton>
                <LoadingButton   loading={loadingStatus} variant='outlined' onClick={deixaroffline} sx={{color: '#fff', borderColor: '#fff', px: 2, py: 1.5}}>Não, deixe-me offline</LoadingButton>
            </Box>

            <Box sx={{ width: '100%', mb:2 }}>
                <TimerDormir colocaroffline={deixaroffline}/>
            </Box>

        </DialogContent>
      </Dialog>

  );
}