// ----------------------------------------------------------------------

import iconConversaBasicaPsico from '../sections/@dashboard/user/componentesEditarPerfil/img/Eyhe_icone_chat.svg'
import iconConversaMultimidaPsico from '../sections/@dashboard/user/componentesEditarPerfil/img/Eyhe_icone_multimidia (1).svg'

import iconConversaBasicaTerapeuta from '../sections/@dashboard/user/componentesEditarPerfil/img/Eyhe_terapeuta_chat.svg'
import iconConversaMultimidaTerapeuta from '../sections/@dashboard/user/componentesEditarPerfil/img/Eyhe_terapeuta_miltimidia.svg'

import iconConversaBasicaCoach from '../sections/@dashboard/user/componentesEditarPerfil/img/Eyhe_coach_chat.svg'
import iconConversaMultimidaCoach from '../sections/@dashboard/user/componentesEditarPerfil/img/Eyhe_coach_multimidia.svg'


export function getCoresTemplate(tipoAtendente: any) {
  if (tipoAtendente === 1) {
    /* CONFIGURAÇÃO ANJO */
    return {
      corPrincipal: 'corPrincipalAnjo',
      corSecundaria: 'corSecundariaAnjo',
      degradeContribua: 'degradeContribuaAnjo',
      imagemBackgroundPerfil: 'backgroundTopoAnjo',
      capaAtendenteDashFirstDesk: 'CapaAtendenteDashFirstDeskAnjo',
      hexPrincipal: '#26cfff',
      iconConversaBasica: iconConversaBasicaPsico,
      iconConversaMultimidia: iconConversaMultimidaPsico,
    };
  }

  if (tipoAtendente === 2) {
    /* CONFIGURAÇÃO PSICOLOGO */
    return {
      corPrincipal: 'corPrincipalPsicologo',
      corSecundaria: 'corSecundariaPsicologo',
      degradeContribua: 'degradeContribuaPsicologo',
      imagemBackgroundPerfil: 'backgroundTopoPsicologo',
      capaAtendenteDashFirstDesk: 'CapaAtendenteDashFirstDeskPsicologo',
      hexPrincipal: '#7635dc',
      iconConversaBasica: iconConversaBasicaPsico,
      iconConversaMultimidia: iconConversaMultimidaPsico,
    };
  }

  if (tipoAtendente === 3) {
    /* CONFIGURAÇÃO PSICANALISTA */
    return {
      corPrincipal: 'corPrincipalPsicanalista',
      corSecundaria: 'corSecundariaPsicanalista',
      degradeContribua: 'degradeContribuaPsicanalista',
      imagemBackgroundPerfil: 'backgroundTopoPsicanalista',
      capaAtendenteDashFirstDesk: 'CapaAtendenteDashFirstDeskPsicanalista',
      hexPrincipal: '#000',
      iconConversaBasica: iconConversaBasicaPsico,
      iconConversaMultimidia: iconConversaBasicaPsico,
    };
  }

  if (tipoAtendente === 4) {
    /* CONFIGURAÇÃO TERAPEUTA */
    return {
      corPrincipal: 'corPrincipalTerapeuta',
      corSecundaria: 'corSecundariaTerapeuta',
      degradeContribua: 'degradeContribuaTerapeuta',
      imagemBackgroundPerfil: 'backgroundTopoTerapeuta',
      capaAtendenteDashFirstDesk: 'CapaAtendenteDashFirstDeskTerapeuta',
      hexPrincipal: '#ffbe98',
      iconConversaBasica: iconConversaBasicaTerapeuta,
      iconConversaMultimidia: iconConversaMultimidaTerapeuta,
    };
  }

  if (tipoAtendente === 5) {
    /* CONFIGURAÇÃO COACH */
    return {
      corPrincipal: 'corPrincipalCoach',
      corSecundaria: 'corSecundariaCoach',
      degradeContribua: 'degradeContribuaCoach',
      imagemBackgroundPerfil: 'backgroundTopoCoach',
      capaAtendenteDashFirstDesk: 'CapaAtendenteDashFirstDeskCoach',
      hexPrincipal: '#5dbfc0',
      iconConversaBasica: iconConversaBasicaCoach,
      iconConversaMultimidia: iconConversaMultimidaCoach,
    };
  }




  return {
    corPrincipal: 'corPrincipalAnjo',
      corSecundaria: 'corSecundariaAnjo',
      degradeContribua: 'degradeContribuaAnjo',
      imagemBackgroundPerfil: 'backgroundTopoAnjo',
      capaAtendenteDashFirstDesk: 'CapaAtendenteDashFirstDeskAnjo',
      hexPrincipal: '',
      iconConversaBasica: iconConversaBasicaPsico,
      iconConversaMultimidia: iconConversaMultimidaPsico,
   };
}
