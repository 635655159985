import { useState, useEffect, useCallback } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
  Box,
  Alert,
  Stack,
  Drawer,
  IconButton,
  IconButtonProps,
  CircularProgress,
  Typography,
  Button,
  Tooltip,
  Divider,
  List,
  Pagination,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  MenuItem,
  Select,
} from '@mui/material';
// hooks

import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import useResponsive from '../../../hooks/useResponsive';

// utils
import axios from '../../../utils/axios';
// routes
import { PATH_AUTH, PATH_DASHBOARD } from '../../../routes/paths';
// @types
import { IChatContact, IChatConversationsState } from '../../../@types/chat';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
//
import ChatNavList from '../chat/nav/ChatNavList';
import ChatNavSearch from '../chat/nav/ChatNavSearch';
import ChatNavAccount from '../chat/nav/ChatNavAccount';
import ChatNavSearchResults from '../chat/nav/ChatNavSearchResults';
import MenuPopover from '../../../components/menu-popover';
import BadgeStatus, { BadgeStatusValue } from '../../../components/badge-status';
import { useAuthContext } from '../../../auth/useAuthContext';
import { useSnackbar } from '../../../components/snackbar';
import BuscarAtendimento from '../chat/nav/BuscarAtendimento';
import ChatAtendimentoLista from '../chat/nav/ChatAtendimentoLista';
import './Avaliacoes.css';
import AvaliacaoIndividual from './AvaliacaoIndividual';

// ----------------------------------------------------------------------

const StyledToggleButton = styled((props) => (
  <IconButton disableRipple {...props} />
))<IconButtonProps>(({ theme }) => ({
  left: 0,
  zIndex: 9,
  width: 32,
  height: 32,
  position: 'absolute',
  top: theme.spacing(13),
  borderRadius: `0 12px 12px 0`,
  color: theme.palette.primary.contrastText,
  backgroundColor: theme.palette.primary.main,
  boxShadow: theme.customShadows.primary,
  '&:hover': {
    backgroundColor: theme.palette.primary.darker,
  },
}));

// ----------------------------------------------------------------------

const NAV_WIDTH = 320;

const NAV_COLLAPSE_WIDTH = 96;

export default function Avaliacoes() {
  const { enqueueSnackbar } = useSnackbar();

  const [avaliacoes, setAvaliacoes] = useState<any>({ last_page: 1, data: [] });

  const [page, setpage] = useState<any>(1);
  const [loading, setloading] = useState<any>(true);

  const [seachstate, setSeach] = useState('');
  const getavaliacoes = useCallback(async () => {
    setloading(true);
    try {
      const response = await axios.post(`/areadoatendente/avaliacoes?page=${page}`, {
        query: seachstate,
      });

      const { avaliacao } = response.data;

      setAvaliacoes(avaliacao);
      setloading(false);
    } catch (error) {
      console.error(error);
    }
  }, [page, seachstate]);

  useEffect(() => {
    getavaliacoes();
  }, [getavaliacoes]);

  const theme = useTheme();

  const STATUS = ['online', 'invisible', 'away'] as const;

  const navigate = useNavigate();

  const isDesktop = useResponsive('up', 'md');

  const [openNav, setOpenNav] = useState(true);

  const { user, logout } = useAuthContext();
  const [searchContacts, setSearchContacts] = useState('');

  const isCollapse = isDesktop && !openNav;

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
  const [status, setStatus] = useState<BadgeStatusValue>('online');
  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  useEffect(() => {
    if (!isDesktop) {
      handleCloseNav();
    } else {
      handleOpenNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDesktop]);

  const handleToggleNav = () => {
    setOpenNav(!openNav);
  };

  const handleOpenNav = () => {
    setOpenNav(true);
  };

  const handleCloseNav = () => {
    setOpenNav(false);
  };
  const handleLogout = async () => {
    try {
      logout();
      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Erro ao sair', { variant: 'error' });
    }
  };

  // const Search = async () => {
  //     try {
  //
  //         setSearchContacts(seachstate);
  //
  //         const response = await axios.post(`/areadoatendente/avaliacoes?page=${page}`, {
  //             query: seachstate
  //         });
  //
  //         setAvaliacoes(response.data.avaliacao);
  //
  //     } catch (error) {
  //         console.error(error);
  //     }
  // };

  const handleChangeSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSeach(value);
  };

  const handleSelectContact = (result: any) => {
    setSearchContacts('');
    navigate(PATH_DASHBOARD.chat.view(result.idconvesa));
  };

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    console.log(value);
    setpage(value);
  };

  const renderContent = (
    <>
      <Box sx={{ p: 2.5 }}>
        <Button
          variant="text"
          onClick={() => {
            navigate(PATH_DASHBOARD.dashfirst, { replace: true });
          }}
        >
          {' '}
          <Iconify icon="eva:arrow-ios-back-outline" /> Dashboard{' '}
        </Button>

        <div className="BreadCrumbsListagemMaterial">
          <CustomBreadcrumbs
            heading="Avaliações"
            links={[
              {
                name: 'Dashboard',
                href: PATH_DASHBOARD.root,
              },
              {
                name: 'Avaliações',
                href: PATH_DASHBOARD.avaliacoes,
              },
            ]}
          />
        </div>

        {avaliacoes.total === 0 ? (
          <Alert variant="outlined" severity="info">
            Você ainda não tem avaliações
          </Alert>
        ) : (
          <>
            <FormControl fullWidth sx={{ m: 1 }}>
              <OutlinedInput
                id="outlined-adornment-amount"
                placeholder="Buscar"
                value={seachstate}
                onChange={handleChangeSearch}
                startAdornment={
                  <InputAdornment position="start">
                    {' '}
                    <Iconify icon="eva:search-fill" />{' '}
                  </InputAdornment>
                }
              />
            </FormControl>
            {loading === false ? (
              <>
                {avaliacoes.data.map((avalia: any, index: any) => (
                  <AvaliacaoIndividual key={index} avalia={avalia} atualizar={getavaliacoes} />
                ))}
                <Stack className="paginationChatAtendimentoLista" spacing={2}>
                  <Pagination
                    count={avaliacoes.last_page}
                    variant="outlined"
                    onChange={handleChange}
                    shape="rounded"
                  />
                </Stack>
              </>
            ) : (
              <div className="divprogress">
                <CircularProgress className="progress" />
              </div>
            )}
          </>
        )}
      </Box>
    </>
  );

  return <>{renderContent}</>;
}
