// @mui
import {InputAdornment, ClickAwayListener, Grid} from '@mui/material';
// components
import Dialog from "@mui/material/Dialog";
import * as React from "react";
import Iconify from '../../../../components/iconify';
import { CustomTextField } from '../../../../components/custom-input';
import Notificacoes from "../../Notificacoes/Notificacoes";
import FimAtendimento from "./noticacoeschat/FimAtendimento";
import {useSnackbar} from "../../../../components/snackbar";

// ----------------------------------------------------------------------

type Props = {
    time: any;
    Atendente: any;
    tipoConversa: any;
    openhist: any;
    setOpenhis: any;
};

export default function ChatNotificacoes({ time, Atendente,tipoConversa,openhist,setOpenhis  }: Props) {
    const { enqueueSnackbar } = useSnackbar();

    React.useEffect(() => {

        if(tipoConversa.Duracao === 20){

            if(time <= 1200000 && time >= 1140000){
                enqueueSnackbar(`Você tem 20 minutos de atendimento`, { variant: 'info' });
            }
        }

        if(tipoConversa.Duracao === 50){

            if(time <= 1800000 && time >= 1740000){
                enqueueSnackbar(`Você tem 30 minutos de atendimento`, { variant: 'info' });
            }
            if(time <= 3000000 && time >=2940000){
                enqueueSnackbar(`Você tem 50 minutos de atendimento`, { variant: 'info' });
            }
        }

        if(time <= 300000 && time >= 240000){
            enqueueSnackbar(`Você tem apenas mais 5 minutos de atendimento`, { variant: 'info' });
        }
        if(time <= 60000 && time >= 1000){
            enqueueSnackbar(`Entramos no último minuto de atendimento`, { variant: 'info' });
        }
        if(time <= 600000 && time >= 540000){
            enqueueSnackbar(`Você tem 10 minutos de atendimento`, { variant: 'info' });
        }

    }, [tipoConversa,time,enqueueSnackbar]);

    const verHistorico = ()=> {
        setOpenhis(false);
    }
  return (
        <>
        {time === 0 ? (
            <Dialog
                fullScreen
                scroll='body'
                open={openhist}
            >
                <FimAtendimento Atendente={Atendente} verHistorico={verHistorico}/>
            </Dialog>
        ) :'' }
        </>
  );
}
