import * as React from 'react';

import {
    Divider,
    Tabs,
    Tab,
    IconButton,
    Button,
    TextField,
    InputLabel,
    Slide,
    DialogProps,
    List,
    MenuItem,
    FormControl,
    OutlinedInput,
    Alert,
    InputAdornment,
    Paper,
    Box,
    Grid,
    Badge,
    Avatar,
    Select,
    Stack,
    Rating,
    Tooltip,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    MobileStepper ,
    
  } from '@mui/material';

import { styled, useTheme  } from '@mui/material/styles';



import { TransitionProps } from '@mui/material/transitions';
import Iconify from 'src/components/iconify';

import imgDialogBoasVindas from './materiais_media/Eyhe_imagem_modal_atendentes_geral.webp'

type Props = {
    openDialogBoasVindas: any;
    handleCloseDialogBoasVindas: any;
};

function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="down" ref={ref} {...props} />;
  }

// Em seguida, use React.forwardRef para criar um componente de transição reutilizável
const ForwardedTransitionBoasVindas = React.forwardRef(Transition);



export default function DialogBoasVindas({openDialogBoasVindas, handleCloseDialogBoasVindas}:Props) {
  
    const theme = useTheme();
    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');

  return (
    <>
      
      <Dialog
        open={openDialogBoasVindas}
        onClose={handleCloseDialogBoasVindas}
        TransitionComponent={ForwardedTransitionBoasVindas}
        maxWidth={maxWidth}
        sx={{p: 3}}
      >
          <DialogContent sx={{p: 3, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>

          <img src={imgDialogBoasVindas} width={350} alt='Imagem Dialog Atendentes gerais'/>
          <Typography variant='h4' sx={{textAlign: 'center', my: 2}}>Olá atendente Eyhe, tudo bem?</Typography>
          <Typography sx={{textAlign: 'center'}}>O seu trabalho e dedicação são fundamentais para acolher as dores e desafios de milhares de pessoas em todo Brasil. Obrigado por fazer parte da comunidade Eyhe.</Typography>
          
          </DialogContent>

          <DialogActions>
          <Button onClick={handleCloseDialogBoasVindas} variant='contained'>
            Ir para o dashboard <Iconify icon='eva:arrow-ios-forward-fill' />
          </Button>
        </DialogActions>

      </Dialog>
    </>
  );
}