import * as React from 'react';

import {
    Divider,
    Tabs,
    Tab,
    IconButton,
    Button,
    ButtonGroup,
    TextField,
    InputLabel,
    Slide,
    DialogProps,
    List,
    MenuItem,
    FormControl,
    OutlinedInput,
    Alert,
    InputAdornment,
    Paper,
    Box,
    Grid,
    Badge,
    Avatar,
    Select,
    Stack,
    Rating,
    Tooltip,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    MobileStepper ,
    
  } from '@mui/material';
  import { styled, useTheme  } from '@mui/material/styles';
  import Iconify from 'src/components/iconify';
  import LoadingButton from '@mui/lab/LoadingButton';
  import { TransitionProps } from '@mui/material/transitions';

  import {useAuthContext} from "../../../../auth/useAuthContext";
  import {useSnackbar} from "../../../../components/snackbar";
  import imgMudarStatus from './img/mudarStatus.jpg';





type Props = {
    openDialogMudarStatus: any;
    handleCloseDialogMudarStatus: any;
};

function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="down" ref={ref} {...props} />;
  }

// Em seguida, use React.forwardRef para criar um componente de transição reutilizável
const ForwardedTransitionMudarStatus = React.forwardRef(Transition);





export default function DialogMudarStatus({openDialogMudarStatus, handleCloseDialogMudarStatus}:Props) {
    const { enqueueSnackbar } = useSnackbar();
    const { user,mudarstatus,getuser } = useAuthContext();
    const theme = useTheme();
    const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');

    const [loadingStatus, setLoadingStatus] = React.useState(false);
    
    const handleStatusChange = async (status: any) => {

      setLoadingStatus(true);

        try {

            if (mudarstatus) {
                await mudarstatus({status});
            }

            enqueueSnackbar(`Status foi alterado para ${status}`, {variant: 'success'});
            handleCloseDialogMudarStatus();
            setLoadingStatus(false);
           // document.location.reload();
           // navigate(0);
        } catch (error) {
            enqueueSnackbar(`${error.message}`, {variant: 'error'});
            setLoadingStatus(false);
        }
    };



  return (
    <>
      
      <Dialog
        open={openDialogMudarStatus}
        onClose={handleCloseDialogMudarStatus}
        TransitionComponent={ForwardedTransitionMudarStatus}
        maxWidth={maxWidth}
        sx={{p: 0, m:0}}
      >
          <DialogContent sx={{p: 0, m:0, display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>

            <img style={{width: '400px', borderRadius: '16px'}} src={imgMudarStatus} alt='Você deseja trocar o status?' />
      
          
          <ButtonGroup size='large' aria-label="loading button group" sx={{mt: -3}}>
            <LoadingButton
                color='success'
                startIcon={<Iconify icon='eva:checkmark-circle-2-outline' />}
                onClick={() => handleStatusChange('Disponivel')}
                loading={loadingStatus}
                variant='contained'
            >
                Online
            </LoadingButton>
            <LoadingButton
                color='error'
                startIcon={<Iconify icon='eva:close-circle-outline' />}
                onClick={() => handleStatusChange('Offline')}
                loading={loadingStatus}
                variant='contained'
            >
                Offline
            </LoadingButton>
            </ButtonGroup>

          </DialogContent>

          <DialogActions sx={{ display:'flex', justifyContent: 'center'}}>
          <Button onClick={handleCloseDialogMudarStatus} variant='text'>
            Cancelar <Iconify icon='eva:arrow-ios-forward-fill' />
          </Button>
        </DialogActions>

      </Dialog>
    </>
  );
}