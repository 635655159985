import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { CustomAvatar } from "src/components/custom-avatar";
import {
    Card,
    Grid,
    IconButton,
    Typography,
    Rating,
    Dialog,
    Button,
    DialogTitle,
    DialogContent,
    DialogContentText, DialogActions
} from '@mui/material';
// @mui
import { styled } from '@mui/material/styles';
import {LoadingButton} from "@mui/lab";
import {useSettingsContext} from '../../../../components/settings';

import '../DetalhesAgendamento.css';

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// routes
import {PATH_DASHBOARD} from '../../../../routes/paths';
import axios from "../../../../utils/axios";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";

import Avatar from './avatar.png'
import CardAgendamento from './cardAgendamento';
import CardRemarcarAtendimento from './cardRemarcarAtendimento';
import {useSnackbar} from "../../../../components/snackbar";

type Props = {
    voltarDetalhesAgendamento: any;
    open: any;
    setOpen: any;
    handleClickOpen: any;
    handleClose: any;
    AgenDados: any;

};

export default function RemarcarAtendimento({voltarDetalhesAgendamento, open, setOpen, handleClickOpen, handleClose,AgenDados} :Props) {


    const [Date, setDate] = useState('');
    const {id = ''} = useParams();
    const { enqueueSnackbar } = useSnackbar();
    const [ConfirmaOpen2, setConfirmaOpen2] = useState(false);
    const [isSubmitSuccessful, setisSubmitSuccessful] = useState(false);
    const navigate = useNavigate();
    const handleDate = (date:any) => {
        console.log(date);
        setDate(date);
    }



    const enviarnovaData = async () => {
        setConfirmaOpen2(false);


        try {
            const response = await axios.post('/areadoatendente/agendamento/remacar', {idAgendamento: id,novadata:Date});

            setisSubmitSuccessful(false);


            enqueueSnackbar(`Remarcado com sucesso`, {variant: 'success'});
                setTimeout(() =>{
                    navigate(PATH_DASHBOARD.agendamentos);
                },2000);

        } catch (error) {
            enqueueSnackbar(`${error.message}`, {variant: 'error'});
            setisSubmitSuccessful(false);
        }


    }
    const abrirmodal = () => {
        setConfirmaOpen2(true);
        setisSubmitSuccessful(true);

    }
    const fecharmodal2 = () => {
        setConfirmaOpen2(false);
        setisSubmitSuccessful(false);
    }

    return (

            <>

                <Dialog
                    open={ConfirmaOpen2}
                    onClose={fecharmodal2}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">

                        Remarcar atendimento
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            tem certeza que deseja Remarcar atendimento ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={fecharmodal2}>Cancelar</Button>
                        <Button onClick={enviarnovaData} autoFocus>
                            Remarcar atendimento
                        </Button>
                    </DialogActions>
                </Dialog>



                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                >

                <div className='RemarcarAtendimentoDialogGeral'>
                    <Grid
                        container
                        direction="row"
                        justifyContent="flex-start"
                        alignItems="center"
                    >
                        <IconButton onClick={voltarDetalhesAgendamento} >
                        <Iconify icon="eva:arrow-ios-back-fill" />
                        </IconButton>
                        <Typography>Meu agendamento</Typography>
                    </Grid>

                    <CardAgendamento AgenDados={AgenDados}/>

                    <CardRemarcarAtendimento enviarhora={handleDate} AgenDados={AgenDados} />




                    <LoadingButton
                        fullWidth
                        color="success"
                        className='btn_Login_Auth'
                        size="large"
                        onClick={abrirmodal}
                        variant="contained"
                        loading={isSubmitSuccessful}
                    >
                        Enviar Sugestão para o Herói
                    </LoadingButton>




                    <Typography className='avisoRemarcarAtendimento'>Sua sugestão de nova data e horário será enviado para o Herói, ele poderá aceitar, sugerir outro horário ou cancelar o atendimento.</Typography>
                </div>
                </Dialog>

            </>
    );
}
