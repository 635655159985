
import {useState, useRef, useEffect} from 'react';
// @mui
import { alpha, useTheme, styled } from '@mui/material/styles';
import useResponsive from "src/hooks/useResponsive";
import { Box, Card, Paper,FormControl,InputLabel,MenuItem,Select,Rating,Button,Chip, Typography, CardContent,SelectChangeEvent, Divider, IconButton, Grid, Dialog,DialogTitle, DialogContent, DialogContentText, DialogActions, DialogProps } from '@mui/material';
import Iconify from 'src/components/iconify/Iconify';
import {fData} from "../../../../utils/formatNumber";
import {fDatacerta, fDate} from "../../../../utils/formatTime";

import '../Dashfirst.css';
// utils

// components

// ----------------------------------------------------------------------

type Props = {
    listarmaterial: any;
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

export default function MaterialdeApoioIndividual({listarmaterial}:Props) {
    
    const isDesktop = useResponsive('up', 'lg');

    const [fullWidth, setFullWidth] = useState(true);
    const [maxWidth, setMaxWidth] = useState<DialogProps['maxWidth']>('md');

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const [tipoConteudo, setTipoConteudo] = useState('audio') // texto || audio || video //

    useEffect(() => {

        setTipoConteudo(listarmaterial.tipo);

    }, [listarmaterial]);


    console.log(tipoConteudo);

    // eslint-disable-next-line react/destructuring-assignment
    const RenderHTML = (props:any) => (<span dangerouslySetInnerHTML={{__html:props.HTML}} />)

  return (
    <>
   
            
                
                <div className='MaterialApoioIndividualCard'>
                    <div className='HeaderMateriaisApoioDashboard'>
                        <div className='IconTituloeDataMaterialApoioIndividual'>
                            <div className='IconMateriaisCircle'>
                                <Iconify className='IconMateriais' icon="eva:browser-outline"/>
                            </div>
                            <div className='TituloeDataMaterialdeApoio'>
                                <Typography className='DataMaterialApoioIndividual'>{fDatacerta(listarmaterial.created_at)}</Typography>
                                <Typography className='TituloMaterialApoioIndividual'>{listarmaterial.titulo}</Typography>
                            </div>
                        </div>
                        {listarmaterial.novo === '1' &&(<Chip label="Novo" className='IndicadorNotificacaoMateriaisApoioDashInicial'/>)}

                    </div>
                    {tipoConteudo === 'download' ? (
                <Button variant='outlined' href={listarmaterial.urldownload} target="_blank" download className='BotaoVerMaterialIndividual' fullWidth>Baixar material</Button>
                        ): (
                        <Button variant='outlined' className='BotaoVerMaterialIndividual' onClick={handleClickOpen} fullWidth>Ver material</Button>
                    )}
                </div>
                

        
            <Dialog
                    open={open}
                    onClose={handleClose}
                    fullWidth={fullWidth}
                    maxWidth={maxWidth}
                >
                    <DialogTitle>
                        {listarmaterial.titulo}
                    </DialogTitle>
                    <DialogContent>
                    <DialogContentText>

                        {tipoConteudo === 'texto' ? (
                            <Typography>
                                <RenderHTML  HTML={listarmaterial.texto} />
                            </Typography>
                        ) : ('')}
                        
                        {tipoConteudo === 'audio' ? (
                        <audio controls id='AudioMaterialApoio'>
                            <source
                            src={listarmaterial.audio}
                            type="audio/mpeg" // Substitua pelo tipo de arquivo correto, se necessário
                            />
                            <track kind="captions" />
                            Seu navegador não suporta elementos de áudio.
                        </audio>
                        ) : ('')}


                        {tipoConteudo === 'video' ? (
                            // eslint-disable-next-line jsx-a11y/media-has-caption
                        <video width="100%" height="100%" controls src={listarmaterial.video} />
                        ) : null}

                    </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                    <Button onClick={handleClose} sx={{p: 1.5}} variant="outlined">Voltar</Button>
                    <Button onClick={handleClose} sx={{p: 1.5}} variant="contained" autoFocus>
                        Visualizado
                    </Button>
                    </DialogActions>
                </Dialog>


    </>
  );
}