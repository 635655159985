import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import {
    Grid,
    IconButton,
    Typography,
    Button,
    Tooltip,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions, Dialog
} from '@mui/material';
// @mui
import { styled } from '@mui/material/styles';
import {LoadingButton} from "@mui/lab";
import {useSettingsContext} from '../../../components/settings';
import './DetalhesAgendamento.css';
import CardAgendamento from './componentesDetalhesAgendamento/cardAgendamento';

import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// routes
import {PATH_DASHBOARD} from '../../../routes/paths';
import axios from "../../../utils/axios";
import {useAuthContext} from "../../../auth/useAuthContext";
import Iconify from "../../../components/iconify";
import CardInfosAtendimento from './componentesDetalhesAgendamento/cardInfosAtendimento';
import CardAvaliacao from './componentesDetalhesAgendamento/cardAvaliacao';
import RemarcarAtendimento from './componentesDetalhesAgendamento/remarcarAtendimento';
import {useSnackbar} from "../../../components/snackbar";




export default function DetalhesAgendamento() {
    const { enqueueSnackbar } = useSnackbar();

  const navigate = useNavigate();
    const {id = ''} = useParams();

    const [AgenDados, setAgen] = useState<any>({});
    const [atendimento, setatendimento] = useState<any>(false);

    const getmessage = useCallback(async () => {
      try {
          const response = await axios.post('/areadoatendente/agendamento/detalhe',{idAgendamento:id});

          const {agendamento} = response.data;
          setAgen(agendamento)
          setatendimento(agendamento.isPast)

      } catch (error) {
          console.error(error);
      }
  },[id]);




  useEffect(() => {

      getmessage();

  }, [getmessage]);


  const pageDash = () => {
    navigate(PATH_DASHBOARD.agendamentos);
};






  const [open, setOpen] = useState(false);
  const [ConfirmaOpen, setConfirmaOpen] = useState(false);
  const [CancelarOpen, setCancelarOpen] = useState(false);
  const [isSubmitSuccessful, setisSubmitSuccessful] = useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const atenderachamada = () => {
        setConfirmaOpen(true)
        setisSubmitSuccessful(true);

        
    };

    const atenderachamadaconfirmado = async () => {
        setConfirmaOpen(false)

        try {
            const response = await axios.post('/areadoatendente/atenderchamadaagendada', {idAgendamento: id});

            setisSubmitSuccessful(false);


            const {chamada} = response.data;
            navigate(PATH_DASHBOARD.chat.view(chamada))

        } catch (error) {
            enqueueSnackbar(`${ error.message}`, { variant: 'error' });
            setisSubmitSuccessful(false);
        }


    };

    const handleClose = () => {
        setOpen(false);
        setConfirmaOpen(false)
    };

    const handleCloseCancelar = () => {
        setCancelarOpen(false);

    };

    const handleOpenCancelar = () => {
        setCancelarOpen(true);

    };


    const atenderachamadacancelar = async () => {


        try {
            const response = await axios.post('/areadoatendente/agendamento/cancelar', {idAgendamento: id});


            enqueueSnackbar(`Agendamento cancelado com sucesso`, {variant: 'success'});
            navigate(PATH_DASHBOARD.agendamentos)
            handleCloseCancelar();

        } catch (error) {
            enqueueSnackbar(`${ error.message}`, { variant: 'error' });
            setisSubmitSuccessful(false);
        }


    };

    const [tooltipOpen, setTooltipOpen] = useState(false);



    return (
            <>

                <Dialog
                    open={ConfirmaOpen}
                    onClose={handleClose}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">

                        Iniciar atendimento
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          tem certeza que deseja Iniciar atendimento ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Cancelar</Button>
                        <Button onClick={atenderachamadaconfirmado} autoFocus>
                            Iniciar atendimento
                        </Button>
                    </DialogActions>
                </Dialog>       


                <Dialog
                    open={CancelarOpen}
                    onClose={handleCloseCancelar}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    <DialogTitle id="alert-dialog-title">

                        Cancelar atendimento
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                          tem certeza que deseja Cancelar atendimento ?
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseCancelar}>Não cancelar</Button>
                        <Button onClick={atenderachamadacancelar} autoFocus>
                            Cancelar atendimento
                        </Button>
                    </DialogActions>
                </Dialog>

                
                
                
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                sx={{pb:2}}
              >
                <IconButton onClick={pageDash} >
                  <Iconify icon="eva:arrow-ios-back-fill" />
                </IconButton>
                <Typography>Meus agendamentos</Typography>
              </Grid>

              <div className='detalhesAgendamentoGlobal'>
              <Typography variant="h4" sx={{py:2}}>Detalhes do agendamento #{id}</Typography>

              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
              >
                <Iconify icon='eva:calendar-outline' />
                <Typography sx={{mr: 2, ml: 0.5}}>{AgenDados.dia}</Typography>

                <Iconify icon='eva:clock-outline' />
                <Typography sx={{ml: 0.5}}>{AgenDados.hora}</Typography>
              </Grid>

              <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      sx={{mt: 1.5}}
                    >
                      <Iconify icon="eva:info-outline" sx={{ mr: 1 }} /> 
                      <Typography variant='subtitle2'>  Horário de Brasília</Typography>
                    </Grid>

              <CardAgendamento AgenDados={AgenDados} />

              <CardInfosAtendimento  AgenDados={AgenDados}/>

            


         {AgenDados.Status === 'Finalizado' ? (  <CardAvaliacao/>) :(
          <>

            <Tooltip title= {atendimento === true ? ("O botão estará disponível somente no horário de inicio do seu atendimento") : ('')}  arrow>
                <div>
              <LoadingButton
                  fullWidth
                  color="success"
                  className='btn_Login_Auth'
                  size="large"
                  onClick={atenderachamada}
                  variant="contained"
                  loading={isSubmitSuccessful}
                  disabled={atendimento === true}
                  sx={{mb: 0.5}}
              >
                  <Iconify icon='eva:message-square-outline' sx={{mr: 1}} /> Iniciar atendimento
              </LoadingButton>
              </div>
            </Tooltip>



              <Button variant='contained' size="large" disabled={atendimento === false}  color='primary' sx={{my: 0.5}} fullWidth onClick={handleClickOpen} > <Iconify icon='eva:calendar-outline' sx={{mr: 1}} /> Remarcar atendimento</Button>
              <Button variant='contained' size="large" disabled={atendimento === false} onClick={handleOpenCancelar} color='error' sx={{mt: 0.5}} fullWidth > <Iconify icon='eva:log-out-fill' sx={{mr: 1}} /> Cancelar atendimento</Button>

              <RemarcarAtendimento  voltarDetalhesAgendamento={handleClose} open={open} AgenDados={AgenDados} setOpen={setOpen} handleClickOpen={handleClickOpen} handleClose={handleClose} />
          </>
         )}

        
           

            </div>
            </>
    );
}
