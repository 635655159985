import * as React from 'react';
import {CssBaseline, Box, Container, Typography, Tooltip, Grid, Paper  } from "@mui/material"; 
import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import  "./saladevideo.css";
// @mui
import { styled } from '@mui/material/styles';
import Dialog from "@mui/material/Dialog";
import {useSettingsContext} from '../../../../components/settings';
// eslint-disable-next-line import/no-cycle



import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// routes
import {PATH_DASHBOARD} from '../../../../routes/paths';
import axios from "../../../../utils/axios";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";
// eslint-disable-next-line import/no-cycle
import HeaderSaladeVideo from "./HeaderSaladeVideo";
import RodapeSaladeVideoAtivo from "./RodapeSaladeVideoAtivo";
import EncerraSalaVideo from "./EncerraSalaVideo";

// ----------------------------------------------------------------------
const OT = require("@opentok/client");

// ----------------------------------------------------------------------

let session: any;
let publisher: any;
let subscriber: any;

type props = {
    EncerraVideo: any;
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


export default function VideoAtivo({EncerraVideo}:props) {

    const { user } = useAuthContext();
    const {tokenConversa = ''} = useParams();
    const [usera,setuser] = useState<any>({});
    const [encerrar,setencerrar] = useState<any>(false);


    const navigate = useNavigate();





    useEffect(  () => {




        if(!sessionStorage.getItem(`tokenConversa`)){
            navigate(0);
            sessionStorage.setItem(`tokenConversa`, '1');
        }

        setTimeout(() => {
            sessionStorage.removeItem(`tokenConversa`);
        }, 10000);

        const fetchRepos2 = async () => {
            const response = await axios.get(`/areadoatendente/atendente/${tokenConversa}`);

            const {atendente} = response.data;


            setuser(atendente)
        }
        fetchRepos2();

    },[tokenConversa,setuser,navigate]);


    useEffect(  () => {



        const fetchRepos = async () => {





            try {
                const response = await axios.post(`/areadoatendente/gettokentokbox`,{id:tokenConversa});


                console.log(response.data);
                const {apiKey} = response.data;
                const {sessionId} = response.data;
                const {token} = response.data;







                // eslint-disable-next-line react-hooks/exhaustive-deps
                session = OT.initSession(apiKey, sessionId);
                // create a publisher
                // eslint-disable-next-line react-hooks/exhaustive-deps
                publisher = OT.initPublisher(
                    "publisher",
                    {
                        insertMode: "append",
                        width: "100%",
                        height: "100%",
                        style: {buttonDisplayMode: 'off'},
                        showControls: true
                    }
                );
                // subscribe to newly created stream
                session.on("streamCreated", (event: any) => {
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    subscriber = session.subscribe(
                        event.stream,
                        "subscriber",
                        {
                            insertMode: "append",
                            width: "100%",
                            height: "100%",
                            style: {buttonDisplayMode: 'on'},
                            showControls: true
                        }
                    );
                });

                // connect to the session
                session.connect(token, (error: any) => {
                    // If the connection is successful, publish to the session

                    if (error) { /* empty */
                    } else {
                        session.publish(publisher);
                    }
                });
                // do some action upon destroying the created stream
                session.on("streamDestroyed", async (event: any) => {
                    console.log("The Video chat has ended");
                });




            } catch (error) {
                console.error(error);
            }
        };



        fetchRepos();

    },[navigate, tokenConversa] );






    const Encerrarchamada = () =>{
        setencerrar(true)
    }
    const Cameraon =  () =>{
        publisher.publishVideo(true);

    }

    const Cameraoff = () =>{
        publisher.publishVideo(false);
    }
    const Microfoneon = () =>{
        publisher.publishAudio(true);
    }
    const Microfoneoff = () =>{
        publisher.publishAudio(false);
    }

    const Texto = () =>{

    }
    const UrlVoltar = () =>{
        setencerrar(false)
    }
    const fecharEcerrar = () =>{
       setencerrar(false)
    }

    const fecharvideo = () =>{
        sessionStorage.setItem(`conversafechada`, '1');
        session = null;
        publisher= null;
        subscriber= null;
        navigate(PATH_DASHBOARD.chat.view(tokenConversa),{replace:true});
    }


    return (
            <>
                <Dialog
                    
                    fullScreen
                    open={encerrar}
                >
                  <EncerraSalaVideo Backaudio={fecharEcerrar} fecharvideo={fecharvideo}/>
                </Dialog>
            <HeaderSaladeVideo UrlVoltar={Encerrarchamada}/>
            <CssBaseline />
            <Container maxWidth="xl">
                <Box sx={{ bgcolor: '#606060', height: '100vh', width: '100vw'}} id="subscriber">

                    <div className="MinhaCamAtiva" >
                        <div id="publisher"  className="publishercss"/>

                    </div>
                </Box>
            </Container>


            <RodapeSaladeVideoAtivo Encerrarchamada={Encerrarchamada} Cameraon={() => Cameraon()}  Cameraoff={() => Cameraoff()} Microfoneon={Microfoneon} Microfoneoff={Microfoneoff} Texto={Texto}/>
            </>
    );
}
