import {
    defaultIconPack,
    DyteClock,
    DyteDialogManager,
    DyteIcon,
    DyteLeaveButton,
    DyteMicToggle,
    DyteParticipantCount,
    DyteParticipantsAudio,
    DyteRecordingIndicator,
    DyteTooltip, useLanguage,
} from '@dytesdk/react-ui-kit';
import {Divider,Tabs, Tab, IconButton,Button,DialogTitle,DialogContent,Drawer,LinearProgress,ListItem,ListItemText,ListItemButton,AppBar,Slide,SlideProps ,Dialog,Toolbar,Card,Chip,TextField, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";

import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';
import {useCallback, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Iconify from "../../../components/iconify";
import Stage from './Stage';
import tchau from './img/tchau.png'
import chamadaRecusada from './img/chamadarecusada.png'
import avatar1 from '../Comunidades/img/avatar_1.jpg'
import arrows from './img/arrows.png'
import avatar2 from '../Comunidades/img/avatar_2.jpg'
import axios from "../../../utils/axios";
import {useSnackbar} from "../../../components/snackbar";
import {PATH_DASHBOARD} from "../../../routes/paths";
import {useAuthContext} from "../../../auth/useAuthContext";
import {portuguesliguage} from "../../../utils/traducao";


export default function Room({socket,usera}:any) {
    const { meeting } = useDyteMeeting();
    const audioEnabled = useDyteSelector((meetinga) => meetinga.self.audioEnabled);
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const {tokenConversa = ''} = useParams();
    const { user} = useAuthContext();

    const [openDrawer, setOpenDrawer] = useState(false);

    const toggleDrawer = (newOpen: boolean) => () => {
        setOpenDrawer(newOpen);
    };

    const alteraraudio = () => {
        if (meeting.self.audioEnabled) {
            meeting.self.disableAudio();
        } else {
            meeting.self.enableAudio();
        }

    };



    const leaveMeeting = async () => {
        meeting.leaveRoom();
        socket.emit("enviarnotifica",

            {
                iduser:`atendido${usera?.id}`,
                heroi: usera?.nome,
                heroiavatar: usera?.avatar,
                atedenteavatar: user?.avatar,
                anjo: user?.nome,
                tipo:'notificaaudio',
                tipochamada:'encerrou',
                tokenConversa,
            });
        navigate(PATH_DASHBOARD.chat.view(tokenConversa),{replace:true});
    };





    const DrawerList = (
        <Box sx={{ width: '100%', p:3, zIndex: '999', position: 'relative', overflow: 'visible'}} role="presentation" onClick={toggleDrawer(false)}>

            <img src={tchau} className='imgSairChamada' width={80} alt='Deseja sair da chamada?' />
            <Typography variant='h4' sx={{textAlign:'center', mb: 1}}>Ei, você deseja mesmo encerrar essa chamada?</Typography>
            <Typography variant='subtitle1' sx={{ textAlign: 'center', mb: 2, lineHeight: 1.3}}>Você será direcionado para o chat de mensagens novamente</Typography>

            <Grid container spacing={1}>
                <Grid item xs={6}>
                    <Button fullWidth variant='outlined' color='warning' sx={{py: 1.5}}>Voltar</Button>
                </Grid>
                <Grid item xs={6}>
                    <Button fullWidth variant='contained' color='error' sx={{py: 1.5}} onClick={leaveMeeting}>Encerrar a chamada</Button>
                </Grid>
            </Grid>




        </Box>
    );





    const [openDrawerRecusado, setOpenDrawerRecusado] = useState(false);

    const toggleDrawerRecusado = (newOpen: boolean) => () => {
        setOpenDrawerRecusado(newOpen);
    };

    const DrawerRecusado = (
        <Box sx={{ width: '100%', p:3, zIndex: '999', position: 'relative', overflow: 'visible'}} role="presentation" onClick={toggleDrawer(false)}>

            <img src={chamadaRecusada} className='imgSairChamada' width={80} alt='Chamada Recusada' />
            <Typography variant='h4' sx={{textAlign:'center', mb: 1}}>Ei, parece que sua chamada foi recusada.</Typography>
            <Typography variant='subtitle1' sx={{ textAlign: 'center', mb: 2, lineHeight: 1.3}}>Clique no botão abaixo para ser direcionado para o chat de mensagens novamente</Typography>

            <Grid container spacing={1}>
                <Grid item xs={12}>
                    <Button fullWidth variant='contained' color='error' sx={{py: 1.5}}>Voltar para o chat</Button>
                </Grid>
            </Grid>
        </Box>
    );




    useEffect(() => {
        function onKeyPress(e: KeyboardEvent) {
            if (e.ctrlKey && e.key.toLowerCase() === 'd') {
                if (meeting.self.audioEnabled) {
                    meeting.self.disableAudio();
                } else {
                    meeting.self.enableAudio();
                }
            }
        }

        window.addEventListener('keypress', onKeyPress);

        return () => {
            window.removeEventListener('keypress', onKeyPress);
        };
    }, [meeting]);




    // @ts-ignore
    return (

        <>
            <Box component="section" sx={{ p: 2, width: '100%', height: '100%', display: 'flex', flexDirection:'column', alignItems: 'center', justifyContent: 'center' }}>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    alignItems="center"
                >
                    <Stage />





                </Grid>

                <Grid
                    container
                    direction="column"
                    justifyContent="space-evenly"
                    alignItems="center">
                    <Typography color='white' variant='subtitle1' sx={{mt: 2}}>Em uma chamada com</Typography>
                    <Typography color='white' variant='h2' sx={{textAlign: 'center'}} >{usera?.nome}</Typography>

                    <DyteClock meeting={meeting}   />


                </Grid>

                <Grid
                    container
                    direction="row"
                    justifyContent="space-evenly"
                    className='menuFixedActionsCallAudio'
                    sx={{position: 'fixed', bottom: '0px', left: '0px', width: '100%', py: 2}}
                >

                    {audioEnabled ? (
                        <IconButton size='large' onClick={alteraraudio}>
                            <Iconify className='iconsActionChamadaAudio' icon="eva:mic-outline" color='white'  />
                        </IconButton>
                    ):(
                        <IconButton size='large' onClick={alteraraudio}>
                            <Iconify className='iconsActionChamadaAudio' icon="eva:mic-off-fill" color='white'  />
                        </IconButton>
                    )}


                    <IconButton size='large' className='btnEncerrarChamadaAudio' id='btnEncerrarChamadaAudio' onClick={toggleDrawer(true)}>
                        <Iconify className='iconsActionChamadaAudio' icon="eva:phone-off-outline" color='white' />
                    </IconButton>

                    <IconButton size='large'>
                        <Iconify className='iconsActionChamadaAudio' icon="eva:volume-up-outline" color='white'/>
                    </IconButton>


                    <Drawer anchor="bottom" open={openDrawer} sx={{zIndex: '9999', overflow: 'visible'}} onClose={toggleDrawer(false)}>
                        {DrawerList}
                    </Drawer>

                    <Drawer anchor="bottom" open={openDrawerRecusado} sx={{zIndex: '9999', overflow: 'visible'}} onClose={toggleDrawerRecusado(false)}>
                        {DrawerRecusado}
                    </Drawer>

                </Grid>
            </Box>
            <DyteDialogManager meeting={meeting} t={useLanguage(portuguesliguage)} />
            <DyteParticipantsAudio meeting={meeting} t={useLanguage(portuguesliguage)}/>


        </>

    );
}