// @mui
import { Card, Stack, Typography, Divider } from '@mui/material';
// utils
import { fNumber } from '../../../../utils/formatNumber';
// @types
import { IUserProfileFollowers } from '../../../../@types/user';

// ----------------------------------------------------------------------
interface Props {
  valor: any;
  tempo: any;
  atedimentos: any;
}
export default function ProfileFollowInfo({ valor, tempo,atedimentos }: Props) {
  return (
    <Card sx={{ py: 3 }} className='CardFollowers'>
      <Stack direction="row" divider={<Divider orientation="vertical" flexItem />}>
        <Stack width={1} textAlign="center">
          <Typography variant="h4">R${valor}</Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            {tempo} Minutos
          </Typography>
        </Stack>

        <Stack width={1} textAlign="center">
          <Typography variant="h4">{atedimentos}</Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }}>
            Atendimentos Feitos
          </Typography>
        </Stack>
      </Stack>
    </Card>
  );
}
