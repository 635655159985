import { useState, useRef, useEffect, useCallback } from 'react';
// @mui
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { alpha, useTheme, styled } from '@mui/material/styles';
import {
  Tab,
  Tabs,
  Card,
  Table,
  Stack,
  Grid,
  IconButton,
  Dialog,
  Button,
  Tooltip,
  Select,
  Divider,
  TableBody,
  Container,
  Paper,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  InputAdornment,
  SelectChangeEvent,
  InputLabel,
  OutlinedInput,
  Typography,
  ToggleButtonGroup,
  ToggleButton,
  FilledInput,
  MenuItem,
  Pagination,
  DialogTitle,
  DialogContent,
  DialogContentText,
  TextField,
  DialogActions,
} from '@mui/material';
import { CustomAvatar } from 'src/components/custom-avatar';
import Iconify from 'src/components/iconify/Iconify';
import ProfilePicture from '../img/profile_picture.png';
import { useAuthContext } from '../../../../auth/useAuthContext';
import { _invoices } from '../../../../_mock/arrays';
import { useSettingsContext } from '../../../../components/settings';
import { useSnackbar } from '../../../../components/snackbar';

import axios from '../../../../utils/axios';

import {
  useTable,
  getComparator,
  emptyRows,
  TableNoData,
  TableEmptyRows,
  TableHeadCustom,
  TableSelectedAction,
  TablePaginationCustom,
} from '../../../../components/table';
// utils

// components

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

export default function Financeiro() {
  const theme = useTheme();

  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();

  const {
    dense,
    page,
    order,
    orderBy,
    rowsPerPage,
    setPage,
    //
    selected,
    setSelected,
    onSelectRow,
    onSelectAllRows,
    //
    onSort,
    onChangeDense,
    onChangePage,
    onChangeRowsPerPage,
  } = useTable({ defaultOrderBy: 'id' });
  const { enqueueSnackbar } = useSnackbar();
  const [tableData, setTableData] = useState(_invoices);

  const [filterName, setFilterName] = useState('');

  const [openConfirm, setOpenConfirm] = useState(false);
  const [chave, setChavePix] = useState<any>('');
  const [tipoChave, settipoChave] = useState<any>('');
  const [valor, setvalor] = useState<any>();
  const [senha, setSenha] = useState<any>();
  const [botaodesabilitador, setbotaodesabilitador] = useState(false);
  const [filterStatus, setFilterStatus] = useState('all');

  const handlechave = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setChavePix(value);
  };
  const handlesenha = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setSenha(value);
  };

  const [filterService, setFilterService] = useState('Todos');
  const [totalfinaceiro, settotalfinaceiro] = useState(0);

  const [filterEndDate, setFilterEndDate] = useState<Date | null>(null);

  const [filterStartDate, setFilterStartDate] = useState<Date | null>(null);
  const [dataFiltered, setdataFiltered] = useState<any[]>([]);
  const [totaldados, settotal] = useState<any>({});
  const [totaldiponiveldados, settotaldiponivel] = useState<any>({});
  const [totalsaquedados, settotalsaque] = useState<any>({});
  const [totalliberarsdados, settotalliberar] = useState<any>({});
  const [totaltaxadados, settotaltaxa] = useState<any>({});

  const [SaldoPeriodo, setSaldoPeriodo] = useState('');
  const { user } = useAuthContext();
  const handleChange = (event: SelectChangeEvent) => {
    setSaldoPeriodo(event.target.value as string);
  };

  const handleChangeTipoPix = (event: SelectChangeEvent) => {
    settipoChave(event.target.value);
  };

  function currencyFormat(num: any) {
    if (num == null) {
      return `R$0,00`;
    }
    num = parseFloat(num.replace(',', '.'));

    return `R$${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  }

  const getfinaceiro = useCallback(async () => {
    try {
      const response = await axios.post(`/areadoatendente/financeiro/all?page=${page}`, {
        filterStartDate,
        filterEndDate,
        filterService,
        filterStatus,
        rowsPerPage,
        filterName,
        order,
        orderBy,
      });

      const { financeiro, total, totaldiponivel, totalsaque, totalliberar, totaltaxa } =
        response.data;

      setdataFiltered(financeiro.data);
      // setPage(financeiro.current_page);
      settotalfinaceiro(financeiro.total);
      settotal(total);
      settotaldiponivel(totaldiponivel);
      settotalsaque(totalsaque);
      settotalliberar(totalliberar);
      settotaltaxa(totaltaxa);
    } catch (error) {
      console.error(error);
    }
  }, [
    page,
    setdataFiltered,
    filterStartDate,
    filterEndDate,
    filterService,
    filterStatus,
    filterName,
    rowsPerPage,
    settotalfinaceiro,
    order,
    orderBy,
    settotal,
    settotaldiponivel,
    settotalsaque,
    settotalliberar,
    settotaltaxa,
  ]);

  useEffect(() => {
    getfinaceiro();
  }, [getfinaceiro]);

  const handlevalor = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    setvalor(value);
  };

  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const sacar = useCallback(async () => {
    setbotaodesabilitador(true);
    try {
      const response = await axios.post(`/areadoatendente/financeiro/sacar`, {
        valor,
        chave,
        senha,
        tipoChave,
      });

      enqueueSnackbar(`${response.data.message}`, { variant: 'success' });
      getfinaceiro();
      setvalor('');
      setChavePix('');
      setSenha('');
      settipoChave('');
      setbotaodesabilitador(false);
      setOpen(false);
    } catch (error) {
      enqueueSnackbar(`${error.message}`, { variant: 'error' });
    }
  }, [valor, chave, senha, tipoChave, enqueueSnackbar, getfinaceiro]);

  const modal = (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Item>
          <FormControl fullWidth sx={{ m: 1 }} variant="filled">
            <InputLabel htmlFor="filled-adornment-amount">Valor:</InputLabel>
            <FilledInput
              id="filled-adornment-amount"
              onChange={handlevalor}
              startAdornment={<InputAdornment position="start">R$</InputAdornment>}
            />
          </FormControl>

          <FormControl fullWidth sx={{ m: 1 }} variant="filled">
            <InputLabel id="demo-simple-select-label">Tipo de Chave Pix</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={tipoChave}
              label="Chave Pix"
              onChange={handleChangeTipoPix}
            >
              <MenuItem value="CPF">CPF</MenuItem>
              <MenuItem value="CNPJ">CNPJ</MenuItem>
              <MenuItem value="E-mail">E-mail</MenuItem>
              <MenuItem value="Celular">Celular</MenuItem>
              <MenuItem value="Chave">Chave Aleatória</MenuItem>
            </Select>
          </FormControl>
        </Item>
      </Grid>

      <Grid item xs={12} md={6}>
        <Item>
          <FormControl fullWidth sx={{ m: 1 }} variant="filled">
            <InputLabel htmlFor="filled-adornment-amount">Chave Pix:</InputLabel>
            <FilledInput onChange={handlechave} id="filled-adornment-amount" />
          </FormControl>

          <FormControl fullWidth sx={{ m: 1 }} variant="filled">
            <InputLabel htmlFor="filled-adornment-amount">Senha:</InputLabel>
            <FilledInput onChange={handlesenha} id="filled-adornment-amount" type="password" />
          </FormControl>
        </Item>
      </Grid>

      <Grid item xs={12} md={12}>
        <Item>
          <Typography variant='subtitle2'><b>Aviso importante:</b> Atendente Eyhe, seu pagamento será feito em até 7 dias úteis após a solicitação de saque. Para dúvidas ou sugestões, envie uma mensagem para o WhatsApp: (46) 9 9140-9093.</Typography>
        </Item>
      </Grid>

      <Grid item xs={12} md={12}>
        <Item>
          <Button
            variant="contained"
            color="success"
            sx={{ mb: 2, py: 1.5 }}
            onClick={sacar}
            disabled={botaodesabilitador}
            className="BtnSolicitarSaqueFinanceiroPage"
          >
            Solicitar Saque
          </Button>
        </Item>
      </Grid>
    </Grid>
  );

  return (
    <>
      <div className="CardFinanceiroDashboard">
        <div className="HeaderFinanceiroDashboard">
          <Typography className="TituloFinanceiroDashInicial">Seu saldo atual</Typography>
        </div>

        <Typography className="SaldoAtualFinanceiroDashboard">
          {currencyFormat(user?.SaldoAtual)}
        </Typography>

        <div className="DetalhesFinanceiroDashboard">
          <Typography>Disponível para saque</Typography>
          <Typography className="ValorDisponivelparaSaque">
            {currencyFormat(user?.SaldoAtual)}
          </Typography>
        </div>

        <div className="DetalhesFinanceiroDashboard">
          <Typography>Saldo a receber</Typography>
          <Typography>{currencyFormat(user?.SaldoAtual)}</Typography>
        </div>

        <div className="DetalhesFinanceiroDashboard">
          <Typography>Total</Typography>
          <Typography>{currencyFormat(user?.SaldoAtual)}</Typography>
        </div>

        <Button
          variant="contained"
          fullWidth
          className="BtnSolicitarSaque"
          sx={{ py: 1.5 }}
          onClick={handleClickOpen}
        >
          Solicitar Saque
        </Button>

        <Dialog open={open} onClose={handleClose}>
          <DialogTitle id="responsive-dialog-title">
            <Grid container direction="row" justifyContent="space-between" alignItems="center">
              <Typography sx={{ flex: 1 }} variant="h6" component="div">
                Solicitar saque
              </Typography>

              <Button
                sx={{ p: 0, m: 0, position: 'relative', left: '20px' }}
                autoFocus
                color="inherit"
                onClick={handleClose}
              >
                <Iconify icon="eva:close-outline" />
              </Button>
            </Grid>
          </DialogTitle>
          <DialogContent>{modal}</DialogContent>
        </Dialog>
      </div>
    </>
  );
}
