
import { useState,useEffect ,useRef,useCallback } from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
// @mui

import { alpha, useTheme } from '@mui/material/styles';
import {
    Box,
    Card,
    Paper,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Rating,
    Slider,
    Button,
    Typography,
    CardContent,
    SelectChangeEvent,
    Divider,
    IconButton,
    Dialog, Grid, DialogContent, DialogActions
} from '@mui/material';
import { CustomAvatar } from "src/components/custom-avatar";
import Iconify from 'src/components/iconify/Iconify';
import * as React from "react";
import {LoadingButton} from "@mui/lab";
import ProfilePicture from '../img/profile_picture.png';
import AvaliacaoIndividual from './AvaliacaoIndividual';
import { useSnackbar } from '../../../../components/snackbar';
import axios from '../../../../utils/axios';
import { PATH_DASHBOARD} from '../../../../routes/paths';
import {fDataHoracerta} from "../../../../utils/formatTime";
import {useAuthContext} from "../../../../auth/useAuthContext";
import fundoAcolhimento from "../img/fundo-de-acolhimento.svg";

import iconRS from '../img/icon-rs.png'

// utils

// components

// ----------------------------------------------------------------------


function valuetext(value: number) {
    return `${value}`;
  }

type Props = {
    sala: any;
    socket: any;
};
export default function SaladeEsperaCard({sala,socket}:Props) {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [progress, setProgress] = React.useState(100);
    const [time, setTime] = useState(300000);
    const [desabiltarbotaochat, setdesabiltarbotaochat] = useState(false);
    const [desabiltarbotaoaoatender, setdesabiltarbotaoaoatender] = useState(false);
    const { user } = useAuthContext();

    const [OrigemCampanha, setOrigemCampanha] = useState('RS');

    React.useEffect(() => {
    setTime(sala.timeconversa)
    }, [sala]);

    React.useEffect(() => {


        const timer = setInterval(() => {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            setTime((time) => Math.max( time - 1000, 0));
            setProgress((oldProgress) => {
                const diff = 0.33;

                return Math.min(oldProgress - diff, 100);
            });
        }, 1000);

        if(time === 0){
            clearInterval(timer);
        }



        return () => {
            clearInterval(timer);
        };
    }, [time]);


    const irparachat = async () => {
        setdesabiltarbotaochat(true);
        try {
            const response = await axios.post('/areadoatendente/atenderchamada',{chamada:sala.tokenConversa});

            const {chamada} = response.data;
            navigate(PATH_DASHBOARD.chat.view(chamada),{replace:true});
            setdesabiltarbotaochat(false);

        } catch (error) {
            enqueueSnackbar(`${ error.message}`, { variant: 'error' });
            setdesabiltarbotaochat(false);

        }
    }
    const irparachatok = async () => {


            navigate(PATH_DASHBOARD.chat.view(sala.tokenConversa),{replace:true});


    }

    const enviarnotifica =  useCallback(async () => {

        socket.emit("enviarnotifica",

            {
                iduser:`atendido${sala?.IDHeroi}`,
                tipo:'AnjoNaoChegou',
                usera:user,
                tokenConversa:sala.tokenConversa,
            });

    },[sala,socket,user]);


    const chamarplantao = async () => {
        setdesabiltarbotaoaoatender(true);
        try {
            const response = await axios.post('/areadoatendente/naopossoatender',{chamada:sala.tokenConversa});

            enqueueSnackbar(`Atendimento recusado.`, { variant: 'success' });
            setdesabiltarbotaoaoatender(false);
            enviarnotifica();
        } catch (error) {
            enqueueSnackbar(`${ error.message}`, { variant: 'error' });
            setdesabiltarbotaoaoatender(false);
        }
    }
    const [openFundosAcolhimento, setOpenFundosAcolhimento] = useState(false);

    const handleCloseFundosAcolhimento = () => {
        setOpenFundosAcolhimento(false);
    };

    const handleabrirconfirmacao = () => {
        setOpenFundosAcolhimento(true);
    };


  return (
    <>
        <div className='CardNotificacaoSaladeEspera'>
            { sala.Status === 'EmAtendimento' ? (
                    <Typography className='Titulo_CardNotificacaoSaladeEspera'>Em Andamento</Typography>
                )
                :(  <Typography className='Titulo_CardNotificacaoSaladeEspera'>Sala de Espera</Typography> )}
            { sala.Status === 'EmAtendimento' ? ( <Typography className='Subtitulo_CardNotificacaoSaladeEspera'>Você já atendeu esse herói, ele está no chat te esperando..</Typography>)
                :(<Typography className='Subtitulo_CardNotificacaoSaladeEspera'>Tem um Herói te aguardando na sala de espera, clique no botão para  atender</Typography>)}


            <div className='ItensCardNotificacaoSaladeEspera'>

                <div className='FotoNomeAssunto'>
                    <CustomAvatar
                        className="FotoPerfilAtendente"
                        src={sala.avatar}
                        name={sala.nome}
                        sx={{ cursor: 'pointer', width: 40, height: 40, mr: 2 }}
                    />

                    <div className='NomeeAssuntosCardSaladeEspera'>
                        <span style={{display: 'flex', alignItems: 'center'}}>
                            <Typography className='NotificacaoNomeCardSaladeEspera' sx={{mr: 1}}>{sala.nome}</Typography>   
                            {sala.OrigemCampanha === 'rs' ? (<img src={iconRS} alt="Origem RS" width={20} />) : ('')}
                        </span>
                        <Typography className='NotificacaoAssuntoCardSaladeEspera'>Ansiedade | Depressão</Typography>
                        <Typography className='NotificacaoAssuntoCardSaladeEspera'>{sala.tipoconversa}</Typography>
                    </div>
                </div>
                <div>
                    <div className='time_CardSaladeEspera'>
                        {sala.datacerta}
                    </div>

                    <div className='BtnIcon_CardSaladeEspera'>
                        <Iconify icon="eva:clock-outline" />
                    </div>
                </div>


                



            </div>

            { sala.Status === 'EmAtendimento' ? (
                    <div className='ButtonActionAtendimento'>


                        <LoadingButton
                            onClick={irparachatok}
                            variant="contained"
                        >
                            <Iconify icon="eva:message-square-fill"/>
                            Ir para o chat
                        </LoadingButton>


                    </div>
                )
                : (<div className='ButtonActionAtendimento'>


                    <LoadingButton
                        onClick={irparachat}
                        variant="contained"
                        loading={desabiltarbotaochat}
                    >
                        <Iconify icon="eva:message-square-fill"/>
                        Atender Heroi
                    </LoadingButton>


                    <Button variant='contained' color='error' disabled={desabiltarbotaoaoatender}
                            onClick={handleabrirconfirmacao}>
                        <Iconify icon="eva:close-circle-outline"/>
                        Não posso atender
                    </Button>
                </div>)}




            { sala.Status === 'EmAtendimento' ? ('')
                : (
            <div className='TemporizadorCardNotificacaoSaladeEspera'>
                
                    <Typography className='TempoTemporizadorCardNotificacaoSaladeEspera'>{(`0${  Math.floor((time / 60000) % 60)}`).slice(-2)}:{(`0${  Math.floor((time / 1000) % 60)}`).slice(-2)}</Typography>

                    <Slider
                        aria-label="Tempo"
                        defaultValue={100}
                        value={progress}
                        getAriaValueText={valuetext}
                        color="secondary"
                    />
                
            </div>
                )}

        </div>
        <Dialog
            open={openFundosAcolhimento}
            onClose={handleCloseFundosAcolhimento}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            keepMounted
            className="modalFundosAcolhimento"
        >
            <Grid container direction="row" justifyContent="center" alignItems="center">
                <DialogContent>


                    <Typography
                        variant="h3"
                        sx={{ textAlign: 'center', color: '#212b36', mb: 2,mt:2, lineHeight: '1.2' }}
                    >
                        Tem certeza que não pode atender ?
                    </Typography>

                    <DialogActions>
                        <Button
                            fullWidth
                            onClick={handleCloseFundosAcolhimento}
                            variant="contained"
                            color="primary"
                            sx={{ py: 1.5, px: 2, my: 2.5 }}
                        >
                            <Iconify icon="eva:checkmark-circle-outline" sx={{ mr: 1 }} /> Cancelar
                        </Button>
                        <Button
                            fullWidth
                            onClick={chamarplantao}
                            variant="contained"
                            color="error"
                            sx={{ py: 1.5, px: 2, my: 2.5 }}
                        >
                            <Iconify icon="eva:checkmark-circle-outline" sx={{ mr: 1 }} /> Não posso atender
                        </Button>
                    </DialogActions>
                </DialogContent>
            </Grid>
        </Dialog>
    </>
  );
}
