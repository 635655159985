import {useEffect, useState} from 'react';
import {Outlet, useNavigate, useParams} from 'react-router-dom';
// @mui
import { Box, DialogContent, Typography, Grid, Paper, IconButton, Button } from '@mui/material';
import { styled } from '@mui/material/styles';
// hooks
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import Iconify from 'src/components/iconify';
// components

//

import fotoPerfil from './img/avatar_1.jpg'

import './ChamadasDialogs.css'
import {PATH_DASHBOARD} from "../../../../routes/paths";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

export default function ChamadaEncerradaDialog({fecharmodal,dados}:any) {
    const navigate = useNavigate();
    const recusarchamada = ()=>{
        fecharmodal();
        navigate(PATH_DASHBOARD.chat.view(dados.tokenConversa),{replace:true});
    }



    return(
        <DialogContent className='ChamadaRecusadaDialogCard'>
            <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            >
                <Typography variant='h6' color='white' sx={{textAlign: 'center'}}>Chamada Encerrada</Typography>
                <img src={dados.heroiavatar} className='FotoAtendenteChamadaRecusadaDialog' alt={dados.heroiavatar} />
                <Typography variant='h4' color='white' sx={{textAlign: 'center'}}>{dados.heroi}</Typography>
            </Grid>

            <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            sx={{my: 3}}
            >
                <IconButton color='error' onClick={recusarchamada}>
                    <Iconify sx={{width: '50px', height: '50px'}} icon='eva:message-circle-outline' color='white' />
                </IconButton>
                <Typography variant='subtitle2' color='white'>Acessar o chat</Typography>
                
            </Grid>

            
            
            

            
            
        </DialogContent>
    )
}