import * as React from 'react';
import { m } from 'framer-motion';
import { Helmet } from 'react-helmet-async';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Button, Typography } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { PageNotFoundIllustration } from '../assets/illustrations';
import Conversas from "../sections/@dashboard/Conversas/Conversas";


// ----------------------------------------------------------------------

export default function ConversasPage() {
  return (
    <>
      <Helmet>
        <title> Conversas do Atendente -  Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>
    <Conversas />

    </>
  );
}
