import { Helmet } from 'react-helmet-async';
// sections
import { Chat } from '../sections/@dashboard/chat';
import Atendetentes from "../sections/@dashboard/Atendentes/Atendetentes";
import {PersonalizarPerfil} from "../sections/@dashboard/Perfil";



export default function PersonalizarPerfilPage() {
  return (
    <>
      <Helmet>
        <title>Personalizar Perfil do Atendente -  Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <PersonalizarPerfil  />
    </>
  );
}
