import { useState, useRef } from 'react';
// @mui
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { alpha, useTheme } from '@mui/material/styles';
import {LoadingButton} from "@mui/lab";
import {
  Box,
  Card,
  Paper,
  FormControl,
  OutlinedInput,
  InputAdornment,
  ToggleButtonGroup,
  ToggleButton,
  ButtonGroup,
  InputLabel,
  MenuItem,
  Select,
  Rating,
  Button,
  Typography,
  TextField,
  CardContent,
  SelectChangeEvent,
  Divider,
  IconButton,
} from '@mui/material';
import { CustomAvatar } from 'src/components/custom-avatar';
import { getCoresTemplate } from 'src/utils/coresTemplate';
import Iconify from 'src/components/iconify/Iconify';
import { useAuthContext } from '../../../../auth/useAuthContext';
import ProfilePicture from '../img/profile_picture.png';
import axios from '../../../../utils/axios';
import { useSnackbar } from '../../../../components/snackbar';
import useCopyToClipboard from '../../../../hooks/useCopyToClipboard';

// utils

// components

// ----------------------------------------------------------------------

export default function Contribuicao() {
  const { enqueueSnackbar } = useSnackbar();
  const { copy } = useCopyToClipboard();
  const [valor, setvalor] = useState<any>('10');

  const { user } = useAuthContext();
  const [dadoscodigo, setdadoscodigo] = useState<any>('vazio');
  const handleChange = (event: React.MouseEvent<HTMLElement>, newvalor: string) => {
    setvalor(newvalor);
  };

  const pixcopiaecola = async () => {
    try {
      const response = await axios.post('/areadoatendente/pixcopiaecola', {
        Valor: valor,
      });

      const { dadosresponse } = response.data;
      setdadoscodigo(dadosresponse.codigo)
      enqueueSnackbar(`Pix Gerado com sucesso!`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`${error.message}`, { variant: 'error' });
    }
  };

  const copyTextToClipboard = async () => {


    // if ('clipboard' in navigator) {
    //     return navigator.clipboard.writeText(dadoscodigo);
    // }

    enqueueSnackbar(`Pix Copiado com sucesso!`, {variant: 'success'});
    // return document.execCommand('copy', true, dadoscodigo);



  }




  return (
    <>
      <div className={getCoresTemplate(user?.TipoAtendente).degradeContribua}>
        <Typography className="TituloCardAzulDashInicialContribua">
          Sua contribuição salva vidas
        </Typography>
        <Typography className="SubtituloCardAzulDashInicialContribua">
          Contribua para que mais pessoas possam ser acolhidas gratuitamente. Quando você contribui,
          vidas são salvas.
        </Typography>

        <Typography className="SelecioneoValorContribua">Selecione o valor:</Typography>

        <ToggleButtonGroup
          color="primary"
          value={valor}
          exclusive
          onChange={handleChange}
          aria-label="Valor"
          className="OpcoesValorContribua"
        >
          <ToggleButton className="OpcaoValorContribua" value="10">
            R$10
          </ToggleButton>
          <ToggleButton className="OpcaoValorContribua" value="20">
            R$20
          </ToggleButton>
          <ToggleButton className="OpcaoValorContribua" value="30">
            R$30
          </ToggleButton>
        </ToggleButtonGroup>

        <FormControl fullWidth sx={{ m: 1 }}>
          <TextField
            className="OutroValorInputContribuaTitulo OutroValorInputContribua"
            id="outlined-uncontrolled"
            label="Outro valor"
         //   defaultValue={valor}
            value={valor === null ? '' : valor}
            onChange={(event) => setvalor(event.target.value)}
            fullWidth
          />
        </FormControl>

        <Button
          variant="contained"
          sx={{ py: 1.5 }}
          onClick={pixcopiaecola}
          className="BotaoContribuaPixCopiaeCola"
          fullWidth
        >
          Gerar Pix
        </Button>

        { dadoscodigo === 'vazio' ? ('') :(
            <CopyToClipboard text={dadoscodigo}
                             onCopy={copyTextToClipboard}>
              <LoadingButton
                  fullWidth
                  variant="contained"
                  color='error'
                  sx={{mt:2}}

              >
                Copiar
              </LoadingButton>
            </CopyToClipboard>
        )}


      </div>
    </>
  );
}
