import { Helmet } from 'react-helmet-async';
import { paramCase } from 'change-case';
import { useParams, useNavigate } from 'react-router-dom';
// @mui
import { Container, Grid, Box, Button } from '@mui/material';
import Iconify from 'src/components/iconify';
// routes
import { useEffect } from 'react';
import { PATH_DASHBOARD } from '../../../routes/paths';
// _mock_
import { _userList } from '../../../_mock/arrays';
// components
import { useSettingsContext } from '../../../components/settings';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';

// sections
import UserNewEditForm from '../user/UserNewEditForm';

import './EditarPerfil.css';
import { useAuthContext } from '../../../auth/useAuthContext';
import UserNewEditFormProfissional from '../user/UserNewEditFormProfissional';

// ----------------------------------------------------------------------

export default function EditarPerfil() {
  const { themeStretch } = useSettingsContext();

  const navigate = useNavigate();

  const { name } = useParams();
  const { user, getuser } = useAuthContext();
  const currentUser = user;

  useEffect(() => {
    getuser();
  }, [getuser]);

  return (
    <>
      <Helmet>
        <title> Editar Perfil | Eyhe</title>
      </Helmet>

      <Box sx={{ p: 2.5 }}>
        <Button
          variant="text"
          onClick={() => {
            navigate(PATH_DASHBOARD.dashfirst, { replace: true });
          }}
        >
          {' '}
          <Iconify icon="eva:arrow-ios-back-outline" /> Dashboard{' '}
        </Button>

        <CustomBreadcrumbs
          heading="Editar Perfil"
          links={[
            {
              name: 'Dashboard',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Usuário',
              href: PATH_DASHBOARD.root,
            },
            { name: currentUser?.nome },
          ]}
        />

        {user?.TipoAtendente > 1 ? (
          <UserNewEditFormProfissional isEdit currentUser={currentUser} />
        ) : (
          <UserNewEditForm isEdit currentUser={currentUser} />
        )}
      </Box>
    </>
  );
}
