import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {LoadingButton} from "@mui/lab";
import {
    Accordion,
    AccordionSummary,
    AccordionDetails,
    Typography,
    Grid,
    Button,
    Card,
    Paper,
    styled,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Stack
} from "@mui/material";

import Iconify from "../../../../components/iconify";
import axios from "../../../../utils/axios";
import ChatNavItem from "../../chat/nav/ChatNavItem";
import '../Rotina.css'
import AcoesInteligentes from './AcoesInteligentes';
import {useSnackbar} from "../../../../components/snackbar";

// ----------------------------------------------------------------------

const ExpandMoreIcon = (
    <Iconify icon='eva:arrow-ios-downward-fill'/>
)

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  type Props = {
    diaDaSemana: any;
    slugDiasemana: any;
  };




export default function DiaSemana({ diaDaSemana,slugDiasemana}: Props) {

    const [acoesInteligentes, setAcoesInteligentes] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const [issalvo, setissalvo] = useState(false);
    const handleAcoesInteligentes = () => (
        acoesInteligentes ? setAcoesInteligentes(false) : setAcoesInteligentes(true)
    );

    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    const handleAccordionClick = () => {
        setIsAccordionOpen(!isAccordionOpen);
        setAcoesInteligentes(!isAccordionOpen);
    };










    const [horarios, setHorarios] = useState<any>(
        { OitoHoras: false, 
            NoveHoras: false,
            DezHoras: false,
            OnzeHoras: false,
            DozeHoras: false,
            TrezeHoras: false, 
            QuatorzeHoras: false,
            QuinzeHoras: false,
            DezesseisHoras: false,
            DezesseteHoras: false,
            DezoitoHoras: false, 
            DezenoveHoras: false,
            VinteHoras: false,
            VinteeUmaHoras: false,
            VinteeDuasHoras: false,
          });



    const getHorarios = useCallback(async () => {
        try {
            const response = await axios.post('/areadoatendente/horarios',{
                diaSemana:slugDiasemana
            });

            const {horariodados} = response.data;


            setHorarios(horariodados)

        } catch (error) {
            console.error(error);
        }
    }, [slugDiasemana]);


    const salvarhorarios = useCallback(async () => {
        setissalvo(true);
        try {
            const response = await axios.post('/areadoatendente/salvarhorarios',{
                diaSemana:slugDiasemana,
                dados:horarios
            });

            enqueueSnackbar('Salvo com sucesso!');
            setissalvo(false);

        } catch (error) {
            enqueueSnackbar(`${ error.message}`, { variant: 'error' });
        }
    }, [slugDiasemana,enqueueSnackbar,horarios]);




    useEffect(() => {

        getHorarios();

    }, [getHorarios]);




    const toggleMudarHorario = (event:any) => {
        setHorarios((state: any) => ({ ...state, [event.target.name]: event.target.checked }));
    }

    const [todaManhaSelecionada, setTodaManhaSelecionada] = useState(false);
    const [todaTardeSelecionada, setTodaTardeSelecionada] = useState(false);
    const [todaNoiteSelecionada, setTodaNoiteSelecionada] = useState(false);

    const toggleTodaManha = () => {
        setHorarios((state: any) => ({ ...state,
            OitoHoras: !state.OitoHoras,
            NoveHoras: !state.NoveHoras,
            DezHoras: !state.DezHoras,
            OnzeHoras: !state.OnzeHoras,
            DozeHoras: !state.DozeHoras,
        }));

        setTodaManhaSelecionada((prevTodaManhaSelecionada) => !prevTodaManhaSelecionada);
        
    };

    const toggleTodaTarde = () => {
        setHorarios((state: any) => ({ ...state, 
            TrezeHoras: !state.TrezeHoras,
            QuatorzeHoras: !state.QuatorzeHoras,
            QuinzeHoras: !state.QuinzeHoras,
            DezesseisHoras: !state.DezesseisHoras,
            DezesseteHoras: !state.DezesseteHoras,
        }));

        setTodaTardeSelecionada((prevTodaTardeSelecionada) => !prevTodaTardeSelecionada);
    };

    const toggleTodaNoite = () => {
        setHorarios((state: any) => ({ ...state, 
            DezoitoHoras: !state.DezoitoHoras,
            DezenoveHoras: !state.DezenoveHoras,
            VinteHoras: !state.VinteHoras,
            VinteeUmaHoras: !state.VinteeUmaHoras,
            VinteeDuasHoras: !state.VinteeDuasHoras,
        }));

        setTodaNoiteSelecionada((prevTodaNoiteSelecionada) => !prevTodaNoiteSelecionada);
    };

    const toggleMarcarTudo = () => {
        toggleTodaManha();
        toggleTodaTarde();
        toggleTodaNoite();
    }

    const toggleDesmarcarTudo = () => {
        setHorarios((state: any) => ({ ...state, 
            OitoHoras: false, 
            NoveHoras: false,
            DezHoras: false,
            OnzeHoras: false,
            DozeHoras: false,
            TrezeHoras: false, 
            QuatorzeHoras: false,
            QuinzeHoras: false,
            DezesseisHoras: false,
            DezesseteHoras: false,
            DezoitoHoras: false, 
            DezenoveHoras: false,
            VinteHoras: false,
            VinteeUmaHoras: false,
            VinteeDuasHoras: false,
        }));

        setTodaManhaSelecionada(false)
        setTodaTardeSelecionada(false)
        setTodaNoiteSelecionada(false)
    }

    return (
            <>
            
            <Grid item xs={12} md={8} >
                <Card>
                    <Accordion expanded={isAccordionOpen} sx={{ p: 1 }}>
                        <AccordionSummary
                        expandIcon={ExpandMoreIcon}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                        onClick={handleAccordionClick}
                        >
                        <Typography className='DiaSemanaRotina'>{diaDaSemana}</Typography>
                        <span className='TextoApoioDiaSemanaRotina'>(Selecione os horários disponíveis)</span>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={1}>
                                <Grid item xs={4}>
                                    <Item>
                                        <Typography sx={{textAlign: 'left'}}>Manhã</Typography>

                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox name="OitoHoras" checked={horarios.OitoHoras} onChange={toggleMudarHorario}/>} label="08h às 08h50" />
                                            <FormControlLabel control={<Checkbox name="NoveHoras" checked={horarios.NoveHoras} onChange={toggleMudarHorario}/>} label="09h às 09h50" />
                                            <FormControlLabel control={<Checkbox name="DezHoras" checked={horarios.DezHoras} onChange={toggleMudarHorario}/>} label="10h às 10h50" />
                                            <FormControlLabel control={<Checkbox name="OnzeHoras" checked={horarios.OnzeHoras} onChange={toggleMudarHorario}/>} label="11h às 11h50" />
                                            <FormControlLabel control={<Checkbox name="DozeHoras" checked={horarios.DozeHoras} onChange={toggleMudarHorario}/>} label="12h às 12h50" />
                                        </FormGroup>

                                    </Item>
                                </Grid>
                                <Grid item xs={4}>
                                    <Item>
                                        <Typography sx={{textAlign: 'left'}}>Tarde</Typography>

                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox name="TrezeHoras" checked={horarios.TrezeHoras} onChange={toggleMudarHorario}/>} label="13h às 13h50" />
                                            <FormControlLabel control={<Checkbox name="QuatorzeHoras" checked={horarios.QuatorzeHoras} onChange={toggleMudarHorario}/>} label="14h às 14h50" />
                                            <FormControlLabel control={<Checkbox name="QuinzeHoras" checked={horarios.QuinzeHoras} onChange={toggleMudarHorario}/>} label="15h às 15h50" />
                                            <FormControlLabel control={<Checkbox name="DezesseisHoras" checked={horarios.DezesseisHoras} onChange={toggleMudarHorario}/>} label="16h às 16h50" />
                                            <FormControlLabel control={<Checkbox name="DezesseteHoras" checked={horarios.DezesseteHoras} onChange={toggleMudarHorario}/>} label="17h às 17h50" />
                                        </FormGroup>
                                    </Item>
                                </Grid>
                                <Grid item xs={4}>
                                    <Item>
                                        <Typography sx={{textAlign: 'left'}}>Noite</Typography>

                                        <FormGroup>
                                            <FormControlLabel control={<Checkbox name="DezoitoHoras" checked={horarios.DezoitoHoras} onChange={toggleMudarHorario}/>} label="18h às 18h50" />
                                            <FormControlLabel control={<Checkbox name="DezenoveHoras" checked={horarios.DezenoveHoras} onChange={toggleMudarHorario}/>} label="19h às 19h50" />
                                            <FormControlLabel control={<Checkbox name="VinteHoras" checked={horarios.VinteHoras} onChange={toggleMudarHorario}/>} label="20h às 20h50" />
                                            <FormControlLabel control={<Checkbox name="VinteeUmaHoras" checked={horarios.VinteeUmaHoras} onChange={toggleMudarHorario}/>} label="21h às 21h50" />
                                            <FormControlLabel control={<Checkbox name="VinteeDuasHoras" checked={horarios.VinteeDuasHoras} onChange={toggleMudarHorario}/>} label="22h às 22h50" />
                                        </FormGroup>
                                    </Item>
                                </Grid>
                            </Grid>

                            <Grid
                            container
                            direction="row"
                            justifyContent="flex-end"
                            alignItems="center"
                            >
                                <Button variant='outlined' sx={{ mr: 1 }} onClick={handleAcoesInteligentes}> Ações Inteligentes </Button>
                                <LoadingButton  variant="contained" onClick={salvarhorarios} loading={issalvo}>
                                    Salvar Rotina
                                </LoadingButton>
                            </Grid>


                            
                        </AccordionDetails>
                    </Accordion>
                </Card>
            </Grid>
            <Grid item xs={12} md={4}>  
                <AcoesInteligentes diaDaSemana={diaDaSemana} abrirAcoesInteligentes={acoesInteligentes} selecionarTodaManha={toggleTodaManha} selecionarTodaTarde={toggleTodaTarde} selecionarTodaNoite={toggleTodaNoite} marcarTodosCheckbox={toggleMarcarTudo}  desmarcarTodosCheckbox={toggleDesmarcarTudo} estadoManha={todaManhaSelecionada} estadoTarde={todaTardeSelecionada} estadoNoite={todaNoiteSelecionada} />
            </Grid>


            </>
    );
}
