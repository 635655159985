
import { useState,useEffect ,useRef,useCallback } from 'react';
// @mui

import { alpha, useTheme } from '@mui/material/styles';
import { Box, Card, Paper,FormControl,InputLabel,MenuItem,Select,Rating,Slider ,Button, Typography, CardContent,SelectChangeEvent, Divider, IconButton } from '@mui/material';
import { CustomAvatar } from "src/components/custom-avatar";
import Iconify from 'src/components/iconify/Iconify';
import {useNavigate} from "react-router-dom";
import * as React from "react";
import {LoadingButton} from "@mui/lab";
import ProfilePicture from '../img/profile_picture.png';
import AvaliacaoIndividual from './AvaliacaoIndividual';
import { useSnackbar } from '../../../../components/snackbar';
import axios from '../../../../utils/axios';
import {PATH_DASHBOARD} from "../../../../routes/paths";

import iconRS from '../img/icon-rs.png'


// utils

// components

// ----------------------------------------------------------------------


function valuetext(value: number) {
    return `${value}`;
  }
type Props = {
    plantao: any;
    user: any;
};

export default function PlantaoSolicitadoCard({plantao,user}:Props) {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [plantaoativo, setativo] = useState<any>(true);
    const [desabiltarbotaochat, setdesabiltarbotaochat] = useState(false);


    useEffect(  () => {

        if(sessionStorage.getItem(plantao.tokenConversa)){
            setativo(false);
        }

    },[plantao]);

    const handlecloseplantao = () => {
        sessionStorage.setItem(plantao.tokenConversa,'ativo')
        setativo(false);
    };



    const irparachat = async () => {
        setdesabiltarbotaochat(true);
        try {
            const response = await axios.post('/areadoatendente/atenderplantao',{chamada:plantao.tokenConversa});

            const {chamada} = response.data;
            navigate(PATH_DASHBOARD.chat.view(chamada),{replace:true});
            setdesabiltarbotaochat(false);

        } catch (error) {
            enqueueSnackbar(`${ error.message}`, { variant: 'error' });
            setdesabiltarbotaochat(false);
        }
    }


    return (
    <>

        {plantaoativo && (
            <div className='CardPlantaoNotificacaoCard'>
                <Typography className='Titulo_CardPlantaoNotificacaoCard'>Plantão solicitado</Typography>

                <Typography className='Subtitulo_CardPlantaoNotificacaoCard'>Tem um herói solicitando atendimento de plantão, você consegue atende-lo?</Typography>

                <div className='ItensCardPlantaoNotificacaoCard'>
                    <div className='FotoNomeAssuntoCardSaladeEspera'>
                        <CustomAvatar
                            className="FotoPerfilAtendente"
                            src={plantao.avatar}
                            name={plantao.nome}
                            sx={{cursor: 'pointer', width: 40, height: 40}}
                        />

                        <div className='NomeeAssuntosCardNotificacaoCard'>

                            <span style={{display: 'flex', alignItems: 'center'}}>
                                <Typography className='NotificacaoNomeCardNotificacaoCard' sx={{mr: 1}}>{plantao.nome}</Typography>   
                                {plantao.OrigemCampanha === 'rs' ? (<img src={iconRS} alt="Origem RS" width={20} />) : ('')}
                            </span>

                            <Typography className='NotificacaoAssuntoCardNotificacaoCard'>Ansiedade |
                                Depressão</Typography>
                            <Typography
                                className='NotificacaoAssuntoCardSaladeEspera'>{plantao.tipoconversa}</Typography>
                        </div>
                    </div>


                    {user?.TipoAtendente === 1 ? (
                        <div className='BtnIcon_CardNotificacaoCard'>
                            <Iconify icon="eva:alert-circle-fill"/>
                        </div>
                    ) : (
                        <div className='BtnIcon_CardNotificacaoCardValor'>
                            <Typography className='NotificacaoValorCardNotificacaoCard'>R${plantao.ValorComDesconto}</Typography>
                        </div>
                )}


            </div>

            <div className='BotoesCardPlantaoNotificacaoCard'>
            <Button className='BotaoAtenderPlantao' onClick={irparachat}> Atender </Button>
                <LoadingButton
                    className='BotaoAtenderPlantao'
                    onClick={irparachat}
                    loading={desabiltarbotaochat}
                >
                    Atender
                </LoadingButton>




                    <Button className='BotaoNaoPossoAtenderPlantao' onClick={handlecloseplantao}> Não posso atender </Button>




                </div>


            </div>
        )}


    </>
  );
}