import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import {useNavigate} from "react-router-dom";
import {useEffect , useState} from "react";
import {
  Badge,
  Stack,
  Typography,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
  Button, 
  FormControl, 
  MenuItem, 
  FormHelperText,
    Chip,
  Menu,
  Pagination,
  PaginationItem
} from '@mui/material';

import Select, { SelectChangeEvent } from '@mui/material/Select';
import Iconify from 'src/components/iconify/Iconify';
// @types
import {useSnackbar} from "notistack";
import axios from "../../../../utils/axios";
import { IChatConversation } from '../../../../@types/chat';
// components
import { CustomAvatar, CustomAvatarGroup } from '../../../../components/custom-avatar';
import BadgeStatus from '../../../../components/badge-status';

import {PATH_DASHBOARD} from "../../../../routes/paths";

import './ChatAtendimentoItem.css';
import DeleteModal from "../../../../components/modal/DeleteModal";

import iconRS from "../../Atendimento/icon-rs.png";
import DialogResponderMensagem from '../../Atendimento/componentesAtendimento/DialogResponderMensagem';

// ----------------------------------------------------------------------
type Props = {
    openNav: any;
    lido: boolean;
    conversa: any;

    tokenConversa: any;
    atualizar: any;
    onSelect: VoidFunction;
};
export default function ChatAtendimentoItem({ lido = true, openNav  , onSelect,atualizar, conversa,tokenConversa}: Props) {

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { enqueueSnackbar } = useSnackbar();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      setAnchorEl(event.currentTarget);
    };


    console.log(conversa);

    

    const handleClose = () => {
      setAnchorEl(null);
    };

    const [modaldelete, setModaldelete] = useState(false);

    const [enviarMensagem, setEnviarMensagem] = useState(false);

    const handleOpenResponderNovaMensagem = () => {
      setEnviarMensagem(true);
    }

    const handleCloseResponderNovaMensagem = () => {
      setEnviarMensagem(false);
    }

    const excluir = async () => {

        const id = conversa.id;
        try {

            const response = await axios.post(`/areadoatendente/atendimentos/deletar`, {
                id
            });

            enqueueSnackbar(`${response.data.message}`, {variant: 'success'});
            atualizar();
            handleClose();
            setModaldelete(false);

        } catch (error) {
            enqueueSnackbar(`${error.message}`, {variant: 'error'});
            setModaldelete(false);
            handleClose();
        }
    };




    const deletar = () => {
        setModaldelete(true)
    };
    
    
    const navigate = useNavigate();
    useEffect(() => {
        if(tokenConversa === ''){
            navigate(PATH_DASHBOARD.chat.view(conversa.tokenConversa));
        }

    }, [navigate,tokenConversa,conversa]);


    

    console.log(openNav);
    const isSelected = conversa.tokenConversa === tokenConversa ;
  return (
      
      
      <>
          <DeleteModal opendeletemodal={modaldelete} setOpen={setModaldelete} deletar={excluir} />

      
    <ListItemButton
      disableGutters
      
      sx={{
        py: 1.5,
        px: 2.5,
          ...(isSelected && {
              bgcolor: 'action.selected',
          })
      }}
      className='ItemChatAtendimento'
    >
    { openNav === true ?(
        <>
        <div className='FotoNomeDataeFuncaoChatAtendimentoItem'>
      <ListItemAvatar onClick={onSelect}>
          <CustomAvatar
            key={conversa.nomeHeroi}
            alt={conversa.nomeHeroi}
            src={conversa.avatarHeroi}
            name={conversa.nomeHeroi}
            sx={{ width: 48, height: 48 }}
          />

      </ListItemAvatar>

        
      
          


            <div className='NomeDataeFuncaoChatAtendimentoItem'>
                <span style={{display: 'flex', alignItems: 'center'}}>
                  <Typography className='NomeChatAtendimentoItem' onClick={onSelect} sx={{mr: 1}}>{conversa.nomeHeroi}</Typography>
                  {conversa.OrigemCampanha === 'rs' ? (<img src={iconRS} alt="Origem RS" width={20} />) : ('')}
                </span>
                <Typography className='DataChatAtendimentoItem' onClick={onSelect}>{conversa.dateUltimaMessagem2}</Typography>
                <Chip label={conversa.Status} variant="outlined" color={ conversa.Status === 'Finalizado' ? ('success') : ('error') } />
            </div>
        </div>

          <Stack alignItems="flex-end" className='ButtonsItemChatAtendimento' sx={{ ml: 2, height: 44 }}>
            <Button variant='contained' onClick={onSelect}> Chat </Button>

                    <Button
                        className="BotaoMais_AtendentesHistoricoAcolhimentoRecorrente"
                        aria-controls={open ? 'basic-menu' : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? 'true' : undefined}
                        onClick={handleClick}
                    >
                        <Iconify icon="eva:more-vertical-fill" />
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                        'aria-labelledby': 'basic-button',
                        }}
                    >
                        <MenuItem onClick={handleOpenResponderNovaMensagem}>Enviar mensagem</MenuItem>
                        <MenuItem onClick={deletar} color='error'>Excluir</MenuItem>
                    </Menu>

          </Stack>
        </>
        ):''
        }
    </ListItemButton>

    <DialogResponderMensagem tipo='nova' idconversa={conversa.tokenConversa} responderMensagem={enviarMensagem} handleCloseResponderNovaMensagem={handleCloseResponderNovaMensagem} />

      </>

      
  );


// ----------------------------------------------------------------------

};
