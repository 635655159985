import { useNavigate } from 'react-router-dom';
// @mui
import { List, 
  SxProps, 
  Stack,
  Pagination} from '@mui/material';


// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useResponsive from '../../../../hooks/useResponsive';
// @types
import { IChatConversationsState } from '../../../../@types/chat';
// components
import { SkeletonConversationItem } from '../../../../components/skeleton';
//
import ChatNavItem from './ChatNavItem';
import ChatAtendimentoItem from './ChatAtendimentoItem';
import './ChatAtendimentoLista.css';


// ----------------------------------------------------------------------


type Props = {
    openNav: any;
    conversas: any;
    onCloseNav: any;
    tokenConversa: any;
    setpage: any;
    atualizar: any;
    page: any;
};



export default function ChatAtendimentoLista({openNav,conversas, onCloseNav,tokenConversa,setpage,atualizar,page}:Props) {
  const navigate = useNavigate();

    const handleSelectConversation = (conversationId: string) => {

        navigate(PATH_DASHBOARD.chat.view(conversationId));
    };


    const isDesktop = useResponsive('up', 'md');



  const loading = false;

    const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
        console.log(value);
        setpage(value);
    };
  return (
    <List >
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {conversas.data.map((conversa:any,index:any) => (
            <ChatAtendimentoItem openNav={openNav}  conversa={conversa}  key={index} tokenConversa={tokenConversa} atualizar={atualizar}
                         onSelect={() => {
                             if (!isDesktop) {
                                 onCloseNav();
                             }
                             handleSelectConversation(conversa.tokenConversa);
                         }}
                         lido={false}/>
        ))}

    <Stack className='paginationChatAtendimentoLista' spacing={2}>
      <Pagination count={conversas.last_page} page={page} variant="outlined" shape="rounded" onChange={handleChange} />
    </Stack>

    </List>
    
  );
}
