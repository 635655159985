import { Helmet } from 'react-helmet-async';
// sections
import { Insights } from '../sections/@dashboard/Insights';

export default function InsightsPage() {
  return (
    <>
      <Helmet>
        <title>Insight Atendente - Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <Insights />
    </>
  );
}
