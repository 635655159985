import { formatDistanceToNowStrict } from 'date-fns';
// @mui
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";
import {
  Badge,
  Stack,
  Typography,
  ListItemText,
  ListItemButton,
  ListItemAvatar,
} from '@mui/material';
// @types
import { IChatConversation } from '../../../../@types/chat';
// components
import { CustomAvatar, CustomAvatarGroup } from '../../../../components/custom-avatar';
import BadgeStatus from '../../../../components/badge-status';

import {PATH_DASHBOARD} from "../../../../routes/paths";


// ----------------------------------------------------------------------
type Props = {
    openNav: any;
    lido: boolean;
    conversa: any;

    tokenConversa: any;
    onSelect: VoidFunction;
};
export default function ChatNavItem({ lido = true, openNav  , onSelect, conversa,tokenConversa}: Props) {
    const navigate = useNavigate();
    useEffect(() => {
        if(tokenConversa === ''){
            navigate(PATH_DASHBOARD.chat.view(conversa.tokenConversa));
        }

    }, [navigate,tokenConversa,conversa]);



    console.log(openNav);
    const isSelected = conversa.tokenConversa === tokenConversa ;
  return (
    <ListItemButton
      disableGutters
      onClick={onSelect}
      sx={{
        py: 1.5,
        px: 2.5,
          ...(isSelected && {
              bgcolor: 'action.selected',
          })
      }}
    >
      <ListItemAvatar>
          <CustomAvatar
            key={conversa.nomeHeroi}
            alt={conversa.nomeHeroi}
            src={conversa.avatarHeroi}
            name={conversa.nomeHeroi}
            BadgeProps={{
              badgeContent: <BadgeStatus status='online' />,
            }}
            sx={{ width: 48, height: 48 }}
          />

      </ListItemAvatar>

        { openNav === true ?(
        <>
          <ListItemText
            primary={conversa.nomeHeroi}
            primaryTypographyProps={{ noWrap: true, variant: 'subtitle2' }}
            secondary={conversa.ultimamessagem}
            secondaryTypographyProps={{
              noWrap: false,
              variant: lido ? 'subtitle2' : 'body2',
              color: lido ? 'text.primary' : 'text.secondary',
            }}
          />


          <Stack alignItems="flex-end" sx={{ ml: 2, height: 44 }}>
            <Typography
              noWrap
              variant="body2"
              component="span"
              sx={{
                mb: 1.5,
                fontSize: 12,
                color: 'text.disabled',
              }}
            >
                {conversa.dateUltimaMessagem2}
            </Typography>


          </Stack>
        </>
        ):''
        }
    </ListItemButton>
  );


// ----------------------------------------------------------------------

};
