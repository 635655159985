
import { useState, useRef } from 'react';
// @mui

import { alpha, useTheme, styled  } from '@mui/material/styles';
import { Grid,Box,Avatar,Card, Paper,FormControl,OutlinedInput,InputAdornment,ToggleButtonGroup,ToggleButton, ButtonGroup, InputLabel,MenuItem,Select,Rating,Button, Typography,TextField, CardContent,SelectChangeEvent, Divider, IconButton } from '@mui/material';
import { CustomAvatar } from "src/components/custom-avatar";
import Iconify from 'src/components/iconify';

import './CardNovaMensagem.css';

import { LoadingButton } from '@mui/lab';
import {useSnackbar} from "notistack";
import * as React from "react";
import imgNovaMensagem from '../img/cardNovaMensagem.png'
import imgPerfil from '../img/imgPerfil.jpg';
import DialogResponderMensagem from "../../Atendimento/componentesAtendimento/DialogResponderMensagem";
import axios from "../../../../utils/axios";
// utils

// components

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


type Props = {
    idconversa: any;
    dados: any;
    atualizarmenssagens: any;
};

export default function CardNovaMensagem({ dados, idconversa,atualizarmenssagens} :Props) {
    const [mensagemLida, setMensagemLida] = useState(false);
    const [responderMensagem, setResponderMensagem] = useState(false);
    const [isloadinglido, setisloadinglido] = useState(false);
    const { enqueueSnackbar } = useSnackbar();

    const handleCloseResponderNovaMensagem = () => {
        setResponderMensagem(false);
        atualizarmenssagens();
    }
    const handleOpenResponderNovaMensagem = () => {
        setResponderMensagem(true);
    }

    const handleCloseNovaMensagem = () => {
        setResponderMensagem(false);
    }

    const marcarlida = async () => {
        setisloadinglido(true);
        try {

            const response = await axios.post(`/areadoatendente/mensssagensexternamarcarlida`, {
                idconversa
            });

            enqueueSnackbar(`Menssagem lida com sucesso.`, {variant: 'success'});
            setisloadinglido(false);
            handleCloseNovaMensagem();
            atualizarmenssagens();

        } catch (error) {
            enqueueSnackbar(`${error.message}`, {variant: 'error'});
            handleCloseNovaMensagem();
            setisloadinglido(false);
        }
    };




  return (
    <>
        <DialogResponderMensagem idconversa={idconversa} tipo='resposta' responderMensagem={responderMensagem} handleCloseResponderNovaMensagem={handleCloseResponderNovaMensagem} />
        <div className='CardDivulgaNovaMensagemDashboard'>

            <Typography className='TituloCardAzulDashInicialDivulgaNovaMensagem' sx={{mb:2}}>Você possui uma nova mensagem!</Typography>


            <Grid container spacing={2}>
                <Grid item md={1} xs={2}>
                    <Item sx={{background: 'transparent'}}>
                        <CustomAvatar
                            className="FotoPerfilAtendente"
                            src={dados.avatar}
                            alt={dados.nome}
                            name={dados.nome}
                            />
                    </Item>
                </Grid>
                <Grid item md={11} xs={10}>
                    <Item sx={{background: 'transparent'}}>
                    <div style={{marginLeft: '12px'}}>
                        <Typography variant='h5' color="white" sx={{textAlign: 'left'}}>{dados.nome}</Typography>
                        <Typography variant='subtitle2' color="white" sx={{textAlign: 'left'}}>{dados.menssagem}</Typography>
                    </div>
                    </Item>
                </Grid>
                
            </Grid>


           


            
            <Grid container sx={{mt: 1}}>
                <Grid item md={6} xs={12}>
                    <Item sx={{background: 'transparent'}}>
                        <LoadingButton  loading={ isloadinglido} fullWidth variant='contained' sx={{background: '#fff', color: "#3366ff"}} onClick={ marcarlida }> <Iconify icon='eva:eye-outline' sx={{mr: 1}} /> Lido</LoadingButton>
                    </Item>
                </Grid>

                <Grid item md={6} xs={12}>
                    <Item sx={{background: 'transparent'}}>
                        <Button fullWidth variant='contained' color='warning' onClick={handleOpenResponderNovaMensagem}> <Iconify icon='eva:message-circle-outline' sx={{mr: 1}} /> Responder</Button>
                    </Item>
                </Grid>
                
                
            </Grid>


        </div>

    </>
  );
}
