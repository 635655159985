import  { useEffect, useState, useCallback, useMemo} from 'react';
import useResponsive from 'src/hooks/useResponsive';
import {Divider, styled, Tabs,Radio ,RadioGroup ,FormControlLabel ,FormLabel , Tab, IconButton,Button,Link,TextField,InputLabel, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Iconify from 'src/components/iconify';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from "react-currency-input-field";
import { LoadingButton } from '@mui/lab';
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
import { PATH_DASHBOARD } from '../../../../routes/paths';

import './CardPacote.css';
import axios from "../../../../utils/axios";
import {useSnackbar} from "../../../../components/snackbar";

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }


  type Props = {
    detalhes?: any;
    isEditing?: any;
    pacotes?: any;
    atualizar: any;
      tiposconversadados: any;
  };

export default function CardPacote({detalhes, isEditing,pacotes,atualizar,tiposconversadados} :Props) {

const isDesktop = useResponsive('up', 'lg');
const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
const [valorChat, setValorChat] = useState('');

const handleChangeValor = (value:any) => {
  setValorChat(value);
};

const [quantidadeSessoes, setQuantidadeSessoes] = useState('');

const handleChangeQtdSessoes = (event:any) => {
  setQuantidadeSessoes(event.target.value);
};

const [nomePacote, setNomePacote] = useState('');

const handleChangeNomePacotes = (event:any) => {
  setNomePacote(event.target.value);
};

const [selectedTipoConversa, setSelectedTipoConversa] = useState('');


  const handleChangeTipoConversa = (event:any) => {
    setSelectedTipoConversa(event.target.value);
  };
  

  const TipoConversaSelecionada = tiposconversadados.find((option: { id: string; }) => option.id === selectedTipoConversa)?.Nome || '';



  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = () => {
    // Defina isSubmitting como true para iniciar o estado de carregamento
    setIsSubmitting(true);

    // Simule uma operação assíncrona (por exemplo, uma chamada de API)
    setTimeout(() => {
      // Defina isSubmitting como false para indicar que o carregamento foi concluído
      setIsSubmitting(false);
        getfinaceiro();
    }, 2000); // Tempo de simulação de 2 segundos
  };
    const [id, setid] = useState('');

    const getfinaceiro= useCallback(async () => {
        try {
            const response = await axios.post(`/areadoatendente/editarpacote`,{
                nomePacote,
                selectedTipoConversa,
                valorChat,
                quantidadeSessoes,
                id
            });

            atualizar();

        } catch (error) {
            enqueueSnackbar(`${ error.message}`, { variant: 'error' });
        }
    },[atualizar, enqueueSnackbar, id, nomePacote, quantidadeSessoes, selectedTipoConversa, valorChat]);
  
  
  

    useEffect(() => {

        setNomePacote(detalhes.Nome);
        setValorChat(detalhes.Valor);
        setQuantidadeSessoes(detalhes.quantidade);
        setSelectedTipoConversa(detalhes.IDTipoConversa);
        setid(detalhes.id);

    }, [detalhes, pacotes.id]);




    return (
            <>
                <Box sx={{ width: '100%'}}>
                  
                    <Grid container>
                        <Grid item xs={12} md={6}>
                            <Item sx={{textAlign: 'left'}}>
                                
                                <Typography variant='h3'> {nomePacote} </Typography>
                                <Typography variant='subtitle2'>Defina aqui os campos que irão compôr os detalhes deste pacote.</Typography>

                                <FormControl fullWidth sx={{mt: 3, mb: 1.5}}>
                                    <TextField
                                        id="nome-pacote"
                                        placeholder='Nome do pacote'
                                        type="text"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={nomePacote}
                                        onChange={handleChangeNomePacotes}
                                    />
                                </FormControl>

                                <FormControl fullWidth sx={{ mb: 1.5 }}>
                                    
                                    <InputLabel htmlFor="valor-sessao"> {valorChat ? ('') : ('Valor do pacote')} </InputLabel>
                                    <CurrencyInput
                                        type="text"
                                        className='inputmasktelefone'
                                        name="valorChat"
                                        value={valorChat}
                                        decimalsLimit={2}
                                        decimalSeparator=","
                                        groupSeparator="."
                                        placeholder=""
                                        onValueChange={(value) => handleChangeValor(value)}
                                        prefix="R$ "
                                        />
                                </FormControl>

                                <FormControl fullWidth sx={{mb: 1.5}}>
                                    <TextField
                                        id="outlined-number"
                                        placeholder='Quantidade de sessões'
                                        type="number"
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        value={quantidadeSessoes}
                                        onChange={handleChangeQtdSessoes}
                                    />
                                </FormControl>


                                <FormControl fullWidth>
                                <TextField
                                    id="outlined-select-currency"
                                    select
                                    label="Tipo de Conversa"
                                    value={selectedTipoConversa}
                                    onChange={handleChangeTipoConversa}
                                >
                                    {tiposconversadados.map((option:any) => (
                                    <MenuItem key={option.id} value={option.id}>
                                        {option.Nome}
                                    </MenuItem>
                                    ))}
                                </TextField>
                                </FormControl>


                            </Item>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Item>
                                <Box sx={{ flexGrow: 1, width: '100%', textAlign: 'left' }} className='boxResumoPacote'>
                                    <Typography variant='subtitle2'>Resumo</Typography>
                                    <Typography variant='h3'> {nomePacote} </Typography>

                                    <Divider sx={{my: 1.5}}/>

                                    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{mb: 1.5}}>
                                        <Typography variant='subtitle2'> Valor total do pacote: </Typography>
                                        <Typography variant='h4'> {valorChat ? ('R$') : ('')} {valorChat} </Typography>
                                    </Grid>

                                    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{mb: 1.5}}>
                                        <Typography variant='subtitle2'> Quantidade de sessões: </Typography>
                                        <Typography variant='h4'> {quantidadeSessoes} </Typography>
                                    </Grid>

                                    <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{mb: 1.5}}>
                                        <Typography variant='subtitle2'> Tipo de atendimento: </Typography>
                                        <Typography variant='h4'> {TipoConversaSelecionada} </Typography>
                                    </Grid>
                                
                                    {valorChat && quantidadeSessoes && TipoConversaSelecionada ? (
                                        <LoadingButton type="submit"
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        sx={{py: 1.5}}
                                        loading={isSubmitting}
                                        onClick={handleSubmit}>Pronto!</LoadingButton>
                                    ) : ('')}
                                    
                                </Box>
                            </Item>
                        </Grid>
                    </Grid>
                </Box>
            


            </>
    );
}
