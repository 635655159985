
import { useState,useEffect ,useRef,useCallback } from 'react';
// @mui

import { alpha, useTheme } from '@mui/material/styles';
import { Box, Card, Paper,FormControl,InputLabel,MenuItem,Select,Rating,Slider ,Button, Typography, CardContent,SelectChangeEvent, Divider, IconButton } from '@mui/material';
import { CustomAvatar } from "src/components/custom-avatar";
import Iconify from 'src/components/iconify/Iconify';
import {useNavigate} from "react-router-dom";
import * as React from "react";
import ProfilePicture from '../img/profile_picture.png';
import AvaliacaoIndividual from './AvaliacaoIndividual';
import { useSnackbar } from '../../../../components/snackbar';
import axios from '../../../../utils/axios';
import {PATH_DASHBOARD} from "../../../../routes/paths";

import iconRS from '../img/icon-rs.png'


// utils

// components

// ----------------------------------------------------------------------


function valuetext(value: number) {
    return `${value}`;
  }
type Props = {
    plantao: any;
    fecharaudio: any;
};

export default function PlantaoJaatendido({plantao,fecharaudio}:Props) {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [plantaoativo, setativo] = useState<any>(true);



    useEffect(  () => {

        if(sessionStorage.getItem(plantao.tokenConversa)){
            setativo(false);
            fecharaudio();
        }

    },[fecharaudio, plantao]);


    const handlecloseplantao = () => {
        sessionStorage.setItem(plantao.tokenConversa,'ativo');
        setativo(false);
        fecharaudio();
    };





    return (
    <>

        {plantaoativo && (
            <div className='CardPlantaoNotificacaoCard'>
                <Typography className='Titulo_CardPlantaoNotificacaoCard'>Atendimento Eyhex</Typography>

                <Typography className='Subtitulo_CardPlantaoNotificacaoCard'>Essa solicitação já foi atendida por outro
                    Anjo. Agradecemos sua disponibilidade.</Typography>

                <div className='ItensCardPlantaoNotificacaoCard'>
                    <div className='FotoNomeAssuntoCardSaladeEspera'>
                        <CustomAvatar
                            className="FotoPerfilAtendente"
                            src={plantao.avatar}
                            name={plantao.nome}
                            sx={{cursor: 'pointer', width: 40, height: 40}}
                        />

                        <div className='NomeeAssuntosCardNotificacaoCard'>
                            <span style={{display: 'flex', alignItems: 'center'}}>
                                <Typography className='NotificacaoNomeCardNotificacaoCard' sx={{mr: 1}}>{plantao.nome}</Typography>   
                                {plantao.OrigemCampanha === 'rs' ? (<img src={iconRS} alt="Origem RS" width={20} />) : ('')}
                            </span>

                            <Typography className='NotificacaoAssuntoCardNotificacaoCard'>Ansiedade |
                                Depressão</Typography>
                        </div>
                    </div>

                    <div className='BtnIcon_CardNotificacaoCard'>
                        <Iconify icon="eva:alert-circle-fill"/>
                    </div>
                </div>

                <div className='BotoesCardPlantaoNotificacaoCard'>
                    <Button className='BotaoNaoPossoAtenderPlantao' onClick={handlecloseplantao}> Ok </Button>
                </div>


            </div>
        )}


    </>
    );
}