import { Helmet } from 'react-helmet-async';
// sections
import { Chat } from '../sections/@dashboard/chat';
import Atendetentes from "../sections/@dashboard/Atendentes/Atendetentes";
import {SalaEspera} from "../sections/@dashboard/SalaEspera";

type Props = {
    socket: any;

};

export default function PageSalaEspera({socket}:Props) {
  return (
    <>
      <Helmet>
        <title>Sala de Espera | Eyhe </title>
      </Helmet>

      <SalaEspera socket={socket} />
    </>
  );
}
