import { Navigate, useRoutes } from 'react-router-dom';
// auth
import { io } from 'socket.io-client';
import RotinaPage from 'src/pages/RotinaPage';
import AgendamentosPage from 'src/pages/AgendamentosPage';
import EsqueciSenhaPage from 'src/pages/EsqueciSenhaPage';
import InsightsPage from 'src/pages/InsightsPage';
import PacotesPage from 'src/pages/PacotesPage';

import RegrasPage from 'src/pages/RegrasPage';
import CuponsPage from 'src/pages/CuponsPage';
import AuthGuard from '../auth/AuthGuard';
import GuestGuard from '../auth/GuestGuard';
// layouts
import CompactLayout from '../layouts/compact';
import DashboardLayout from '../layouts/dashboard';

// config
import { PATH_AFTER_LOGIN } from '../config-global';

//
import {
  Page404,
  PageTwo,
  PageSix,
  PageFour,
  PageFive,
  LoginPage,
  PageThree,
  ChatPage,
} from './elements';
import ChatVideo from '../pages/ChatVideo';
import Conversas from '../sections/@dashboard/Conversas/Conversas';
import ConversasPage from '../pages/ConversasPage';
import ChatAudio from '../pages/ChatAudio';
import AtendetesPage from '../pages/AtendetesPage';
import PageDashfirst from '../pages/PageDashfirst';
import PageSalaEspera from '../pages/PageSalaEspera';
import PageDashfirstListagem from '../pages/PageDashfirstListagem';
import PersonalizarPerfilPage from '../pages/PersonalizarPerfilPage';
import AvaliacoesPage from '../pages/AvaliacoesPage';
import AtendimentoPage from '../pages/AtendimentoPage';
import FinanceiroPage from '../pages/FinanceiroPage';
import CadastrePage from '../pages/CadastrePage';
import EditarPerfilPage from '../pages/EditarPerfilPage';

import VerPerfilPage from '../pages/VerPerfilPage';
import DetalhesAgendamentoPage from '../pages/DetalhesAgendamentoPage';
import ChamadaAudioPage from "../pages/ChamadaAudioPage";
import ChamadaVideoPage from "../pages/ChamadaVideoPage";

// ---------------------------------------------------------------------- https://socket.eyhe.com.br:3001/chat
const socket = io('https://socket.eyhe.com.br:3001/chat');

// client-side
socket.on('connect', () => {
  console.log(socket.id); // x8WIv7-mJelg7on_ALbx
}); // client-side

document.addEventListener('visibilitychange', () => {
  const Agent = navigator.userAgent;
  if (Agent.includes('iPhone') || Agent.includes('iPad') || Agent.includes('iPod')) {
    if (document.hidden) {
      socket.disconnect();
    } else {
      socket.connect();
    }
  }
});

export default function Router() {
  return useRoutes([
    {
      path: '/',
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        // { path: 'cadastro', element: <CadastrePage /> },
        { path: 'esquecisenha', element: <EsqueciSenhaPage /> },
        {
          path: 'login',
          element: (
            <GuestGuard>
              <LoginPage />
            </GuestGuard>
          ),
        },
      ],
    },
    {
      path: '/dashboard',
      element: (
        <AuthGuard>
          <DashboardLayout socket={socket} />
        </AuthGuard>
      ),
      children: [
        { element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
        { path: 'dashfirst', element: <PageDashfirst socket={socket} /> },
        { path: 'listagem/:tipo', element: <PageDashfirstListagem /> },
        { path: 'Conversas', element: <ConversasPage /> },
        { path: 'atendentes', element: <AtendetesPage /> },
        { path: 'avaliacoes', element: <AvaliacoesPage /> },
        { path: 'atendimentos', element: <AtendimentoPage /> },
        { path: 'financeiro', element: <FinanceiroPage /> },
        { path: 'salaespera/:tokenConversa', element: <PageSalaEspera socket={socket} /> },
        { path: 'notifica', element: <PageFour /> },
        { path: 'personalizarperfil', element: <PersonalizarPerfilPage /> },
        { path: 'editarperfil', element: <EditarPerfilPage /> },
        { path: 'verperfil', element: <VerPerfilPage /> },
        { path: 'detalhesagendamento/:id', element: <DetalhesAgendamentoPage /> },
        { path: 'rotina', element: <RotinaPage /> },
        { path: 'regras', element: <RegrasPage /> },
        { path: 'cupons', element: <CuponsPage /> },
        { path: 'agendamentos', element: <AgendamentosPage /> },
        { path: 'insights', element: <InsightsPage /> },
        { path: 'pacotes', element: <PacotesPage /> },
        { path: 'chamadaaudio/:tokenConversa', element: <ChamadaAudioPage socket={socket}/> },
        { path: 'chamadavideo/:tokenConversa', element: <ChamadaVideoPage socket={socket}/> },
        
        {
          path: 'user',
          children: [
            { element: <Navigate to="/dashboard/user/four" replace />, index: true },
            { path: 'four', element: <PageFour /> },
            { path: 'five', element: <PageFive /> },
            { path: 'six', element: <PageSix /> },
          ],
        },

        {
          path: 'chat',
          children: [
            { element: <ChatPage socket={socket} />, index: true },
            { path: ':tokenConversa', element: <ChatPage socket={socket} /> },
          ],
        },
        {
          path: 'video',
          children: [{ path: ':tokenConversa', element: <ChatVideo socket={socket} /> }],
        },
        {
          path: 'audio',
          children: [{ path: ':tokenConversa', element: <ChatAudio socket={socket} /> }],
        },
      ],
    },
    {
      element: <CompactLayout />,
      children: [{ path: '404', element: <Page404 /> }],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
