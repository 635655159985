import * as Yup from 'yup';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createTheme, styled, ThemeProvider } from '@mui/material/styles';
// form
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
// @mui
import Iconify from 'src/components/iconify';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Card,
  Grid,
  Stack,
  Switch,
  Typography,
  TextField,
  Button,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Paper,
} from '@mui/material';
import RHFtelefone from 'src/components/hook-form/RHFtelefone';
import RHFValor from 'src/components/hook-form/RHFValor';
import RHFValorProfissional from 'src/components/hook-form/RHFValorProfissional';
import Drawing from 'src/components/assinatura/assinatura';
import { getCoresTemplate } from 'src/utils/coresTemplate';
// utils
import CurrencyInput from "react-currency-input-field";
import { fData } from '../../../../utils/formatNumber';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// @types
import { IUserAccountGeneral } from '../../../../@types/user';
// assets
import { countries } from '../../../../assets/data';
// components
import Label from '../../../../components/label';
import { CustomFile } from '../../../../components/upload';
import { useSnackbar } from '../../../../components/snackbar';
import FormProvider, {
  RHFSelect,
  RHFSelectBandeiras,
  RHFSwitch,
  RHFTextField,
  RHFUploadAvatar,
} from '../../../../components/hook-form';
import axios from '../../../../utils/axios';
import useCopyToClipboard from '../../../../hooks/useCopyToClipboard';




const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

interface FormValuesProps extends Omit<IUserAccountGeneral, 'avatarUrl'> {
  ValorAtendimento: string | null;
  valorChat: string | null;
}

type Props = {
  isEdit?: boolean;
  currentUser?: any;
};

export default function CardPrecosAtendimentos({ isEdit = true, currentUser }: Props) {
  const { enqueueSnackbar } = useSnackbar();

  const NewUserSchema = Yup.object().shape({

  });

  const defaultValues = useMemo(
    () => ({
        ValorAtendimento: currentUser?.ValorAtendimento || '',
        valorChat: currentUser?.valorChat || '',
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentUser]
  );

  const methods = useForm<FormValuesProps>({
    resolver: yupResolver(NewUserSchema),
    defaultValues,
  });

  const {
    reset,
    watch,
    control,
    setValue,
    handleSubmit,getValues,
    formState: { isSubmitting,isValid },
  } = methods;


  const values = watch();





  const [botaoclicado, setbotaoclicado] = useState(false);
  const [ValorAtendimento, setValorAtendimento] = useState<any>('');
  const [valorChat, setvalorChat] = useState<any>('');




  const onSubmit2 = async () => {
const data = {ValorAtendimento,valorChat}
    setbotaoclicado(true);
    try {
      const response = await axios.post('/areadoatendente/editarperfilvalores', data);
      const { user } = response.data;
      enqueueSnackbar('Editado com sucesso!');
      setbotaoclicado(false);
    } catch (error) {
      enqueueSnackbar(`${error.message}`, { variant: 'error' });
      setbotaoclicado(false);
    }
  };


  const handleAtendimento = async (event: any) => {
    console.log(event.target.value);
    setValorAtendimento(event.target.value);
  };
  const handlevalorChat = async (event: any) => {
    console.log(event.target.value);
    setvalorChat(event.target.value);
  };

  useEffect(() => {
    if ( currentUser) {
      reset(defaultValues)
      setValorAtendimento(currentUser?.ValorAtendimento);
      setvalorChat(currentUser?.valorChat);
    }



  }, [currentUser, defaultValues, reset]);

  
  const corIcone = getCoresTemplate(currentUser?.TipoAtendente).hexPrincipal

  return (
      <Card sx={{ p: 3, my: 3 }}>
        <Typography className="TitlesEditarPerfil">
          Defina o valor dos atendimentos por tipo:
        </Typography>
        <Typography sx={{ mb: 2 }} >
        Ao definir os valores você precisa salvar as alterações.
        </Typography>

        <Grid container spacing={2}>
          
          <Grid item md={6} xs={12}>
            <Item>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 3,
                }}
              >

              

                

              <div>
                <img src={getCoresTemplate(currentUser?.TipoAtendente).iconConversaBasica} alt='ilustração preço' style={{marginBottom: '10px'}} />
              </div>
                

                <Typography variant='h6' sx={{mb: 1}}>Atendimento em texto e áudio - 50min</Typography>
                

                <CurrencyInput
                    type="text"
                    className='inputmasktelefone'
                    name="valorChat"
                    value={valorChat}
                    decimalsLimit={2}
                    decimalSeparator=","
                    groupSeparator="."
                    placeholder="Valor chat"
                    // onChange={handlevalorChat}
                    onValueChange={(value, name) => setvalorChat(value)}
                />

              </Card>
            </Item>
          </Grid>
          <Grid item md={6} xs={12}>
            <Item>
              <Card
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  p: 3,
                }}
              >
                <div>
                  <img src={getCoresTemplate(currentUser?.TipoAtendente).iconConversaMultimidia} alt='ilustração preço' style={{marginBottom: '10px'}} />
                </div>
                
                <Typography variant='h6' sx={{mb: 1}}>Atendimento em texto, áudio e vídeo - 50min</Typography>
                
                <CurrencyInput
                    type="text"
                    className='inputmasktelefone'
                    name='ValorAtendimento'
                    value={ValorAtendimento}
                    placeholder="Valor multimídia"
                    decimalSeparator=","
                    groupSeparator="."
                  //  onChange={handleAtendimento}
                    onValueChange={(value, name) => setValorAtendimento(value)}
                />
              </Card>
            </Item>
          </Grid>
          <Grid item xs={12}>
            <Item sx={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <LoadingButton
                  onClick={onSubmit2}
                // loading={isSubmitting}
                sx={{ py: 1.5, px: 3 }}
                variant="contained"
              >
                <Iconify icon="eva:checkmark-circle-outline" sx={{ mr: 1 }} /> Salvar alterações
              </LoadingButton>
            </Item>
          </Grid>
        </Grid>
      </Card>
  );
}
