
import {useState, useRef, useCallback, useEffect} from 'react';
// @mui

import { alpha, useTheme } from '@mui/material/styles';
import { Box, Card, Paper,FormControl,InputLabel,MenuItem,Select,Rating,Button,Chip, Typography, CardContent,SelectChangeEvent, Divider, IconButton } from '@mui/material';
import Iconify from 'src/components/iconify/Iconify';
import {useNavigate} from "react-router-dom";
import axios from "../../../../utils/axios";
import {useSnackbar} from "../../../../components/snackbar";
import {PATH_DASHBOARD} from "../../../../routes/paths";

// utils

// components

// ----------------------------------------------------------------------



export default function MateriaisdeApoio() {
    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [listarmateriais, setlistarmateriais] = useState<any[]>([]);

    const getmateriais = useCallback(async () => {
        try {
            const response = await axios.post('/areadoatendente/materiais');

            const {materiaiscontanovos} = response.data;

            setlistarmateriais(materiaiscontanovos);


        } catch (error) {
            enqueueSnackbar(`${ error.message}`, { variant: 'error' });
        }
    },[enqueueSnackbar]);

    useEffect(() => {

        getmateriais();

    }, [getmateriais]);



  return (
    <>
        <Typography variant='h6'>Materiais de Apoio</Typography>
        <div className='CardMateriaisApoioDashboard'>
            <div className='HeaderMateriaisApoioDashboard'>
                <div className='IconMateriaisCircle'>
                    <Iconify className='IconMateriais' icon="eva:browser-outline"/>
                </div>
                <Typography>Materiais de Apoio</Typography>
                <Chip label={`Novos +${listarmateriais}`} className='IndicadorNotificacaoMateriaisApoioDashInicial'/>
            </div>

            <Button variant='outlined' fullWidth className='BtnVerMateriais' sx={{py: 1.5}} onClick={ ()=>{ navigate(PATH_DASHBOARD.listagem('material'),{replace:true})}}>Ver materiais</Button>
        </div>
        
    </>
  );
}