import * as React from 'react';

import {
    Divider,
    Tabs,
    Tab,
    IconButton,
    Button,
    TextField,
    InputLabel,
    Slide,
    DialogProps,
    List,
    MenuItem,
    FormControl,
    OutlinedInput,
    Alert,
    InputAdornment,
    Paper,
    Box,
    Grid,
    Badge,
    Avatar,
    Select,
    Stack,
    Rating,
    Tooltip,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogContentText,
    DialogActions,
    MobileStepper ,
    
  } from '@mui/material';
import useResponsive from 'src/hooks/useResponsive';
import { styled, useTheme  } from '@mui/material/styles';


import { TransitionProps } from '@mui/material/transitions';
import Iconify from 'src/components/iconify';
import {LoadingButton} from "@mui/lab";
import {useState} from "react";
import {useSnackbar} from "notistack";
import axios from "../../../../utils/axios";


type Props = {
    responderMensagem: any;
    handleCloseResponderNovaMensagem: any;
    idconversa: any;
    tipo: any;
};


function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="down" ref={ref} {...props} />;
  }

// Em seguida, use React.forwardRef para criar um componente de transição reutilizável
const ForwardedTransitionOrientacoes = React.forwardRef(Transition);






export default function DialogResponderMensagem({responderMensagem, handleCloseResponderNovaMensagem,idconversa,tipo}:Props) {
  
    const theme = useTheme();
    const [isloadingresposder, setisloadingresposder] = useState(false);
    const [menssagem, setmenssagem] = useState<any>('');

  const isDesktop = useResponsive('up', 'lg');
    const { enqueueSnackbar } = useSnackbar();

    const enviarmensssagens = async () => {
        setisloadingresposder(true);
        try {

            const response = await axios.post(`/areadoatendente/enviarmenssagemexternas`, {
                idconversa,
                tipo,
                menssagem
            });

            enqueueSnackbar(`Menssagem enviada com Sucesso.`, {variant: 'success'});
            setisloadingresposder(false);
            handleCloseResponderNovaMensagem();

        } catch (error) {
            enqueueSnackbar(`${error.message}`, {variant: 'error'});
            handleCloseResponderNovaMensagem();
            setisloadingresposder(false);
        }
    };



  return (
    <>
      
      <Dialog
        open={responderMensagem}
        onClose={handleCloseResponderNovaMensagem}
        TransitionComponent={ForwardedTransitionOrientacoes}
      >

      
            <DialogContent sx={{py: 3}}>

                <DialogContentText id="alert-dialog-slide-description">
                    <Typography variant='h4'> {tipo === 'resposta' ? 'Responder ': 'Enviar'} mensagem</Typography>
                    <Typography variant='subtitle2' sx={{mb: 2}}>Escreva abaixo a mensagem que será enviada para o Herói :</Typography>
                </DialogContentText>

                <TextField
                    id="outlined-multiline-static"
                    label="Escreva sua mensagem"
                    multiline
                    fullWidth
                    rows={4}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setmenssagem(event.target.value);
                    }}
                />

                <Grid
                container
                direction="row"
                justifyContent="flex-end"
                alignItems="center"
                sx={{mt: 2}}
                >

                    <Button
                        color='error'
                        variant="contained"
                        onClick={handleCloseResponderNovaMensagem}
                        sx={{ ml: 1 }}

                    >
                        Cancelar
                        <Iconify sx={{ml: 0.5}} icon='eva:close-fill' />
                    </Button>



                    <LoadingButton
                        color="success"
                        variant="contained"
                        sx={{ ml: 1 }}
                        loading={ isloadingresposder}
                        onClick={enviarmensssagens}
                    >
                        Responder
                        <Iconify sx={{ml: 0.5}} icon='eva:navigation-2-outline' />
                    </LoadingButton>
                </Grid>

                
            </DialogContent>
           
      </Dialog>
    </>
  );
}