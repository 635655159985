// @mui
import { Stack, InputAdornment, TextField, MenuItem,Grid, Button, Paper , Box, styled  } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import useResponsive from "src/hooks/useResponsive";
import { ptBR } from '@mui/x-date-pickers/locales';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';


// components
import Iconify from '../../../../../components/iconify';

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));


const INPUT_WIDTH = 160;

type Props = {
  filterName: string;
  isFiltered: boolean;
  filterService: string;
  optionsService: string[];
  filterEndDate: Date | null;
  onResetFilter: VoidFunction;
  filterStartDate: Date | null;
  onFilterName: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterService: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onFilterStartDate: (value: Date | null) => void;
  onFilterEndDate: (value: Date | null) => void;
};



export default function InvoiceTableToolbar({
  filterName,
  isFiltered,
  onFilterName,
  filterEndDate,
  filterService,
  onResetFilter,
  optionsService,
  filterStartDate,
  onFilterService,
  onFilterEndDate,
  onFilterStartDate,
}: Props)
{
  const isDesktop = useResponsive('up', 'lg');
  return (
    <Stack
      spacing={2}
      alignItems="center"
      direction={{
        xs: 'column',
        md: 'row',
      }}
      sx={{ px: 2.5, py: 3 }}
    >
      <TextField
        fullWidth
        select
        label="Status"
        value={filterService}
        onChange={onFilterService}
        SelectProps={{
          MenuProps: {
            PaperProps: {
              sx: { maxHeight: 220 },
            },
          },
        }}
        sx={{
          maxWidth: { md: INPUT_WIDTH },
          textTransform: 'capitalize',
        }}
      >
        {optionsService.map((option) => (
          <MenuItem
            key={option}
            value={option}
            sx={{
              mx: 1,
              borderRadius: 0.75,
              typography: 'body2',
              textTransform: 'capitalize',
            }}
          >
            {option}
          </MenuItem>
        ))}
      </TextField>
      
      <LocalizationProvider dateAdapter={AdapterDayjs}>

      {isDesktop ? (
        <>
        <DatePicker
              label="Início"
              value={filterStartDate}
              onChange={onFilterStartDate}
            />
            <DatePicker
              label="Final"
              value={filterEndDate}
              onChange={onFilterEndDate}
            />
        </>
      ) : (<Grid container>
        <Grid item xs={6}>
          <Item>
            <DatePicker
              label="Início"
              value={filterStartDate}
              onChange={onFilterStartDate}
            />
          </Item>
        </Grid>
        <Grid item xs={6}>
          <Item>
            <DatePicker
              label="Final"
              value={filterEndDate}
              onChange={onFilterEndDate}
            />
          </Item>
        </Grid>
      </Grid>)}
      

      


        
       

      </LocalizationProvider>

      <TextField
        fullWidth
        value={filterName}
        onChange={onFilterName}
        placeholder="Pesquisar por cliente ou fatura..."
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled' }} />
            </InputAdornment>
          ),
        }}
      />

      {isFiltered && (
        <Button
          color="error"
          sx={{ flexShrink: 0 }}
          onClick={onResetFilter}
          startIcon={<Iconify icon="eva:trash-2-outline" />}
        >
          Limpar
        </Button>
      )}
    </Stack>
  );
}
