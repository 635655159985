import React, { useRef, useEffect, useState } from 'react';
import './assinatura.css';
import { Button } from '@mui/material';
import Iconify from 'src/components/iconify';

interface DrawingProps {
  width: string;
  height: string;
  setimagem: any;

}

const Drawing: React.FC<DrawingProps> = ({ width, height, setimagem }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [isDrawing, setIsDrawing] = useState(false);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    const parentElement = canvas?.parentElement;

    if (canvas && context && parentElement) {
      const parsedWidth = width.includes('%') ? (parseInt(width, 10) / 100) * parentElement.clientWidth : parseInt(width, 10);
      const parsedHeight = height.includes('%') ? (parseInt(height, 10) / 100) * parentElement.clientHeight : parseInt(height, 10);

      canvas.width = parsedWidth;
      canvas.height = parsedHeight;

      context.lineCap = 'round';
      context.strokeStyle = 'black';
      context.lineWidth = 2;
    }
  }, [width, height]);

  const startDrawing = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    setIsDrawing(true);
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');
    if (context) {
      const x = event.nativeEvent instanceof MouseEvent ? event.nativeEvent.offsetX : event.nativeEvent.touches[0].clientX - canvas!.offsetLeft;
      const y = event.nativeEvent instanceof MouseEvent ? event.nativeEvent.offsetY : event.nativeEvent.touches[0].clientY - canvas!.offsetTop;

      context.beginPath();
      context.moveTo(x, y);
    }
  };

  const draw = (event: React.MouseEvent<HTMLCanvasElement> | React.TouchEvent<HTMLCanvasElement>) => {
    if (!isDrawing) return;

    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (context) {
      const x = event.nativeEvent instanceof MouseEvent ? event.nativeEvent.offsetX : event.nativeEvent.touches[0].clientX - canvas!.offsetLeft;
      const y = event.nativeEvent instanceof MouseEvent ? event.nativeEvent.offsetY : event.nativeEvent.touches[0].clientY - canvas!.offsetTop;

      context.lineTo(x, y);
      context.stroke();
    }
  };

  const stopDrawing = () => {
    setIsDrawing(false);
    const canvas = canvasRef.current;
    setimagem(canvas?.toDataURL());
  };

  const clearDrawing = async () => {
    const canvas = canvasRef.current;
    const context = canvas?.getContext('2d');

    if (context) {
      context.clearRect(0, 0, canvas?.width ?? 0, canvas?.height ?? 0);
      setimagem(canvas?.toDataURL());
    }
  };
  

  return (
    <div>
      <canvas
        ref={canvasRef}
        onMouseDown={startDrawing}
        onMouseMove={draw}
        onMouseUp={stopDrawing}
        onMouseLeave={stopDrawing}
        onTouchStart={startDrawing}
        onTouchMove={draw}
        onTouchEnd={stopDrawing}
        className='CanvaAssinatura'
      />
      <Button  onClick={clearDrawing} color='primary'> <Iconify icon='eva:repeat-fill' sx={{mr: 1}} /> Limpar Desenho</Button>
    </div>
  );
};

export default Drawing;
