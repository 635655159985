import { Helmet } from 'react-helmet-async';
// sections
import { Chat } from '../sections/@dashboard/chat';
import Atendetentes from "../sections/@dashboard/Atendentes/Atendetentes";
import {Rotina} from "../sections/@dashboard/Rotina";



export default function RotinaPage() {
  return (
    <>
      <Helmet>
        <title>Rotina do Atendente -  Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <Rotina  />
    </>
  );
}
