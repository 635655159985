import * as React from "react";
import {Divider,Tabs, Tab, IconButton,Button,TextField,InputLabel, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import { CustomAvatar } from "src/components/custom-avatar";
import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import FiltrarProfissional from "src/components/filtrar-profissional/FiltrarProfissional";
import FiltrarAssuntos from "src/components/filtrar-assuntos/FiltrarAssuntos";
import AtendenteRecomendado from "src/components/atendentes-recomendados/AtendentesRecomendados";
import HistoricoAcolhimentoPrimeiroAcesso from "src/components/historico-acolhimento/HistoricoAcolhimentoPrimeiroAcesso";
import HistoricoAcolhimentoRecorrente from "src/components/historico-acolhimento-recorrente/HistoricoAcolhimentoRecorrente";

// @mui
import useResponsive from "src/hooks/useResponsive";
import { styled } from '@mui/material/styles';



import _mock from "./componentesDashboardFirst/mock";

import Iconify from "../../../components/iconify";
import axios from "../../../utils/axios";
import {useSettingsContext} from '../../../components/settings';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import './Dashfirst.css';

import Atendente from "../Atendentes/ComponetesAtedentes/atendente";


import CarouselAnimation from "./componentesDashboardFirst/CarouselDashboardFirst";
import {PATH_DASHBOARD} from "../../../routes/paths";
import {useAuthContext} from "../../../auth/useAuthContext";

import AgendamentosMarcados from "./componentesDashboardFirst/AgendamentosMarcados";
import Financeiro from "./componentesDashboardFirst/Financeiro";
import Avaliacoes from "./componentesDashboardFirst/Avaliacoes";
import MateriaisdeApoio from "./componentesDashboardFirst/MateriaisdeApoio";
import Contribuicao from "./componentesDashboardFirst/Contribuicao";
import AgendamentoMarcado from "./componentesDashboardFirst/AgendamentoMarcado";
import {useSnackbar} from "../../../components/snackbar";
import {fDate} from "../../../utils/formatTime";






export default function DashfirstListagemAgendamento() {
    const navigate = useNavigate();
    const { enqueueSnackbar } = useSnackbar();
    const [value, setValue] = useState<Date | null>(new Date());
    const [listaragendamentos, setlistaragendamentos] = useState<any[]>([]);

    const getavalia = useCallback(async () => {
        try {
            const response = await axios.post('/areadoatendente/agendamentos');

            const {agendamentos} = response.data;
            setlistaragendamentos(agendamentos);

        } catch (error) {
            enqueueSnackbar(`${ error.message}`, { variant: 'error' });
        }
    },[enqueueSnackbar]);

    useEffect(() => {

        getavalia();

    }, [getavalia]);

    return (
            <>
                <Button variant="text" onClick={()=>{ navigate(PATH_DASHBOARD.dashfirst,{replace:true})}}> <Iconify icon="eva:arrow-ios-back-outline"/> Meus agendamentos </Button>

                <div className="AgendamentosCard">


                    <Typography className="TituloAgendamentoCard">Agendamentos</Typography>


                    {listaragendamentos.map((listaragenda:any,index:any) => (
                        <>
                            <Typography className="DataDoAtendimentoDashListagem">{listaragenda.data}</Typography>

                            {listaragenda.dados.map((listaragend:any,index2:any) => (
                                <AgendamentoMarcado listaragendamentos={listaragend} key={index2}/>
                            ))}
                        </>
                    ))}


                    
                </div>
            </>
    );
}