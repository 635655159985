// @mui
import {Card, Stack, Typography, Divider, Grid, Paper} from '@mui/material';
// utils
import {styled} from "@mui/material/styles";
import { fNumber } from '../../../../utils/formatNumber';
// @types
import { IUserProfileFollowers } from '../../../../@types/user';
import Iconify from "../../../../components/iconify";

// ----------------------------------------------------------------------
interface Props {
    assuntos: any;
}
const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
export default function ConversasSobre({assuntos}:Props) {
  return (
      <Grid container className='GlobalCardsAvaliacoes'>
          {assuntos.map((post:any) => (
        <Grid item md={3} xs={6}>

          <Item>
            <div className="cardConversasSobre">
              <img src="https://api.eyhe.com.br/img/filtrar-assuntos/abuso-sexual.svg" height="30px" alt="Icone Abuso Sexual" />
              <h3>{post.NomeAssunto}</h3>
            </div>
          </Item>

        </Grid>
          ))}
      </Grid>
  );
}
