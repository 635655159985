import { Helmet } from 'react-helmet-async';
// sections
import ChamadaAudio from "../sections/@dashboard/ChamadaAudio/ChamadaAudio";



export default function ChamadaAudioPage({socket}:any) {
  return (
    <>
      <Helmet>
        <title>Chamada Áudio Herói - Eyhe Maior Comunidade de Suporte Emocional do Brasil</title>
      </Helmet>

      <ChamadaAudio socket={socket}  />
    </>
  );
}
