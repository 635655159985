import {useEffect, useState, useCallback, useMemo} from 'react';
import {Divider, styled, Tabs,Radio ,RadioGroup ,FormControlLabel ,FormLabel , Tab, IconButton,Button,Link,TextField,InputLabel, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import useResponsive from 'src/hooks/useResponsive';
import Iconify from 'src/components/iconify';
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
import { PATH_DASHBOARD } from '../../../../routes/paths';

import './DetalhesPacotes.css';
import CardPacote from './CardPacote';

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

type Props = {
    detalhesSessoes: any;
    isEditing: any;
    setIsEditing: any;
    pacotes: any;
    atualizar: any;
    tiposconversadados: any;
};
export default function DetalhesPacotes({ detalhesSessoes, isEditing, setIsEditing,pacotes,atualizar,tiposconversadados }:Props) {
    const isDesktop = useResponsive('up', 'lg');
    const navigate = useNavigate();

    const handleFinishEditing = () => {
      // Define o estado isEditing como false quando o botão é clicado
      setIsEditing(false);
    };

    console.log(detalhesSessoes);
    const [value, setValue] = useState(0);

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
    
    return (
            <>
          
              <Box sx={{ p: 2.5 }}>
              <Button
              variant="text"
              onClick={handleFinishEditing}
              >
              {' '}
              <Iconify icon="eva:arrow-ios-back-outline" /> Lista de Pacotes{' '}
              </Button>

              <CustomBreadcrumbs
              heading="Detalhes de Pacotes"
              sx={{ px: 2.5 }}
              links={[
                  {
                  name: 'Dashboard',
                  href: PATH_DASHBOARD.dashfirst,
                  },
                  {
                  name: 'Lista de Pacotes',
                  href: PATH_DASHBOARD.pacotes,
                  },
                  {
                    name: 'Detalhes de Pacotes',
                    href: PATH_DASHBOARD.root,
                    },
              ]}
              
              />



          <Box sx={{ 
                  flexGrow: 1,
                  bgcolor: 'background.paper',
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' }, // Use a expressão ternária aqui
                  width: '100%'
              }}
              className='boxTabsPacotes'>


                  <CardPacote key={1} detalhes={detalhesSessoes} pacotes={pacotes} atualizar={atualizar} tiposconversadados={tiposconversadados} isEditing={isEditing} />

              
              

      </Box>

  </Box>     
           
                


            </>
    );
}
