// @mui
import {Card, Stack, Typography, Divider, Grid, Paper} from '@mui/material';
// utils
import {styled} from "@mui/material/styles";
import { fNumber } from '../../../../utils/formatNumber';
// @types
import { IUserProfileFollowers } from '../../../../@types/user';
import Iconify from "../../../../components/iconify";
import {CustomAvatar} from "../../../../components/custom-avatar";
import FotoPerfil from "../background_verperfil.jpg";

// ----------------------------------------------------------------------
interface Props {
  avaliacoes: any;
}
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
export default function Avaliacoesperfil({avaliacoes}:Props) {
  return (
      <Grid container className='GlobalCardsAvaliacoes'>
        <Grid item md={4} xs={12}>
          {avaliacoes.data.map((avalia:any) => (
          <Item>
            <div className="cardAvaliacoesVerPerfil">
              <CustomAvatar
                  className="FotoPerfilAtendente"
                  src={avalia.avatar}
                  alt={avalia.nome}
                  name={avalia.nome}
                  sx={{ cursor: 'pointer', width: 80, height: 80 }}
              />
              <div className='NomeLocaleDepoimento'>
                <h3>{avalia.nome}</h3>
                <div className="LocalDepoimento">
                  <Iconify icon="eva:pin-outline" />
                  <Typography>{avalia.created_at} </Typography>
                </div>
                <Typography>{avalia.texto}</Typography>
              </div>
            </div>
          </Item>
          ))}
        </Grid>

      </Grid>
  );
}
