import { Helmet } from 'react-helmet-async';
// @mui
import { Container, Typography } from '@mui/material';
// components
import {useParams} from "react-router-dom";
import { useSettingsContext } from '../components/settings';
import {Dashfirst} from "../sections/@dashboard/Dashfirst";
import DashfirstListagemMaterial from "../sections/@dashboard/Dashfirst/DashfirstListagemMaterial";
import DashfirstListagemAgendamento from "../sections/@dashboard/Dashfirst/DashfirstListagem";

// ----------------------------------------------------------------------

export default function PageDashfirstListagem() {
  const { themeStretch } = useSettingsContext();
    const {tipo = ''} = useParams();

  return (
    <>
      <Helmet>
        <title> DashBoard - Listagem</title>
      </Helmet>
        {tipo === 'agendamento'? <DashfirstListagemAgendamento/> :  '' }
        {tipo === 'material'? <DashfirstListagemMaterial/> :  '' }

     
    </>
  );
}
