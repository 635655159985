import * as Yup from 'yup';
import {useEffect, useState, useCallback, useMemo} from 'react';
import {Divider, styled, Tabs,Radio ,RadioGroup ,FormControlLabel , Typography,Card, FormLabel , Tab, IconButton,Button,Link,TextField,InputLabel, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip} from "@mui/material";
import { CustomAvatar } from "src/components/custom-avatar";

import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import {useSnackbar} from "notistack";
import { LoadingButton } from '@mui/lab';
import axios from "../../../utils/axios";
import ChatNavItem from "../chat/nav/ChatNavItem";
import ProfilePicture from "./img/pessoa_call.png";
import { CustomFile } from '../../../components/upload';

import {PATH_AUTH, PATH_DASHBOARD} from '../../../routes/paths';

import './Regras.css';

import FormProvider, {RHFCheckbox} from '../../../components/hook-form';
import {fData} from "../../../utils/formatNumber";
import {useAuthContext} from "../../../auth/useAuthContext";
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';


// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

export default function Regras() {
    
    // @ts-ignore
    return (
            <>
            <Box sx={{ p: 2.5 }}>
              <div className="BreadCrumbsListagemMaterial">
                <CustomBreadcrumbs
                heading="Regras"
                links={[
                    {
                    name: 'Dashboard',
                    href: PATH_DASHBOARD.root,
                    },
                    {
                    name: 'Regras',
                    href: PATH_DASHBOARD.regras,
                    }
                ]}
                />
                </div>

              <Grid 
              container 
              direction="row"
              justifyContent="flex-start"
              alignItems="center" 
              spacing={2}>
                <Grid item xs={12} md={3}>
                  <Item>
                    <Card className='CardRegras'>
                    <img width="56" height="56" src="https://img.icons8.com/fluency-systems-regular/96/7635DC/time-is-money.png" alt="time-is-money"/>
                      <Typography sx={{textAlign: 'left', mt: 2}}>Receba em até 15 dias</Typography>

                      <Typography variant='subtitle2' sx={{textAlign: 'left'}}>Os valores das sessões são liberados 15 dias após a sua realização e são enviados diretamente para a sua conta.
                        Rápido, fácil e sem burocracia.</Typography>
                    </Card>
                  </Item>
                </Grid>
                
                <Grid item xs={12} md={3}>
                  <Item>
                    <Card className='CardRegras'>
                      <img width="56" height="56" src="https://img.icons8.com/ios/100/7635DC/pay-date.png" alt="pay-date"/>
                      <Typography sx={{textAlign: 'left', mt: 2}}>Venda parcelado</Typography>

                      <Typography variant='subtitle2' sx={{textAlign: 'left'}}>Todas as sessões podem ser parceladas no cartão de crédito do paciente. Mais comodidade e acesso facilitado para a comunidade.</Typography>
                    </Card>
                  </Item>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Item>
                    <Card className='CardRegras'>
                    <img width="56" height="56" src="https://img.icons8.com/external-nawicon-detailed-outline-nawicon/64/7635DC/external-Tax-money-management-nawicon-detailed-outline-nawicon.png" alt="external-Tax-money-management-nawicon-detailed-outline-nawicon"/>                      
                    <Typography sx={{textAlign: 'left', mt: 2}}>25% de comissão Eyhe</Typography>

                    <Typography variant='subtitle2' sx={{textAlign: 'left'}}>Temos a menor taxa do mercado, valorizamos os profissionais que fazem parte da comunidade e oportunizamos o acesso à saúde mental para quem precisa.</Typography>
                    </Card>
                  </Item>
                </Grid>

                <Grid item xs={12} md={3}>
                  <Item>
                    <Card className='CardRegras'>
                      <img width="56" height="56" src="https://img.icons8.com/external-coronavirus-icons-berkahicon/64/7635DC/external-call-social-distancing-coronavirus-icons-berkahicon-20.png" alt="external-call-social-distancing-coronavirus-icons-berkahicon-20"/>
                      <Typography sx={{textAlign: 'left', mt: 2}}>Consultório Online</Typography>

                      <Typography variant='subtitle2' sx={{textAlign: 'left'}}>Todos os recursos necessários para atuação profissional da psicóloga(o) estão disponíveis no Eyhe. Atendimento multimídia, controle de rotina, agendamento de sessões, prontuário, atestado e recibo para pacientes. Uma estrutura digital completa e acesso à pacientes de todo o Brasil.</Typography>
                    </Card>
                  </Item>
                </Grid>


                

              </Grid>

              </Box>
            


            </>
    );
}
