import {useState, useEffect, useCallback} from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
    Box,Alert,
    Stack,
    Drawer,
    IconButton,
    Button,
    IconButtonProps,
    Typography,
    CircularProgress,
    Tooltip,
    Divider,
    List,
    MenuItem, Select
} from '@mui/material';

import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
// hooks
import useResponsive from '../../../hooks/useResponsive';

// utils
import axios from '../../../utils/axios';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../routes/paths';
// @types
import { IChatContact, IChatConversationsState } from '../../../@types/chat';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
//
import ChatNavList from '../chat/nav/ChatNavList';
import ChatNavSearch from '../chat/nav/ChatNavSearch';
import ChatNavAccount from '../chat/nav/ChatNavAccount';
import ChatNavSearchResults from '../chat/nav/ChatNavSearchResults';
import MenuPopover from "../../../components/menu-popover";
import BadgeStatus, {BadgeStatusValue} from "../../../components/badge-status";
import {useAuthContext} from "../../../auth/useAuthContext";
import {useSnackbar} from "../../../components/snackbar";
import BuscarAtendimento from '../chat/nav/BuscarAtendimento';
import ChatAtendimentoLista from '../chat/nav/ChatAtendimentoLista';
import './Atendimento.css';
import ModalAvaliacao from "../../../components/modalAvalicao/ModalAvaliacao";

// ----------------------------------------------------------------------

const StyledToggleButton = styled((props) => (
    <IconButton disableRipple {...props} />
))<IconButtonProps>(({ theme }) => ({
    left: 0,
    zIndex: 9,
    width: 32,
    height: 32,
    position: 'absolute',
    top: theme.spacing(13),
    borderRadius: `0 12px 12px 0`,
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
    boxShadow: theme.customShadows.primary,
    '&:hover': {
        backgroundColor: theme.palette.primary.darker,
    },
}));

// ----------------------------------------------------------------------

const NAV_WIDTH = 320;

const NAV_COLLAPSE_WIDTH = 96;



export default function Atendimento() {
    const { enqueueSnackbar } = useSnackbar();

    const [conversas, setConvesas] = useState<any>({data:[]});
    const [page, setpage] = useState<any>(1);
    const [loading, setloading] = useState<any>(true);
    const [seachstate, setSeach] = useState('');

    const getmessage = useCallback(async () => {
        setloading(true);
        try {
            const response = await axios.post(`/areadoatendente/atendimentos?page=${page}`,{
                query: seachstate
            });

            const {atendimentos} = response.data;
            setConvesas(atendimentos);
            setloading(false);
        } catch (error) {
            console.error(error);
        }
    },[page,seachstate]);




    useEffect(() => {

        getmessage();

    }, [getmessage]);


    const theme = useTheme();

    const STATUS = ['online', 'invisible', 'away'] as const;

    const navigate = useNavigate();

    const isDesktop = useResponsive('up', 'md');

    const [openNav, setOpenNav] = useState(true);

    const { user, logout } = useAuthContext();
    const [searchContacts, setSearchContacts] = useState('');

    const isCollapse = isDesktop && !openNav;

    const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);
    const [status, setStatus] = useState<BadgeStatusValue>('online');
    const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
        setOpenPopover(event.currentTarget);
    };

    const handleClosePopover = () => {
        setOpenPopover(null);
    };



    useEffect(() => {
        if (!isDesktop) {
            handleCloseNav();
        } else {
            handleOpenNav();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDesktop]);

    const handleToggleNav = () => {
        setOpenNav(!openNav);
    };

    const handleOpenNav = () => {
        setOpenNav(true);
    };

    const handleCloseNav = () => {
        setOpenNav(false);
    };
    const handleLogout = async () => {
        try {
            logout();
            navigate(PATH_AUTH.login, { replace: true });
            handleClosePopover();
        } catch (error) {
            console.error(error);
            enqueueSnackbar('Erro ao sair', { variant: 'error' });
        }
    };


    // const seach = async () => {
    //     setloading(true);
    //     try {
    //
    //
    //         setSearchContacts(seachstate);
    //
    //         const response = await axios.post(`/areadoatendente/atendimentos?page=${page}`, {
    //             query: seachstate
    //         });
    //         console.log(response.data.atendimentos);
    //         setConvesas(response.data.atendimentos);
    //         setloading(false);
    //
    //     } catch (error) {
    //         console.error(error);
    //     }
    // };

    const handleChangeSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        setSeach(value);

    };

    const handleSelectContact = (result: any) => {
        setSearchContacts('');
        navigate(PATH_DASHBOARD.chat.view(result.idconvesa));
    };

    const renderContent = (
        <>



            <Box sx={{ p: 2.5 }}>

            <Button variant="text" onClick={()=>{ navigate(PATH_DASHBOARD.dashfirst,{replace:true})}}> <Iconify icon="eva:arrow-ios-back-outline"/> Dashboard </Button>
            
            <div className="BreadCrumbsListagemMaterial">
            <CustomBreadcrumbs
            heading="Seus atendimentos"
            links={[
                {
                name: 'Dashboard',
                href: PATH_DASHBOARD.root,
                },
                {
                name: 'Seus Atendimentos',
                href: PATH_DASHBOARD.atendimentos,
                }
            ]}
            />
            </div>
            {conversas.total === 0 ? (
                <Alert variant="outlined" severity="info">
                    Você ainda não fez atendimentos
                </Alert>
            ) : (
                <>
                <BuscarAtendimento
                value={seachstate}
                onChange={handleChangeSearch}
                onClickAway={() => setSearchContacts('')}
                />


                    {loading === false ? (
                    <ChatAtendimentoLista openNav
                                conversas={conversas}
                                onCloseNav={handleCloseNav}
                                setpage={setpage}
                                atualizar={getmessage}
                                tokenConversa='5454554545'
                                page={page}
                    />
                    ):(<div className="divprogress"><CircularProgress  className="progress" /></div>)}

                </>
            ) }
            
                

            </Box>
            

        </>
    );

    return (
        <>
            {renderContent}
        </>
    )
}
