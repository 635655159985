import { Helmet } from 'react-helmet-async';
import { Cupons } from 'src/sections/@dashboard/Cupons';
// sections

export default function CuponsPage() {
  return (
    <>
      <Helmet>
        <title> Cupons do Atendente -  Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <Cupons/>
    </>
  );
}
