import * as React from 'react';
import {
  Divider,
  Tabs,
  Tab,
  IconButton,
  Button,
  TextField,
  InputLabel,
  Slide,
  DialogProps,
  List,
  MenuItem,
  FormControl,
  OutlinedInput,
  Alert,
  InputAdornment,
  Paper,
  Box,
  Grid,
  Badge,
  Avatar,
  Select,
  Stack,
  Rating,
  Tooltip,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import { CustomAvatar } from 'src/components/custom-avatar';
import { useEffect, useState, useCallback, useRef } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import FiltrarProfissional from 'src/components/filtrar-profissional/FiltrarProfissional';
import FiltrarAssuntos from 'src/components/filtrar-assuntos/FiltrarAssuntos';
import AtendenteRecomendado from 'src/components/atendentes-recomendados/AtendentesRecomendados';
import HistoricoAcolhimentoPrimeiroAcesso from 'src/components/historico-acolhimento/HistoricoAcolhimentoPrimeiroAcesso';
import HistoricoAcolhimentoRecorrente from 'src/components/historico-acolhimento-recorrente/HistoricoAcolhimentoRecorrente';

// @mui
import { getCoresTemplate } from 'src/utils/coresTemplate';
import useResponsive from 'src/hooks/useResponsive';
import { styled } from '@mui/material/styles';
import _mock from './componentesDashboardFirst/mock';

import Iconify from '../../../components/iconify';
import axios from '../../../utils/axios';
import { useSettingsContext } from '../../../components/settings';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import './Dashfirst.css';

import '../../../layouts/dashboard/CoresProfissionais.css';

import Atendente from '../Atendentes/ComponetesAtedentes/atendente';

import CarouselAnimation from './componentesDashboardFirst/CarouselDashboardFirst';
import { PATH_DASHBOARD } from '../../../routes/paths';
import { useAuthContext } from '../../../auth/useAuthContext';

import AgendamentosMarcados from './componentesDashboardFirst/AgendamentosMarcados';
import Financeiro from './componentesDashboardFirst/Financeiro';
import Avaliacoes from './componentesDashboardFirst/Avaliacoes';
import MateriaisdeApoio from './componentesDashboardFirst/MateriaisdeApoio';
import Contribuicao from './componentesDashboardFirst/Contribuicao';
import SaladeEsperaCard from './componentesDashboardFirst/SaladeEsperaCard';
import LembretesCard from './componentesDashboardFirst/LembretesNotificacaoCard';
import PlantaoSolicitadoCard from './componentesDashboardFirst/PlantaoNotificacao';
import CustomDay from './componentesDashboardFirst/CalendarioAgendamento';
import WeeklyCalendar from '../Agendamentos/componenteAgendamento/calendario';
import CardAgendamento from '../Agendamentos/componenteAgendamento/cardAgendamento';

import sound from './beep.mp3';

import fundoAcolhimento from './img/fundo-de-acolhimento.svg';
import PlantaoJaatendido from "./componentesDashboardFirst/PlantaoJaatendido";
import DialogBoasVindas from './componentesDashboardFirst/DialogBemVindo';
import CardResumoDash from './componentesDashboardFirst/CardResumoDash';
import CardNovaMensagem from './componentesDashboardFirst/CardNovaMensagem';
import DialogResponderMensagem from '../Atendimento/componentesAtendimento/DialogResponderMensagem';


const audio = new Audio(sound);
audio.muted = true;

// routes

// ----------------------------------------------------------------------

// Defina a função Transition como uma função nomeada
function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="down" ref={ref} {...props} />;
}

// Em seguida, use React.forwardRef para criar um componente de transição reutilizável
const ForwardedTransition = React.forwardRef(Transition);

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

type Props = {
  socket: any;
};

export default function Dashfirst({ socket }: Props) {
  const [openFundosAcolhimento, setOpenFundosAcolhimento] = useState(false);

 

  // Verifica se o valor 'modalVisto' existe no localStorage
  useEffect(() => {
    const modalVisto = localStorage.getItem('modalVisto');

    console.log(modalVisto);

    if (modalVisto === 'false') {
      setOpenFundosAcolhimento(true); // Abre o Dialog se 'modalVisto' não existir no localStorage
    }
  }, []);




  const handleClickOpenFundosAcolhimento = () => {
    setOpenFundosAcolhimento(true);
  };

  const handleCloseFundosAcolhimento = () => {
    setOpenFundosAcolhimento(false);
    localStorage.setItem('modalVisto', 'true');
  };

  const [mensagemLida, setMensagemLida] = useState(false);
  const [responderMensagem, setResponderMensagem] = useState(false);

  const lerMensagem = () => {
    setMensagemLida(true)
  }

  const handleOpenResponderNovaMensagem = () => {
    setResponderMensagem(true);
  }

  const handleCloseResponderNovaMensagem = () => {
    setResponderMensagem(false);
  }

  const [modalOpen, setModalOpen] = useState(false);

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const [abrirDialogBoasVindas, setabrirDialogBoasVindas] = useState(true);

  const fecharDialogBoasVindas = () => {
    setabrirDialogBoasVindas(false);
  };

  const [scroll, setScroll] = React.useState<DialogProps['scroll']>('paper');
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState<DialogProps['maxWidth']>('sm');

  const [recorrencia, setRecorrencia] = useState(false);

  const { themeLayout } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const navigate = useNavigate();
  const [valueAssunto, setValueAssunto] = useState(1);
  const handleChange2 = (event: React.SyntheticEvent, newValue: number) => {
    setValueAssunto(newValue);
  };

  const { user,getuser } = useAuthContext();




  useEffect(() => {

    getuser();

  }, [getuser]);








  function currencyFormat(num: any) {
    if (num == null) {
      return `R$0,00`;
    }
    num = parseFloat(num.replace(',', '.'));

    return `R$${num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`;
  }

  const [dadossaladeespera, setdadossaladeespera] = useState<any>({
    salaespera: [],
    lembretes: [],
    plantao: [],
  });




  const getsaladeespera = useCallback(async () => {
    try {
      const response = await axios.post('/areadoatendente/saladeespera');
      setdadossaladeespera(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    // @ts-ignore
    getsaladeespera();
  }, [getsaladeespera]);

  const linkelement = useRef<any>();

  const [dadosmensssagen, setdadosmensssagens] = useState<any>([]);

  const getmenssagensexternas = useCallback(async () => {
    try {
      const response = await axios.post('/areadoatendente/getmenssagensexternas');
      setdadosmensssagens(response.data);
    } catch (error) {
      console.error(error);
    }
  }, []);



  useEffect(() => {
    // @ts-ignore
    getmenssagensexternas();
  }, [getmenssagensexternas]);









  useEffect(() => {
    if (
      dadossaladeespera.salaespera.length > 0 ||
      dadossaladeespera.lembretes.length > 0 ||
      dadossaladeespera.plantao.length > 0
    ) {
      // @ts-ignore
      document.getElementById('backgroundMusic').play().catch((error) => {
          document.addEventListener(
            'mousemove',
            () => {
              // @ts-ignore
              document.getElementById('backgroundMusic').play();
            },
            { once: true }
          );
        });
    }
  }, [dadossaladeespera]);

  useEffect(() => {
    let timer: any;

    socket.on('salaespera', (dados: any) => {
      if (dados.tipo === 'NotificaAtendente') {
        getsaladeespera();
      }
      if (dados.tipo === 'Menssagensexterna') {
        getmenssagensexternas();
      }
    });
  }, [socket, getsaladeespera,getmenssagensexternas]);

  useEffect(() => {
    let timer: any;

    socket.on('plantao', (dados: any) => {
      getsaladeespera();
    });
  }, [socket, getsaladeespera]);


  const myRef = useRef<any>();
  const fecharaudio = () => {
    myRef.current.pause();
  };

  const pageantedentes = () => {
    navigate(PATH_DASHBOARD.atendentes);
  };

  const pageAgendamentos = () => {
    navigate(PATH_DASHBOARD.agendamentos);
  };

  const pageEditarPerfil = () => {
    navigate(PATH_DASHBOARD.editarperfil);
  };

  const [AgendamentoStatus, setAgendamentoStatus] = useState('Agendado');

  return (
    <>
      {user?.TipoAtendente > 1 ? (
        ''
      ) : (
        <Alert
          severity="success"
          onClick={handleClickOpenFundosAcolhimento}
          sx={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',

          }}
        >
          <b>
            Todos os atendimentos que você realizar no Eyhe 5.0 serão remunerados.{' '}
            <u> Clique aqui para saber mais.</u>
          </b>{' '}
        </Alert>
      )}
      {user?.StatusGeral === 'Ativo' ? (
          ''
      ) : (
      <Alert
          severity="error"
          sx={{
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            mt:2
          }}
      >
        <b>
          Seu perfil está inativo, entre em contato com o suporte Eyhe
        </b>
      </Alert>
      )}
      <div className="DashboardFirst">
        <Typography className="BemVindoTituloDashFirst">Bem-vindo, {user?.nome}</Typography>

        {isDesktop ? (
          <div className="CapaAtendenteDashFirstDesktop">
            <div
              className={getCoresTemplate(user?.TipoAtendente).capaAtendenteDashFirstDesk}  >
              <div className="FotoeNomeAtendenteDashFirstDesktop">
                <CustomAvatar
                  className="FotoPerfilAtendente"
                  src={user?.avatar}
                  alt={user?.nome}
                  name={user?.nome}
                  sx={{
                    cursor: 'pointer',
                    width: 100,
                    height: 100,
                    border: '2px solid #fff',
                    marginBottom: '-25px',
                  }}
                />

                <Typography className="NomeCardTopoPrincipalDashFirstDesktop" sx={{ mb: 1 }}>
                  {user?.nome}
                </Typography>
              </div>
            </div>
          </div>
        ) : (
          <div className="CardTopoPrincipalDashFirst">
            <div className={getCoresTemplate(user?.TipoAtendente).imagemBackgroundPerfil} >
              <CustomAvatar
                className="FotoPerfilAtendente"
                src={user?.avatar}
                alt={user?.nome}
                name={user?.nome}
                sx={{ cursor: 'pointer', width: 40, height: 40 }}
              />

              <Typography className="NomeCardTopoPrincipalDashFirst">{user?.nome}</Typography>
            </div>

            <div className="BotoesCardTopoPrincipalDashFirst">
              <div className="btnAdicionarSaldoCardTopoPrincipalDashFirst">
                <Typography variant="h3">{user?.Atendimentos}</Typography>
                <Typography>Atendimentos</Typography>
              </div>

              <div className="btnAdicionarSaldoCardTopoPrincipalDashFirst">
                <Typography variant="h3">{currencyFormat(user?.SaldoAtual)}</Typography>
                <Typography>Saldo Atual</Typography>
              </div>
            </div>
          </div>
        )}

        <Box sx={{ flexGrow: 1 }}>
          
          <Grid container spacing={2} sx={{ mt: isDesktop ? 2 : 0 }}>
            <Grid item xs={12} md={4} sx={{ mt: isDesktop ? 3 : 0 }}>
              <div>




                {dadosmensssagen.map((sala: any, index: any) => (
                       <CardNovaMensagem dados={sala} key={index} idconversa={sala.id} atualizarmenssagens={getmenssagensexternas} />
                ))}






                    


                {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}

                {/* eslint-disable-next-line jsx-a11y/media-has-caption */}
                <audio  ref={myRef} id="backgroundMusic">
                  <source src={sound} type="audio/mpeg" />
                  Your browser does not support the audio element.
                </audio>
                {/* <SaladeEsperaCard sala={{nome:'assasas',tipoconversa:'nome'}} key={1} socket={socket} /> */}
                {dadossaladeespera.salaespera.map((sala: any, index: any) => (
                  <SaladeEsperaCard sala={sala} key={index} socket={socket} />
                ))}

                {dadossaladeespera.lembretes.map((lembrete: any, index: any) => (
                  <LembretesCard lembrete={lembrete} key={index} />
                ))}


                {dadossaladeespera.plantao.map((plantao: any, index: any) => (
                    <>
                        {plantao.Status === 'EmAtendimento' ? (<PlantaoJaatendido fecharaudio={fecharaudio} plantao={plantao} key={index} />) : (<PlantaoSolicitadoCard user={user} plantao={plantao} key={index} />)}
                    </>
                ))}

                {/* <PlantaoSolicitadoCard user={user} plantao={{nome:'Héroi Eyhe',tipoconversa:'Atendimento em Texto e audio',ValorComDesconto:'20.00'}}  /> */}
                  <CardResumoDash />

                

                


                <div className="SobreMimCardTopoPrincipalDashFirstDesktop">
                  <Typography className="TituloSobreMimCardTopoPrincipalDashFirstDesktop">
                    Sobre Mim
                  </Typography>
                  <Typography className="DescricaoSobreMimCardTopoPrincipalDashFirstDesktop">
                    {user?.biografia}
                  </Typography>

                  {user?.cidade ? (
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      className="ItemsSobreMimCardTopoPrincipalDashFirstDesktop"
                    >
                      <Iconify icon="eva:pin-fill" sx={{ mr: 1 }} />
                      <Typography variant="subtitle2">{user?.cidade}</Typography>
                    </Grid>
                  ) : (
                    ''
                  )}

                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className="ItemsSobreMimCardTopoPrincipalDashFirstDesktop"
                  >
                    <Iconify icon="eva:email-fill" sx={{ mr: 1 }} />
                    <Typography variant="subtitle2">{user?.email}</Typography>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    className="ItemsSobreMimCardTopoPrincipalDashFirstDesktop"
                  >
                    <Iconify icon="eva:phone-fill" sx={{ mr: 1 }} />
                    <Typography variant="subtitle2">{user?.NumeroWhatsApp}</Typography>
                  </Grid>

                  {user?.biografia ? (
                    <Grid
                      container
                      direction="row"
                      justifyContent="flex-start"
                      alignItems="center"
                      className="ItemsSobreMimCardTopoPrincipalDashFirstDesktop"
                    >
                      <Iconify icon="eva:bulb-outline" sx={{ mr: 1 }} />
                      <Typography variant="subtitle2">{user?.biografia}</Typography>
                    </Grid>
                  ) : (
                    ''
                  )}

                  <Button
                    onClick={pageEditarPerfil}
                    variant="outlined"
                    color="primary"
                    sx={{ py: 1.5, px: 3, my: 2.5 }}
                  >
                    <Iconify sx={{ mr: 1 }} icon="eva:settings-2-outline" />
                    Editar Perfil
                  </Button>
                </div>
              </div>
              {/* 
                                { AgendamentoStatus === "Agendado" ? (
                                    <div className="MeusAgendamentosDashInicial">
                                        <Typography className="TituloMeusAgendamentosDashInicial"> Meus Agendamentos </Typography>
                                        <div className="CardMeusAgendamentosDashInicial">
                                            
                                        <Grid
                                        container
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        >
                                            <Item><Typography variant="subtitle2">Agendamentos</Typography></Item>
                                            <Item><IconButton onClick={pageAgendamentos}><Iconify icon='eva:edit-outline' /></IconButton></Item>
                                        </Grid>

                                        <WeeklyCalendar/>

                                        <CardAgendamento/>

                                        <Button variant='contained' onClick={pageAgendamentos} fullWidth>Ver todos agendamentos</Button>


                                        </div>

                                        
                                        

                                        
                                
                                    </div> */}
                                
                                    <div className="MeusAgendamentosDashInicial">
                                        <Typography className="TituloMeusAgendamentosDashInicial"> Meus Agendamentos </Typography>
                                        <div className="CardMeusAgendamentosDashInicial">
                                            <Button className="ButtonCardMeusAgendamentosDashInicial" onClick={pageAgendamentos}> <Iconify className="IconButtonCardMeusAgendamentosDashInicial" sx={{mr: 1}} icon="eva:calendar-outline"/> Ver agendamentos </Button>
                                        </div>
                                    </div> 
                                

              

              {/* <AgendamentosMarcados  /> */}

               {!isDesktop ?(<Financeiro />) : ('')} 

              <Avaliacoes />  

              <MateriaisdeApoio />
            {!isDesktop ? 
            (<>
              <Contribuicao />
              <div className="CarouselDashboardInicial">
                <CarouselAnimation />
              </div>
            </>) : 
            (
              <> 
              <div className="CarouselDashboardInicial">
                <CarouselAnimation />
              </div>
              </>
            )}
              
            </Grid>

            <Grid item xs={12} md={8} sx={{mt: 3}}>
              {isDesktop ? (
                <>
                 <Financeiro />
                 <Contribuicao />
                </>
              
              ) : ('')}
            </Grid>
          </Grid>
        </Box>
      </div>

      <DialogBoasVindas openDialogBoasVindas={abrirDialogBoasVindas} handleCloseDialogBoasVindas={fecharDialogBoasVindas} />

      <Dialog
        open={openFundosAcolhimento}
        onClose={handleCloseFundosAcolhimento}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        TransitionComponent={ForwardedTransition}
        keepMounted
        scroll={scroll}
        fullWidth={fullWidth}
        maxWidth={maxWidth}
        className="modalFundosAcolhimento"
      >
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <DialogContent sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
            <img src={fundoAcolhimento} width={350} alt="Fundos de acolhimentos para atendimentos gratuitos" />

            <Typography
              variant="h3"
              sx={{ textAlign: 'center', color: '#212b36', mb: 2, lineHeight: '1.2' }}
            >
              Olha essa novidade. Agora, todos os atendimentos no Eyhe 5.0 serão remunerados.
            </Typography>
            <Typography variant="body1" sx={{ textAlign: 'left', color: '#212b36' }}>
              Cada conversa bem sucedida, você receberá um valor mesmo que para o herói o
              atendimento seja grátis. Uma forma de retribuir sua dedicação e seu tempo no Eyhe.
              <br />
              <br />
              Esta novidade é possível com o apoio de pessoas e empresas que contribuem com o{' '}
              <b>Fundo de Acolhimento Eyhe.</b> Todo valor das contribuições é revertido para os
              anjos.
              <br />
              <br />É a fase inicial, e agora cada atendimento terá o valor de <b>R$2,00.</b>
              <br />
              À medida que novas contribuições chegarem, o valor pode aumentar.
              <br />
              <br />
              Contamos com você para fortalecer a comunidade Eyhe.
              <br />
              <b>#VocêSeImporta?</b>
            </Typography>
            <DialogActions>
              <Button
                fullWidth
                onClick={handleCloseFundosAcolhimento}
                variant="contained"
                color="success"
                sx={{ py: 1.5, px: 2, my: 2.5 }}
              >
                <Iconify icon="eva:checkmark-circle-outline" sx={{ mr: 1 }} /> Ok! Entendido.
              </Button>
            </DialogActions>
          </DialogContent>
        </Grid>
      </Dialog>
    </>
  );
}
