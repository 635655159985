import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Chip } from '@mui/material';
// routes
import Iconify from 'src/components/iconify';
import { PATH_AUTH } from '../../../routes/paths';
// auth
import { useAuthContext } from '../../../auth/useAuthContext';
// components
import { CustomAvatar } from '../../../components/custom-avatar';
import { useSnackbar } from '../../../components/snackbar';
import MenuPopover from '../../../components/menu-popover';
import { IconButtonAnimate } from '../../../components/animate';
import ToggleStatus from './componenteHeader/toggleStatus';
import axios from '../../../utils/axios';
import DialogMudarStatus from './componenteHeader/ModalStatus';

// ----------------------------------------------------------------------

const OPTIONS = [
  {
    icon: (
      <img
        src="https://heroi.eyhe.com.br/assets/icons/navbar/ic_dashboard.svg"
        className="iconMenuPopover"
        alt="Dashboard"
      />
    ),
    label: ' Dashboard',
    linkTo: '/dashboard/dashfirst',
  },
  {
    icon: (
      <img
        src="https://heroi.eyhe.com.br/assets/icons/navbar/ic_menu_item.svg"
        className="iconMenuPopover"
        alt="Rotina"
      />
    ),
    label: 'Rotina',
    linkTo: '/dashboard/rotina',
  },
  {
    icon: (
      <img
        src="https://heroi.eyhe.com.br/assets/icons/navbar/ic_chat.svg"
        className="iconMenuPopover"
        alt="Atendimentos"
      />
    ),
    label: 'Atendimentos',
    linkTo: '/dashboard/atendimentos',
  },
  {
    icon: (
      <img
        src="https://heroi.eyhe.com.br/assets/icons/navbar/ic_calendar.svg"
        className="iconMenuPopover"
        alt="Agendamentos"
      />
    ),
    label: 'Agendamentos',
    linkTo: '/dashboard/agendamentos',
  },
  {
    icon: (
      <img
        src="https://heroi.eyhe.com.br/assets/icons/navbar/ic_banking.svg"
        className="iconMenuPopover"
        alt="Financeiro"
      />
    ),
    label: 'Financeiro',
    linkTo: '/dashboard/financeiro',
  },
  {
    icon: (
      <img
        src="https://heroi.eyhe.com.br/assets/icons/navbar/ic_label.svg"
        className="iconMenuPopover"
        alt="Avaliações"
      />
    ),
    label: 'Avaliações',
    linkTo: '/dashboard/avaliacoes',
  },
  {
    icon: (
      <img
        src="https://heroi.eyhe.com.br/assets/icons/navbar/ic_user.svg"
        className="iconMenuPopover"
        alt="Meu perfil"
      />
    ),
    label: 'Editar Perfil',
    linkTo: '/dashboard/editarperfil',
  },
  {
    icon: (
      <img
        src="https://atendente.eyhe.com.br/assets/icons/navbar/ic_tour.svg"
        className="iconMenuPopover"
        alt="Insights"
      />
    ),
    label: 'Insights',
    linkTo: '/dashboard/insights',
  },
  {
    icon: (
      <img
        src="https://atendente.eyhe.com.br/assets/icons/navbar/ic_ecommerce.svg"
        className="iconMenuPopover"
        alt="Pacotes"
      />
    ),
    label: 'Pacotes',
    linkTo: '/dashboard/pacotes',
  }
];

const regrasProfissional = {
  icon: (
    <img
      src="https://heroi.eyhe.com.br/assets/icons/navbar/ic_file.svg"
      className="iconMenuPopover"
      alt="Regras"
    />
  ),
  label: 'Regras',
  linkTo: '/dashboard/regras',
};

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [status, setStatus] = useState('online');

  const mudarOffline = () => {
    setStatus('offine');
  };

  const mudarOnline = () => {
    setStatus('online');
  };

  const mudaStatus = () => {
    if (status === 'online') {
      mudarOffline();
    } else {
      mudarOnline();
    }
    console.log(status);
  };

  const navigate = useNavigate();

  const { user, logout } = useAuthContext();

  const { enqueueSnackbar } = useSnackbar();

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  const handleLogout = async () => {
    try {
      const response = await axios.post('/areadoatendente/mudarstatus', { status: 'Offline' });
      logout();

      navigate(PATH_AUTH.login, { replace: true });
      handleClosePopover();
    } catch (error) {
      console.error(error);
      enqueueSnackbar('Erro ao sair', { variant: 'error' });
    }
  };

  const handleClickItem = (path: string) => {
    handleClosePopover();
    navigate(path);
  };

  if (user?.TipoAtendente > 1 && !OPTIONS.find((item) => item.label === regrasProfissional.label)) {
    OPTIONS.push(regrasProfissional);
  }

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpenPopover}
        sx={{
          p: 0,
          ...(openPopover && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <CustomAvatar src={user?.avatar} alt={user?.nome} name={user?.nome} />
      </IconButtonAnimate>

      <MenuPopover open={openPopover} onClose={handleClosePopover} sx={{ width: 200, p: 0 }}>
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user?.nome}
          </Typography>

          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <ToggleStatus />

          {OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleClickItem(option.linkTo)}>
              {option.icon}
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Sair
        </MenuItem>
      </MenuPopover>
    </>
  );
}
