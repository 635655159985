import * as React from 'react';
import {CssBaseline, Box, Container, Typography, Tooltip, Button } from "@mui/material";
import {useEffect, useState, useCallback, useRef} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';


import "./saladeaudio.css";
// @mui
import {styled} from '@mui/material/styles';
import {useSettingsContext} from '../../../../components/settings';
// eslint-disable-next-line import/no-cycle


import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../../routes/paths';
import axios from "../../../../utils/axios";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";
// eslint-disable-next-line import/no-cycle
import HeaderSaladeAudio from "./HeaderSaladeAudio";
import RodapeSaladeAudio from "./RodapeSaladeAudio";
import {CustomAvatar} from "../../../../components/custom-avatar";
import BadgeStatus, {BadgeStatusValue} from "../../../../components/badge-status";



// ----------------------------------------------------------------------

type props = {
    Backaudio: any;
};

export default function EncerraSalaAudio({Backaudio}:props) {



    const {tokenConversa = ''} = useParams();
    const [usera,setuser] = useState<any>({});
    const navigate = useNavigate();
    const [status, setStatus] = useState<BadgeStatusValue>('online');

    useEffect(  () => {
        const fetchRepos2 = async () => {
            const response = await axios.get(`/areadoatendente/atendente/${tokenConversa}`);

            const {atendente} = response.data;


            setuser(atendente)
        }
        fetchRepos2();

    },[tokenConversa]);

    const UrlVoltar = () =>{
        console.log('teste encerrar');
        Backaudio('Ativo');
    }
    const Encerrar = () =>{
        sessionStorage.setItem('paginaAnterior', 'encerrasalaaudio');
        navigate(PATH_DASHBOARD.chat.view(tokenConversa));
    }


    return (
        <>
            <HeaderSaladeAudio UrlVoltar={UrlVoltar}/>
            <CssBaseline/>
            <Container maxWidth="xl" className='GlobalChamadaEncerrada'>
                <Box sx={{bgcolor: '#fff', height: '100vh', width: '100vw'}}>

                    
                    

                    <div className='containerInterno'>

                        <div className='InfosChamada'>                    
                            <Typography className='NomeUserEncerraAudio'>{usera?.nome}</Typography>
                        </div>

                        <CustomAvatar
                            src={usera?.avatar}
                            alt={usera?.nome}
                            name={usera?.nome}
                            BadgeProps={{
                                badgeContent: <BadgeStatus status={status} />,
                            }}
                            sx={{ cursor: 'pointer', width: 120, height: 120 }}
                        />
                        
                        <div className='InfosChamadaEncerrada'>                    
                            <Typography className='DesejaEncerrarChamada'>Deseja encerrar essa
                            chamada de Audio?</Typography>
                            <div className='textoChamadaEncerrada'>.Please enter the email address associated with your account, and we&apos;ll email you a link to reset your password.</div>
                        </div>
                        

                        

                        <button type='button' onClick={Encerrar} className='EncerrarBotaoAudio'>
                            <Iconify icon="eva:phone-fill" sx={{mr: 1}} />
                            <Typography>Encerrar</Typography>
                        </button>    

                            
                       
                    </div>
                </Box>
            </Container>

            </>
    );
}