import { Helmet } from 'react-helmet-async';
// sections
import Agendamentos from "../sections/@dashboard/Agendamentos/Agendamentos";

export default function AgendamentosPage() {
  return (
    <>
      <Helmet>
        <title>Agendamentos do Atendente -  Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <Agendamentos  />
    </>
  );
}
