import { Helmet } from 'react-helmet-async';
import { Regras } from 'src/sections/@dashboard/Regras';
// sections

export default function RegrasPage() {
  return (
    <>
      <Helmet>
        <title> Regras do Atendente -  Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <Regras/>
    </>
  );
}
