import {useEffect, useState} from 'react';
import {Outlet, useParams} from 'react-router-dom';
// @mui
import { Box } from '@mui/material';
// hooks
import * as React from "react";
import Dialog from "@mui/material/Dialog";
import useResponsive from '../../hooks/useResponsive';
// components
import { useSettingsContext } from '../../components/settings';
//
import Main from './Main';
import Header from './header';
import NavMini from './nav/NavMini';
import NavVertical from './nav/NavVertical';
import NavHorizontal from './nav/NavHorizontal';
import {useAuthContext} from "../../auth/useAuthContext";
import Notificacoes from "../../sections/@dashboard/Notificacoes/Notificacoes";
import {dataHoracertachat} from "../../utils/formatTime";
import DialogDormir from './componentesDashboard/DialogDormir';
import axios from "../../utils/axios";
import NotificaAudio from "../../sections/@dashboard/Notificacoes/ComponetesNotificacoes/NotificaAudio";
import NotificaVideo from "../../sections/@dashboard/Notificacoes/ComponetesNotificacoes/NotificaVideo";
import ChamadaRecusadaDialog from "../../sections/@dashboard/Notificacoes/ComponetesNotificacoes/ChamadaRecusadaDialog";
import ChamadaEncerradaDialog
    from "../../sections/@dashboard/Notificacoes/ComponetesNotificacoes/ChamadaEncerradaDialog";

// ----------------------------------------------------------------------
type props = {
    socket: any;
};
export default function DashboardLayout({socket}:props) {
  const {themeColorPresets, themeLayout,mudarColorPresets } = useSettingsContext();

  const isDesktop = useResponsive('up', 'lg');

  const [open, setOpen] = useState(false);
  const [openmodal, setopenmodal] = useState(false);
    const [openmodalChamadas, setopenmodalChamadas] = useState(false);
  const [open2, setOpen2] = useState<any>('');
  const [Dadosnoti, setDadosnoti] = useState<any>('');
    const { user } = useAuthContext();
  const isNavHorizontal = themeLayout === 'horizontal';
    const {tokenConversa = ''} = useParams();
  const isNavMini = themeLayout === 'mini';

  const [openmodalDormir, setopenmodalDormir] = useState(false);

  const handleOpenDormir = () => {
    setopenmodalDormir(true)
  }

  const handleCloseDormir = () => {
    setopenmodalDormir(false)
  }

  const handleOpen = () => {
    setOpen(true);
  };


    useEffect(() => {


      
      /* COACH */
      if(user?.TipoAtendente === 5) {
          if(themeColorPresets !=='blue'){
              mudarColorPresets('blue');
          }
      }

      /* TERAPEUTA */
      if(user?.TipoAtendente === 4)  {
          if(themeColorPresets !=='orange'){
              mudarColorPresets('orange');
          }
      }
      
      /* PSICOLOGO */
      if(user?.TipoAtendente === 2)  {
          if(themeColorPresets !=='purple'){
              mudarColorPresets('purple');
          }
      }

      /* ANJO */
      if(user?.TipoAtendente === 1){
        if(themeColorPresets !=='cyan'){
          mudarColorPresets('cyan');
        }
       }


    },[mudarColorPresets,user,themeColorPresets]);


    useEffect(() => {

        socket.emit('entrarusuario', `atendente${  user?.id}`);
        socket.on("connect", () => {
            console.log('usuario relogado');
            socket.emit('entrarusuario', `atendente${  user?.id}`);

            if(tokenConversa){
                console.log('entrou na sala');
                socket.emit('entrarsala', tokenConversa);
            }

        });// client-side

    },[socket, tokenConversa, user?.id]);



    useEffect(() => {
        let timer:any;

        socket.on("notifica", (dados:any) => {
            setDadosnoti(dados);
            setOpen2(dados.tipo);

                if(dados.tipo === 'notificaestadisponivel'){
                    handleOpenDormir();
                }else if(dados.tipo === "notificaaudio" || dados.tipo === "notificavideo"){
                    setopenmodalChamadas(true);
                }else{
                    setopenmodal(true);
                }
        });

    },[socket,user]);


    React.useEffect(() => {
        const interval = setInterval( async () => {
            const dataAtual = new Date();

            if (dataAtual.getHours() >= 20 || dataAtual.getHours() <= 7) {
                if (dataAtual.getMinutes() >= 0 || dataAtual.getMinutes() <= 5) {

                    if (user?.StatusAtendimento === 'Disponivel') {


                        const response = await axios.post('/areadoatendente/estoudisponivelis');
                        if(response.data){
                            handleOpenDormir();
                        }


                    }

                }
                if (dataAtual.getMinutes() >= 30 || dataAtual.getMinutes() <= 35) {

                    if (user?.StatusAtendimento === 'Disponivel') {
                        const response = await axios.post('/areadoatendente/estoudisponivelis');
                        if(response.data){
                            handleOpenDormir();
                        }
                    }

                }

            }


        }, 10000); // Atualizar a cada 10 min

        return () => clearInterval(interval);
    }, [user])




  const handleClose = () => {
    setOpen(false);
  };

  const fecharmodal = () => {
    setOpen2('');
    setopenmodal(false);
      setopenmodalChamadas(false);
  };

    // notificaaudio
    // notificavideo

    const tipo = (
        <>
            

            { openmodal  ?
                (
                    <Dialog
                        fullScreen
                        scroll='body'
                        open
                    ><Notificacoes fecharmodal={fecharmodal} dados={Dadosnoti} estadoNotifica={open2}/>
                    </Dialog>
                ) : ('')
            }

            {openmodalDormir ? (
              <DialogDormir openDormir={openmodalDormir} handleCloseDormir={handleCloseDormir} />
            ) : ('')}
            
        </>
    );



    const dialogChamada = (
        <>

            <Dialog
                open={openmodalChamadas}
                scroll='body'
                className='DialogAudioEVideo'
            >
                { open2 === "notificaaudio" && Dadosnoti.tipochamada === 'recebida' ? (
                    <NotificaAudio socket={socket}  fecharmodal={fecharmodal} dados={Dadosnoti}  />
                ) : ''}

                { open2 === "notificaaudio" && Dadosnoti.tipochamada === 'recusada' ? (
                    <ChamadaRecusadaDialog  fecharmodal={fecharmodal} dados={Dadosnoti}  />
                ) : ''}

                { open2 === "notificaaudio" && Dadosnoti.tipochamada === 'encerrou' ? (
                    <ChamadaEncerradaDialog  fecharmodal={fecharmodal} dados={Dadosnoti}  />
                ) : ''}




                { open2 === "notificavideo" && Dadosnoti.tipochamada === 'recebida'? (
                    <NotificaVideo socket={socket} fecharmodal={fecharmodal} dados={Dadosnoti}/>
                ) : ''}

                { open2 === "notificavideo" && Dadosnoti.tipochamada === 'recusada' ? (
                    <ChamadaRecusadaDialog   fecharmodal={fecharmodal} dados={Dadosnoti}  />
                ) : ''}

                { open2 === "notificavideo" && Dadosnoti.tipochamada === 'encerrou' ? (
                    <ChamadaEncerradaDialog   fecharmodal={fecharmodal} dados={Dadosnoti}  />
                ) : ''}

            </Dialog>



        </>
    )


  const renderNavVertical = <NavVertical openNav={open} onCloseNav={handleClose} />;


  if (isNavHorizontal) {
    return (
      <>
          {tipo}
          {dialogChamada}
        <Header onOpenNav={handleOpen} />

        {isDesktop ? <NavHorizontal /> : renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </>
    );
  }

  if (isNavMini) {
    return (
      <>
          {tipo}
          {dialogChamada}
        <Header onOpenNav={handleOpen} />

        <Box
          sx={{
            display: { lg: 'flex' },
            minHeight: { lg: 1 },
          }}
        >
          {isDesktop ? <NavMini /> : renderNavVertical}

          <Main>
            <Outlet />
          </Main>
        </Box>
      </>
    );
  }

  return (
    <>
        {tipo}
        {dialogChamada}
      <Header onOpenNav={handleOpen} />

      <Box
        sx={{
          display: { lg: 'flex' },
          minHeight: { lg: 1 },
        }}
      >
        {renderNavVertical}

        <Main>
          <Outlet />
        </Main>
      </Box>
    </>
  );
}
