import { useAuthContext } from '../../../auth/useAuthContext';
// routes
import { PATH_DASHBOARD } from '../../../routes/paths';
// components
import SvgColor from '../../../components/svg-color';

// ----------------------------------------------------------------------

const icon = (name: string) => (
  <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />
);

const ICONS = {
  user: icon('ic_user'),
  ecommerce: icon('ic_ecommerce'),
  analytics: icon('ic_analytics'),
  chat: icon('ic_chat'),
  dashboard: icon('ic_dashboard'),
  financeiro: icon('ic_banking'),
  agendamentos: icon('ic_calendar'),
  avaliacao: icon('ic_label'),
  regras: icon('ic_file'),
  cupons: icon('ic_invoice'),
  insights: icon('ic_tour'),
};

const navConfigProfissional = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'Eyhe 5.0',
    items: [
      { title: 'Dashboard', path: PATH_DASHBOARD.dashfirst, icon: ICONS.dashboard },
      { title: 'Atendimentos', path: PATH_DASHBOARD.atendimentos, icon: ICONS.chat },
      { title: 'Agendamentos', path: PATH_DASHBOARD.agendamentos, icon: ICONS.agendamentos },
      { title: 'Financeiro', path: PATH_DASHBOARD.financeiro, icon: ICONS.financeiro },
      { title: 'Avaliações', path: PATH_DASHBOARD.avaliacoes, icon: ICONS.avaliacao },
      { title: 'Regras', path: PATH_DASHBOARD.regras, icon: ICONS.regras },
      { title: 'Pacotes', path: PATH_DASHBOARD.pacotes, icon: ICONS.ecommerce },
      { title: 'Cupons', path: PATH_DASHBOARD.cupons, icon: ICONS.cupons },
      { title: 'Insights', path: PATH_DASHBOARD.insights, icon: ICONS.insights },
    ],
  },
];

export default navConfigProfissional;
