import * as React from 'react';
import * as Yup from 'yup';
import {useEffect, useState, useCallback, useMemo} from 'react';
import { alpha } from '@mui/material/styles';
import {
  styled, 
  Table,Collapse ,
  TableBody,
  TableCell ,
  TableContainer ,
  TableHead ,
  TablePagination ,
  TableRow ,
  TableSortLabel ,
  Toolbar ,
  Typography ,
  Checkbox ,
  IconButton ,
  Tooltip ,
  FormControlLabel ,
  Switch ,
  Button,
  Paper, 
  Box, 
  Grid, 
  } from "@mui/material";
  import { truncate } from 'lodash';
  import { visuallyHidden } from '@mui/utils';
import { CustomAvatar } from "src/components/custom-avatar";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, Controller } from 'react-hook-form';
import {useSnackbar} from "notistack";
import { LoadingButton } from '@mui/lab';
import Iconify from 'src/components/iconify';
import axios from "../../../utils/axios";
import ChatNavItem from "../chat/nav/ChatNavItem";
import ProfilePicture from "./img/pessoa_call.png";
import { CustomFile } from '../../../components/upload';

import {PATH_AUTH, PATH_DASHBOARD} from '../../../routes/paths';

import FormProvider, {RHFCheckbox} from '../../../components/hook-form';
import {fData} from "../../../utils/formatNumber";
import {useAuthContext} from "../../../auth/useAuthContext";
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import ModalCriarCupom from './componentesCupons/ModalCriarCupom';




// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));





  interface Data {
    id: number;
    taxa: number;
    quatidade: number;
    datacriacao: string;
    cupom: string;
    created_at: string;

  }
  
  function createData(
    id: number,
    cupom: string,
    taxa: number,
    datacriacao: string,
    created_at: string,
    quatidade: number,
  ): Data {
    return {
      id,
      cupom,
      taxa,
      datacriacao,
      quatidade,
      created_at
    };
  }
  
  const rows = [
    createData(1, 'AJUDA20', 20, '2023-01-15', '5',1),
    createData(2, 'DESCONTO15', 15, '2023-02-22', '10',1),
    createData(3, 'OFERTA10', 10, '2023-03-10', '8',1),
  ];
  
  function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  type Order = 'asc' | 'desc';
  
  function getComparator<Key extends keyof any>(
    order: Order,
    orderBy: Key,
  ): (
    a: { [key in Key]: number | string },
    b: { [key in Key]: number | string },
  ) => number {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  // Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
  // stableSort() brings sort stability to non-modern browsers (notably IE11). If you
  // only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
  // with exampleArray.slice().sort(exampleComparator)
  function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
    const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) {
        return order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  interface HeadCell {
    disablePadding: boolean;
    id: keyof Data;
    label: string;
    numeric: boolean;
  }
  
  const headCells: readonly HeadCell[] = [
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: 'ID',
    },
    {
      id: 'cupom',
      numeric: false,
      disablePadding: true,
      label: 'Nome Cupom',
    },
    {
      id: 'taxa',
      numeric: true,
      disablePadding: false,
      label: 'Desconto',
    },
    {
      id: 'created_at',
      numeric: true,
      disablePadding: false,
      label: 'Data de Criação',
    },
    {
      id: 'quatidade',
      numeric: true,
      disablePadding: false,
      label: 'Qtd de Usos',
    }
  ];
  
  interface EnhancedTableProps {
    numSelected: number;
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
    order: Order;
    orderBy: string;
    rowCount: number;
  }
  
  function EnhancedTableHead(props: EnhancedTableProps) {
    const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } =
      props;
    const createSortHandler =
      (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
      };
  
    return (
      <TableHead>
        <TableRow>

          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align="center"
              padding={headCell.disablePadding ? 'none' : 'normal'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  interface EnhancedTableToolbarProps {
    numSelected: number;
  }
  
  function EnhancedTableToolbar(props: EnhancedTableToolbarProps) {
    const { numSelected } = props;
  
    return (
      <Toolbar
        sx={{
          pl: { sm: 2 },
          pr: { xs: 1, sm: 1 },
          ...(numSelected > 0 && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
          }),
        }}
      >
        {numSelected > 0 ? (
          <Typography
            sx={{ flex: '1 1 100%' }}
            color="inherit"
            variant="subtitle1"
            component="div"
          >
            {numSelected} {numSelected === 1 ?('selecionado') : ('selecionados') } 
          </Typography>
        ) : (
          <Typography
            sx={{ flex: '1 1 100%' }}
            variant="h6"
            id="tableTitle"
            component="div"
          >
            Cupons
          </Typography>
        )}
        {numSelected > 0 ? (
          <Tooltip title="Delete">
            <IconButton>
              <Iconify icon='eva:trash-2-outline' />
            </IconButton>
          </Tooltip>
        ) : (
          ''
        )}
      </Toolbar>
    );
  }


export default function Cupons() {
  
  const navigate = useNavigate();


  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('id');
  const [selected, setSelected] = React.useState<readonly number[]>([]);
  const [page, setPage] = React.useState(0);
  const [totalpage, settotalpage] = React.useState(1);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: keyof Data,
  ) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      const newSelected = rows.map((n) => n.id);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event: React.MouseEvent<unknown>, id: number) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected: readonly number[] = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const isSelected = (id: number) => selected.indexOf(id) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;



  const [visibleRows, setvisibleRows] = useState<any[]>([]);

  const getfinaceiro= useCallback(async () => {
    try {
      const response = await axios.post(`/areadoatendente/cupom/getall?page=${page}`,{
        order,
        orderBy,
        rowsPerPage
      });

      const {cupons} = response.data;

      setvisibleRows(cupons.data);
      settotalpage(cupons.total)

    } catch (error) {
      console.error(error);
    }
  },[page, order, orderBy, rowsPerPage]);



  useEffect(() => {

    getfinaceiro();

  }, [getfinaceiro]);






  const [modalOpenCupom, setModalOpenCupom] = useState(false);

  const handleOpenModalCupom = () => {
    setModalOpenCupom(true);
  };

  const handleCloseModalCupom = () => {
    setModalOpenCupom(false);
  };

  const relogardados = () => {
    getfinaceiro();
  };

    
    return (
            <>
            <Box sx={{ p: 2.5 }}>
            
              <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
              >
                  <div>
                    <Button variant="text" onClick={()=>{ navigate(PATH_DASHBOARD.dashfirst,{replace:true})}}> <Iconify icon="eva:arrow-ios-back-outline"/> Dashboard </Button>
                
                    <div className="BreadCrumbsListagemMaterial">
                      <CustomBreadcrumbs
                      heading="Cupons"
                      links={[
                          {
                          name: 'Dashboard',
                          href: PATH_DASHBOARD.root,
                          },
                          {
                          name: 'Cupons',
                          href: PATH_DASHBOARD.regras,
                          }
                      ]}
                      />
                    </div>
                  </div>
                  <div>
                    <Button variant="contained" onClick={handleOpenModalCupom}> <Iconify icon="eva:plus-fill"/> Criar cupom </Button>    
                  </div>
              </Grid>
           
              
             
              
              <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
            size='medium'
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {visibleRows.map((row, index) => {
                const isItemSelected = isSelected(row.id);
                const labelId = `enhanced-table-checkbox-${index}`;

                return (
                  <TableRow
                    hover
                    onClick={(event) => handleClick(event, row.id)}
                    role="checkbox"
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={row.id}
                    selected={isItemSelected}
                    sx={{ cursor: 'pointer' }}
                  >

                    <TableCell align="center">{row.id}</TableCell>
                    <TableCell
                      component="th"
                      id={labelId}
                      scope="row"
                      padding="none"
                      align="left"
                    >
                      {row.cupom}
                    </TableCell>
                    <TableCell align="right">{row.taxa}%</TableCell>
                    <TableCell align="right">{row.datacriacao}</TableCell>
                    <TableCell align="right">{row.quantidade}</TableCell>
                    
                  </TableRow>
                );
              })}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height:  53 * emptyRows,
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalpage}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      
    </Box>

              
              

              </Box>
            
                  <ModalCriarCupom openModalCupom={modalOpenCupom} relogardados={relogardados} onCloseModalCupom={handleCloseModalCupom} />

            </>
    );
}
