import  { useEffect, useState, useCallback, useMemo} from 'react';
import useResponsive from 'src/hooks/useResponsive';
import {Divider, styled, Tabs,Radio ,RadioGroup ,FormControlLabel ,FormLabel , Tab, IconButton,Button,Link,TextField,InputLabel, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Iconify from 'src/components/iconify';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import CurrencyInput from "react-currency-input-field";
import { LoadingButton } from '@mui/lab';
import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
import { PATH_DASHBOARD } from '../../../../routes/paths';

import './CardListaPacotes.css';
import axios from "../../../../utils/axios";
import {useSnackbar} from "../../../../components/snackbar";
import DeleteModal from "../../../../components/modal/DeleteModal";

// ----------------------------------------------------------------------

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;
  
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
  
  function a11yProps(index: number) {
    return {
      id: `vertical-tab-${index}`,
      'aria-controls': `vertical-tabpanel-${index}`,
    };
  }

type Props = {
    NomePacote: any;
    ValorPacote: any;
    QtdAtendimentos: any;
    TipoAtendimento: any;
    onEditClick: any;
    pacote: any;
    atualizar: any;
};

export default function CardListaPacotes({NomePacote, ValorPacote, QtdAtendimentos, TipoAtendimento, onEditClick,pacote,atualizar }:Props) {

const isDesktop = useResponsive('up', 'lg');
const navigate = useNavigate();

const [valorChat, setValorChat] = useState('');

const handleChangeValor = (value:any) => {
  setValorChat(value);
};

const [quantidadeSessoes, setQuantidadeSessoes] = useState('');

const handleChangeQtdSessoes = (event:any) => {
  setQuantidadeSessoes(event.target.value);
};

const [nomePacote, setNomePacote] = useState('');

const handleChangeNomePacotes = (event:any) => {
  setNomePacote(event.target.value);
};

const [selectedTipoConversa, setSelectedTipoConversa] = useState('');
    const { enqueueSnackbar } = useSnackbar();
  const handleChangeTipoConversa = (event:any) => {
    setSelectedTipoConversa(event.target.value);
  };

const tipoConversa = [
    { value: 'TipoConversa', label: 'Tipo de conversa', disabled: true },
    {
      value: 'Texto',
      label: 'Texto',
    },
    {
      value: 'TextoAudio',
      label: 'Texto + Chamada de Áudio',
    },
    {
      value: 'TextoAudioVideo',
      label: 'Texto + Chamadas de Áudio e Vídeo',
    }
  ];

  const TipoConversaSelecionada = tipoConversa.find(option => option.value === selectedTipoConversa)?.label || '';

    const [modaldelete, setModaldelete] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleSubmit = () => {


        setModaldelete(true);


  };



    

    const deletar= useCallback(async () => {
        try {
            const response = await axios.post(`/areadoatendente/deletarrpacote`,{
                id:pacote.id
            });

            atualizar();
            setModaldelete(false);
        } catch (error) {
            enqueueSnackbar(`${ error.message}`, { variant: 'error' });
        }
    },[atualizar, enqueueSnackbar, pacote]);



    return (
            <>

                <DeleteModal opendeletemodal={modaldelete} setOpen={setModaldelete} deletar={deletar} />
                    <Grid item xs={12} md={4}>
                        <Item>
                            <Box sx={{ textAlign: 'left' }} className='boxResumoListaPacote'>
                                <Typography variant='subtitle2'>Pacote:</Typography>
                                <Typography variant='h3'> {NomePacote} </Typography>

                                <Divider sx={{my: 1.5}}/>

                                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{mb: 1.5}}>
                                    <Typography variant='subtitle2'> Valor total do pacote: </Typography>
                                    <Typography variant='h4'>R${ValorPacote} </Typography>
                                </Grid>

                                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{mb: 1.5}}>
                                    <Typography variant='subtitle2'> Quantidade de sessões: </Typography>
                                    <Typography variant='h4'> {QtdAtendimentos} </Typography>
                                </Grid>

                                <Grid container direction="column" justifyContent="flex-start" alignItems="flex-start" sx={{mb: 1.5}}>
                                    <Typography variant='subtitle2'> Tipo de atendimento: </Typography>
                                    <Typography variant='h4'> {TipoAtendimento} </Typography>
                                </Grid>



                                <Grid container spacing={2}>
                                    <Grid item xs={6}>
                                        <Item>
                                            <Button type="submit"
                                            variant="contained"
                                            color="primary"
                                            fullWidth
                                            sx={{py: 1.5}}
                                            onClick={()=>{onEditClick(pacote)}}>Editar</Button>
                                        </Item>
                                    </Grid>
                                    
                                    
                                </Grid>
                            
                              

                               
                                
                                
                            </Box>
                        </Item>
                    </Grid>
                

            

                
            


            </>
    );
}
