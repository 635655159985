import {Divider,Tabs, Tab, IconButton,Button,TextField,InputLabel, List, MenuItem,FormControl,OutlinedInput,InputAdornment, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import { CustomAvatar } from "src/components/custom-avatar";
import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams, Link as RouterLink } from 'react-router-dom';
import FiltrarProfissional from "src/components/filtrar-profissional/FiltrarProfissional";
import FiltrarAssuntos from "src/components/filtrar-assuntos/FiltrarAssuntos";
import AtendenteRecomendado from "src/components/atendentes-recomendados/AtendentesRecomendados";
import HistoricoAcolhimentoPrimeiroAcesso from "src/components/historico-acolhimento/HistoricoAcolhimentoPrimeiroAcesso";
import HistoricoAcolhimentoRecorrente from "src/components/historico-acolhimento-recorrente/HistoricoAcolhimentoRecorrente";

// @mui
import useResponsive from "src/hooks/useResponsive";
import { styled } from '@mui/material/styles';
import _mock from "./componentesDashboardFirst/mock";    

import Iconify from "../../../components/iconify";
import axios from "../../../utils/axios";
import {useSettingsContext} from '../../../components/settings';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import './Dashfirst.css';

import Atendente from "../Atendentes/ComponetesAtedentes/atendente";


import CarouselAnimation from "./componentesDashboardFirst/CarouselDashboardFirst";
import {PATH_DASHBOARD} from "../../../routes/paths";
import {useAuthContext} from "../../../auth/useAuthContext";

import AgendamentosMarcados from "./componentesDashboardFirst/AgendamentosMarcados";
import Financeiro from "./componentesDashboardFirst/Financeiro";
import Avaliacoes from "./componentesDashboardFirst/Avaliacoes";
import MateriaisdeApoio from "./componentesDashboardFirst/MateriaisdeApoio";
import Contribuicao from "./componentesDashboardFirst/Contribuicao";
import MaterialdeApoioIndividual from "./componentesDashboardFirst/MaterialdeApoioIndividual";
import {useSnackbar} from "../../../components/snackbar";
import AvaliacaoIndividual from "./componentesDashboardFirst/AvaliacaoIndividual";


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));



export default function DashfirstListagemMaterial() {

    const { enqueueSnackbar } = useSnackbar();
    const navigate = useNavigate();
    const [listarmateriais, setlistarmateriais] = useState<any>({data:[]});

    const getmateriais = useCallback(async () => {
        try {
            const response = await axios.post('/areadoatendente/materiais');

            const {materiais} = response.data;
            setlistarmateriais(materiais);


        } catch (error) {
            enqueueSnackbar(`${ error.message}`, { variant: 'error' });
        }
    },[enqueueSnackbar]);

    useEffect(() => {

        getmateriais();

    }, [getmateriais]);



    return (
            <>

            
            <Button variant="text" onClick={()=>{ navigate(PATH_DASHBOARD.dashfirst,{replace:true})}}> <Iconify icon="eva:arrow-ios-back-outline"/> Dashboard </Button>
            
            <div className="BreadCrumbsListagemMaterial">
            <CustomBreadcrumbs
            heading="Materiais de Apoio"
            links={[
                {
                name: 'Dashboard',
                href: PATH_DASHBOARD.root,
                },
                {
                name: 'Materiais de Apoio',
                href: PATH_DASHBOARD.financeiro,
                }
            ]}
            />
            </div>
            
           

                    <Grid container spacing={2} sx={{px: 1.5}}>
                        {listarmateriais.data.map((listarmaterial:any,index:any) => (
                        <Item key={index}><MaterialdeApoioIndividual listarmaterial={listarmaterial}/> </Item>
                        ))}
                    </Grid>



                    
            

                   
            </>
    );
}