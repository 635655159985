import {useState, useEffect, useCallback} from 'react';
import {Link as RouterLink, useNavigate, useParams} from 'react-router-dom';
// @mui
import { useTheme, styled } from '@mui/material/styles';
import {
    Box,
    Stack,
    Drawer,
    IconButton, Button,
    IconButtonProps,
    Typography, Grid, Paper, FilledInput,
    Tooltip,
    Divider,
    List, Pagination, FormControl, InputLabel, OutlinedInput, InputAdornment,
    MenuItem, Select, Alert,AlertTitle, Card, CardHeader, CardContent, CardActions, TextField
} from '@mui/material';
import  { SelectChangeEvent } from '@mui/material/Select';
import Logo from 'src/components/logo';
// hooks
import {yupResolver} from "@hookform/resolvers/yup/dist/yup";
import * as Yup from "yup";
import {useForm} from "react-hook-form";
import {LoadingButton} from "@mui/lab";
import useResponsive from '../../../hooks/useResponsive';
import './EsqueciSenha.css';
// utils
import axios from '../../../utils/axios';
// routes
import {PATH_AUTH, PATH_DASHBOARD} from '../../../routes/paths';
// @types
import { IChatContact, IChatConversationsState } from '../../../@types/chat';
// components
import Iconify from '../../../components/iconify';
import Scrollbar from '../../../components/scrollbar';
import FormProvider, { RHFTextField, RHFSelect } from '../../../components/hook-form';
import {useAuthContext} from "../../../auth/useAuthContext";
import RHFSelectBandeiras from "../../../components/hook-form/RHFSelectBandeiras";
import RHFnascimento from "../../../components/hook-form/RHFnascimento";
import RHFtelefone from "../../../components/hook-form/RHFtelefone";
// eslint-disable-next-line import/order
import {useSnackbar} from "notistack";



//

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));
// ----------------------------------------------------------------------




export default function EsqueciSenha() {

    const navigate = useNavigate();
    const { name } = useParams();
    const [esqueceuSenha, setEsqueceuSenha] = useState('email'); // email > codigo > senha
    const [email, mudarEmail] = useState('');
    const [codigo, mudarcodigo] = useState('');
    const [senha, mudarsenha] = useState('');
    const [isSubmitting, mudarisSubmitting] = useState(false);
    const { enqueueSnackbar } = useSnackbar();
    const irCodigo = async () => {
        mudarisSubmitting(true);
        if(email === ''){
            enqueueSnackbar(`Campo email não pode ser vazio`, {variant: 'error'});
            mudarisSubmitting(false);
        }else{

            try {
                const data = {email};
                const response = await axios.post('/recuperarsenhaanjo/email', data);
                const {user} = response.data;
                setEsqueceuSenha('codigo');
                mudarisSubmitting(false);

            } catch (error) {
                enqueueSnackbar(`${error.message}`, {variant: 'error'});
                mudarisSubmitting(false);
            }

        }

    }

    const irNovaSenha= async () => {
        mudarisSubmitting(true);
        if (codigo === '') {
            enqueueSnackbar(`Campo código não pode ser vazio`, {variant: 'error'});
            mudarisSubmitting(false);
        } else {

            try {
                const data = {codigo,email};
                const response = await axios.post('/recuperarsenhaanjo/codigo', data);
                const {user} = response.data;
                mudarisSubmitting(false);
                setEsqueceuSenha('senha');


            } catch (error) {
                enqueueSnackbar(`${error.message}`, {variant: 'error'});
                mudarisSubmitting(false);
            }

        }


    }
    const criarnovasenha= async () => {
        mudarisSubmitting(true);
        if (senha === '') {
            enqueueSnackbar(`Campo senha não pode ser vazio`, {variant: 'error'});
            mudarisSubmitting(false);
        } else {

            try {
                const data = {codigo,email,senha};
                const response = await axios.post('/recuperarsenhaanjo/senha', data);
                const {user} = response.data;
                enqueueSnackbar(`Senha alterada com sucesso, em instantes você sera redirecionado para fazer um novo login`, {variant: 'success'});

                setTimeout(()=>{
                    mudarisSubmitting(false);
                    navigate(PATH_AUTH.login,{replace:true});

                },3000)


            } catch (error) {
                enqueueSnackbar(`${error.message}`, {variant: 'error'});
                mudarisSubmitting(false);
            }

        }


    }

    function getYear() {
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        return currentYear;
    }

    // Exemplo de uso:
    const year = getYear();

    return (
        <>


            <Grid container
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  className='MainCadastroButtons'>

                <Grid item xs={12} md={8} >
                    <Item className='heroCadasterBackground'> <></> </Item>
                </Grid>

                <Grid item xs={12} md={4} >

                    {esqueceuSenha === 'email' ? (
                        <Card sx={{mx: 4, px: 2, pb: 2}}>

                            <CardHeader
                                title="Olá Anjo, esqueceu a senha?"
                                subheader="Vamos recuperar seu acesso!"
                            />
                            <CardContent sx={{textAlign: 'center'}}>
                                <Alert>
                                    Digite seu e-mail. Você receberá um <strong>código por E-mail</strong>
                                </Alert>
                                <TextField id="email" onChange={(event)=>{mudarEmail(event.target.value)}} fullWidth label="Informe seu e-mail" variant="outlined" sx={{my: 2.5}} />

                                <LoadingButton variant='contained' sx={{ mx: 'auto', p: 1.5 }} onClick={irCodigo} loading={isSubmitting}>
                                    Recuperar minha senha <Iconify icon='eva:chevron-right-outline' sx={{ml: 1}} />
                                </LoadingButton>
                            </CardContent>

                        </Card>
                    ) : ('')}

                    {esqueceuSenha === 'codigo' ? (
                        <Card sx={{mx: 4, px: 2, pb: 2}}>
                            <CardHeader
                                title="Código verificador"
                            />
                            <CardContent sx={{textAlign: 'center'}}>
                                <Alert>
                                    Digite o código que foi enviado para o seu email.
                                </Alert>
                                <TextField id="text" type="number" fullWidth label="Insira aqui o código" onChange={(event)=>{mudarcodigo(event.target.value)}} variant="outlined" sx={{my: 2.5}} />


                                <LoadingButton variant='contained' sx={{ mx: 'auto', p: 1.5 }} onClick={irNovaSenha} loading={isSubmitting}>
                                    Criar nova senha <Iconify icon='eva:chevron-right-outline' sx={{ml: 1}} />
                                </LoadingButton>
                            </CardContent>

                        </Card>
                    ) : ('')}

                    {esqueceuSenha === 'senha' ? (
                        <Card sx={{mx: 4, px: 2, pb: 2}}>
                            <CardHeader
                                title="Redefinir senha"
                            />
                            <CardContent sx={{textAlign: 'center'}}>
                                <Alert>
                                    Escolha sua nova senha, digite abaixo para redifinir!
                                </Alert>
                                <TextField id="text" type="password" fullWidth label="Nova Senha" onChange={(event)=>{mudarsenha(event.target.value)}} variant="outlined" sx={{my: 2.5}} />

                                <LoadingButton onClick={criarnovasenha} variant='contained' sx={{ mx: 'auto', p: 1.5 }}loading={isSubmitting}>
                                    Redefinir Senha <Iconify icon='eva:checkmark-circle-outline' sx={{ml: 1}} />
                                </LoadingButton>
                            </CardContent>

                        </Card>
                    ) : ('')}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            height: '100%',
                            mt: 2
                        }}
                    >
                        <Logo/>
                        <Typography variant='caption' sx={{mt: 1}}>© Eyhe {year} Todos os direitos reservados</Typography>
                    </Box>


                </Grid>

            </Grid>






        </>
    )
}
