import * as React from 'react';
import { m } from 'framer-motion';
import Dialog from '@mui/material/Dialog';
import { Helmet } from 'react-helmet-async';
import { TransitionProps } from '@mui/material/transitions';
import Slide from '@mui/material/Slide';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { Button, Typography } from '@mui/material';
// components
import { MotionContainer, varBounce } from '../components/animate';
// assets
import { PageNotFoundIllustration } from '../assets/illustrations';
import SalaAudio from "../sections/@dashboard/SalaAudio/SalaAudio";

const Transition = React.forwardRef((
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) => <Slide direction="up" ref={ref} {...props} />);

// ----------------------------------------------------------------------
type props = {
    socket: any;
};
export default function ChatAudio({socket}:props) {
  return (
    <>
      <Helmet>
        <title> Chat de Áudio do Atendente -  Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

        <Dialog
            fullScreen
            scroll='body'
            open
            TransitionComponent={Transition}
        >
            <SalaAudio/>
        </Dialog>
    </>
  );
}
