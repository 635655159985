import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import { CustomAvatar } from "src/components/custom-avatar";
import {Card, Grid, IconButton, Typography, Rating} from '@mui/material';
// @mui
import { styled } from '@mui/material/styles';
import {useSettingsContext} from '../../../../components/settings';

import '../DetalhesAgendamento.css';

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// routes
import {PATH_DASHBOARD} from '../../../../routes/paths';
import axios from "../../../../utils/axios";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";

import Avatar from './avatar.png'

export default function CardAvaliacao() {

    const [value, setValue] = useState<number | null>(2);

    return (

        
        
            <>
                <Typography variant='subtitle2'>Avaliação recebida</Typography>

                <Card sx={{my:1}} className='CardAvaliacaoInfoAgendamento'>
                    <Rating name="read-only" value={value} readOnly />
                    <Typography className='AvaliacaoTexto'>Contribua para que mais pessoas possam ser acolhidas gratuitamente. Quando você contribui, vidas são salvas.</Typography>
                </Card>

            </>
    );
}
