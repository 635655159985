import { Helmet } from 'react-helmet-async';
// sections

import {Pacotes} from "../sections/@dashboard/Pacotes";



export default function PacotesPage() {
  return (
    <>
      <Helmet>
        <title>Pacotes do Atendente -  Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <Pacotes  />
    </>
  );
}
