import * as React from 'react';
import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import Form from 'react-bootstrap/Form';
// @mui
import {CssBaseline, Box, ToggleButton,ToggleButtonGroup,TextareaAutosize ,IconButton,Stepper,Step,LinearProgress , StepLabel, Container,Paper, Grid, Dialog,Slide, Typography, Tooltip, Button, Link,TextField } from "@mui/material";
import { CustomAvatar } from "src/components/custom-avatar";
import { TransitionProps } from '@mui/material/transitions';
import Iconify from 'src/components/iconify/Iconify';
import { styled } from '@mui/material/styles';
import {useSettingsContext} from '../../../components/settings';

import salaespera1 from './img/salaespera1.png'

import AmazonLogo from './img/amazonLogo.png'
import NetflixLogo from './img/netflixLogo.png'
import imageProfile from './img/imageProfile.webp'
import anjoChegou from './img/anjoChegou.webp'
import anjoNaoChegou from './img/anjoNaoChegou.webp'

import './SalaEspera.css'
import CarouselAnimation from '../Dashfirst/componentesDashboardFirst/CarouselDashboardFirst';
import axios from "../../../utils/axios";
import {useAuthContext} from "../../../auth/useAuthContext";
import {PATH_DASHBOARD} from "../../../routes/paths";



// ----------------------------------------------------------------------


const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const steps = [' ', ' ', ' '];



type Props = {
    socket: any;

};
export default function SalaEspera({socket}:Props) {
    const { user } = useAuthContext();
    const {tokenConversa = ''} = useParams();
    const blue = {
        100: '#DAECFF',
        200: '#b6daff',
        400: '#3399FF',
        500: '#007FFF',
        600: '#0072E5',
        900: '#003A75',
      };
    
      const grey = {
        50: '#f6f8fa',
        100: '#eaeef2',
        200: '#d0d7de',
        300: '#afb8c1',
        400: '#8c959f',
        500: '#6e7781',
        600: '#57606a',
        700: '#424a53',
        800: '#32383f',
        900: '#24292f',
      };
    
      const StyledTextarea = styled(TextareaAutosize)(
        ({ theme }) => `
        width: 320px;
        font-family: IBM Plex Sans, sans-serif;
        font-size: 0.875rem;
        font-weight: 400;
        line-height: 1.5;
        padding: 12px;
        border-radius: 12px 12px 0 12px;
        color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
        background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
        border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
        box-shadow: 0px 2px 2px ${theme.palette.mode === 'dark' ? grey[900] : grey[50]};
      
        &:hover {
          border-color: ${blue[400]};
        }
      
        &:focus {
          border-color: ${blue[400]};
          box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[500] : blue[200]};
        }
      
        // firefox
        &:focus-visible {
          outline: 0;
        }
      `,
      );

    const enviarnotifica =  useCallback(async () => {

        socket.emit("enviarnotifica",

            {
                iduser:`atendido${user?.id}`,
                tipo:'AnjoNaoChegou',
                tokenConversa,
            });

    },[tokenConversa,socket,user]);


    const [progress, setProgress] = React.useState(0);
    const [time, setTime] = useState(300000);

    React.useEffect(() => {


        const timer = setInterval(() => {
            // eslint-disable-next-line @typescript-eslint/no-shadow
            setTime((time) => Math.max( time - 1000, 0));
        setProgress((oldProgress) => {
            const diff = 0.33;
            return Math.min(oldProgress + diff, 100);
        });
        }, 1000);

        if(time === 0){
            enviarnotifica();
            clearInterval(timer);
        }



        return () => {
        clearInterval(timer);
        };
    }, [enviarnotifica,time]);
    


    const [open, setOpen] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    

    const [questao1, setquestao1] = React.useState('simQuestao1');

    const handleChange1 = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setquestao1(newAlignment);
    };

    const [questao2, setquestao2] = React.useState('simQuestao2');

    const handleChange2 = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setquestao2(newAlignment);
    };

    const [questao3, setquestao3] = React.useState('simQuestao3');

    const handleChange3 = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: string,
    ) => {
        setquestao3(newAlignment);
    };


// Aguardando // Chegou
    const [Aguarde, setAguarde] = React.useState("Aguardando");

    const AnjoChegou = () => {
        setAguarde("Chegou");
    };

    const [activeStep, setActiveStep] = React.useState(0);
    const [skipped, setSkipped] = React.useState(new Set<number>());
  
    
    const isStepOptional = (step: number) => {
      return step === 1;
      console.log('pass');
  };
  
  
    const isStepSkipped = (step: number) => {
      return skipped.has(step);
      console.log('pass2');
    };
  
    const handleNext = async () => {

        if(activeStep === 0  || activeStep === 1){
            Postrespostas();
        }


      let newSkipped = skipped;
      if (isStepSkipped(activeStep)) {
        newSkipped = new Set(newSkipped.values());
        newSkipped.delete(activeStep);
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
    };
  
    const handleBack = () => {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleSkip = () => {
      if (!isStepOptional(activeStep)) {
        // You probably want to guard against something like this,
        // it should never occur unless someone's actively trying to break something.
        throw new Error("You can't skip a step that isn't optional.");
        
      }
  
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped((prevSkipped) => {
        const newSkipped = new Set(prevSkipped.values());
        newSkipped.add(activeStep);
        return newSkipped;
      });
    };

    const [Assunto, setAssunto] = React.useState("");
    const handleChangetext = async (event: any) => {
        try {

            setAssunto(event.target.value);

        } catch (error) {
            console.error(error);
        }
    };



    const handleReset = () => {
      setActiveStep(0);
    };

    const [Status, setStatus] = React.useState("Step0");

    const Postrespostas = useCallback(async () => {
        try {
            const response = await axios.post('/areadoatendente/salaespera/respostas/post', {
                questao1,
                questao2,
                questao3,
                Assunto
            });



        } catch (error) {
            console.error(error);
        }
    }, [questao1,questao2,questao3,Assunto]);

    return (    
            <>

            <Dialog
                fullScreen
                open={open}
                onClose={handleClose}
                className='DialogStep0SaladeEspera'
            >
                <div className='DialogPaddingStep0SaladeEspera'>
                <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="flex-start"
                >
                    <Item> 
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"

                        >
                            <Iconify icon="eva:chevron-left-fill"/>
                        </IconButton>
                    </Item>

                    <Item>
                    <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="close"
                            onClick={handleClose}
                        >
                             <Iconify icon="eva:close-fill"/>
                        </IconButton>
                    </Item>
                </Grid>

                <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                >
                    <img src={salaespera1} className='ImagemSalaDeEspera1' alt='Aguarde'/>

                    <Typography className='TituloSalaDeEsperaSlide01'> Olá, {user?.nome} </Typography>
                    <Typography className='SubTituloSalaDeEsperaSlide01'> Esta é a sala de encontro do Eyhe. O Anjo que você escolheu já está chegando. </Typography>
                    <Typography className='TextoApoioSalaDeEsperaSlide01'> Vamos iniciar o acolhimento para nos conhecermos melhor? </Typography>
                </Grid>

                <div className='FooterButtonSalaDeEsperaSlider01'> 
                    <Button className='ButtonSalaDeEsperaSlider01' onClick={handleClose}>Iniciar Acolhimento</Button>
                </div>

                </div>
            </Dialog>


        <div className='GlobalSaladeEsperaSteppereSteps'>
            <Stepper activeStep={activeStep}>
                {steps.map((label, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: {
                    optional?: React.ReactNode;
                } = {};
                
                if (isStepSkipped(index)) {
                    stepProps.completed = false;
                }
                return (
                    <Step key={index} {...stepProps}>
                    <StepLabel {...labelProps}>{label}</StepLabel>
                    </Step>
                );
                })}
            </Stepper>


            
            <div className='StepseBotoesSaladeEspera'>

                <div className='StepsSaladeEsperaGlobal'>
                    {activeStep === 0 ? (
                        <div className='Step1SaladeEspera'>
                            
                            <Typography className='TituloStep1SaladeEspera'>Seu Anjo está chegando</Typography>


                            <div className='Pergunta1'>
                            <Typography> 1. Você ja fez terapia? </Typography>
                                <ToggleButtonGroup
                                color="primary"
                                value={questao1}
                                exclusive
                                onChange={handleChange1}
                                aria-label="Platform"
                                >
                                    <ToggleButton value="simQuestao1" className='AlternativasPerguntasStep1SaladeEspera'>Sim</ToggleButton>
                                    <ToggleButton value="naoQuestao1" className='AlternativasPerguntasStep1SaladeEspera'>Não</ToggleButton>
                                </ToggleButtonGroup>
                            </div>



                            <div className='Pergunta2'>
                            <Typography> 2. Você ja fez terapia? </Typography>
                                <ToggleButtonGroup
                                color="primary"
                                value={questao2}
                                exclusive
                                onChange={handleChange2}
                                aria-label="Platform"
                                >
                                    <ToggleButton value="simQuestao2" className='AlternativasPerguntasStep1SaladeEspera'>Sim</ToggleButton>
                                    <ToggleButton value="naoQuestao2" className='AlternativasPerguntasStep1SaladeEspera'>Não</ToggleButton>
                                </ToggleButtonGroup>
                            </div>




                            <div className='Pergunta3'>
                            <Typography> 3. Você ja fez terapia? </Typography>
                                <ToggleButtonGroup
                                color="primary"
                                value={questao3}
                                exclusive
                                onChange={handleChange3}
                                aria-label="Platform"
                                >
                                    <ToggleButton value="simQuestao3" className='AlternativasPerguntasStep1SaladeEspera'>Sim</ToggleButton>
                                    <ToggleButton value="naoQuestao3" className='AlternativasPerguntasStep1SaladeEspera'>Não</ToggleButton>
                                </ToggleButtonGroup>
                            </div>

                        </div>
                    ) : ('') }

                    {activeStep === 1 ? (
                        <div className='Step2SaladeEspera'>
                            <Typography>Fale um pouco sobre o assunto que deseja conversar para que o Anjo entenda a sua necessidade</Typography>

                            <TextField
                                id="outlined-multiline-static"
                                multiline
                                rows={4}
                                className='textoarea'
                                onChange={handleChangetext}
                                placeholder='Digite aqui'
                            />
                            
                                <div className='IconAlertaStep2SaladeEspera'>
                                    <Iconify icon="eva:info-fill"/>
                                    <Typography className='TextoAlertaStep2SaladeEspera'>Caption text, description, notification</Typography>
                                </div>


                        </div>
                    ) : ('') }    

                    
                    { activeStep === 2 ? (
                        <div className='Step3SaladeEspera'>
                            <Typography>Este acolhimento gratuito é possível porque empresas responsáveis apoiam o propósito do Eyhe</Typography>
                            <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
                                <Grid item xs={6} md={4} lg={3}>
                                <Item><img src={AmazonLogo} alt='Amazon Logo' width="90%"/></Item>
                                </Grid>
                                <Grid item xs={6} md={4} lg={3}>
                                <Item><img src={NetflixLogo} alt='Netflix Logo' width="90%" /></Item>
                                </Grid>
                                <Grid item xs={6} md={4} lg={3}>
                                <Item><img src={AmazonLogo} alt='Amazon Logo' width="90%" /></Item>
                                </Grid>
                                <Grid item xs={6} md={4} lg={3}>
                                <Item><img src={NetflixLogo} alt='Netflix Logo' width="90%" /></Item>
                                </Grid>
                            </Grid>
                        </div>
                    ) : ('') }  


                    {activeStep === 3 ? (
                        <div className='Step4SaladeEspera'>
                            <Typography className='TituloStep4SaladeEspera'>Seu Anjo está chegando</Typography>
                            <Typography className='SubTituloStep4SaladeEspera'>Aproveite este tempo para conhecer mais sobre o Eyhe.</Typography>

                            <CarouselAnimation/>
                                                      
                        </div>
                    ) : ('') }
                    
                    


                </div>

            {activeStep !== steps.length ? (
             
                 <div className='FooterButtonNextFinishSaladeEspera'>
                    <Button onClick={handleNext} className='ButtonNextFinishSaladeEspera'>
                      {activeStep === steps.length - 1 ? 'Finalizar' : 'Próximo'}
                    </Button>
                </div>
                
              ): (' ')}
            </div>

            
        </div>

        {Aguarde === "Aguardando" ? (
            <div className='CardTimerSaladeEspera'>
                <Grid
                container 
                spacing={2}
                direction="row"
                justifyContent="space-between"
                >
                    <Grid item xs={10}>
                        <Item className='IconRelogioeTituloSaladeEspera'>
                            <Iconify icon="eva:clock-outline"/> 
                            <Typography className='TituloTimerSaladeEspera'>Seu anjo está chegando</Typography>  
                        </Item>
                    </Grid>
                        
                    <Grid item xs={2}>
                        <Item>
                            <Iconify icon="eva:chevron-right-outline"/>
                        </Item>
                    </Grid>

                </Grid>
                <Grid
                container 
                spacing={2}
                direction="row"
                justifyContent="space-between"
                >
                    <Grid item xs={3}>
                        <Item>
                            <Typography className='TimerSaladeEspera'>  {(`0${  Math.floor((time / 60000) % 60)}`).slice(-2)}:{(`0${  Math.floor((time / 1000) % 60)}`).slice(-2)}</Typography>
                        </Item>
                    </Grid>
                    <Grid item xs={9}>
                        <Item  className='ProgressoCard'>
                            <LinearProgress variant="determinate" value={progress} />
                        </Item>
                    </Grid>
                </Grid>
            </div>
        ) : (' ')}
        

        {Aguarde === "Chegou" ? (
            <div className='CardAtendenteChegouSaladeEspera'>
                
                        
                    <div className='FotoeTituloAnjoChegouSaladeEspera'>
                    <CustomAvatar
                            className="FotoPerfilAtendente"
                            src={imageProfile}
                            alt="Fulano"
                            name="Fulano"
                            sx={{ cursor: 'pointer', width: 40, height: 40 }}
                        />

                        <div className='TituloeTextoAtendenteChegouSaladeEspera'>
                            <Typography className='TituloAtendenteChegouSaladeEspera'>Seu anjo Chegou</Typography>            
                            <Typography className='SubTituloAtendenteChegouSaladeEspera'>Clique para encontrá-lo</Typography>
                        </div>
                    </div>
                        

                     
                    <Button className='BotaoIrParaoChatSaladeEspera'>Ir para o Chat</Button>
                    

                
            </div>
        ) : (' ')}
        






            </>
    );
}
