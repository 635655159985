import { Helmet } from 'react-helmet-async';
// sections
import Avaliacoes from "../sections/@dashboard/Avaliacoes/Avaliacoes";



export default function AvaliacoesPage() {
  return (
    <>
      <Helmet>
        <title> Avaliações do Atendente -  Eyhe Maior Comunidade de Suporte Emocional do Brasil </title>
      </Helmet>

      <Avaliacoes  />
    </>
  );
}
