import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import Iconify from 'src/components/iconify';
import {yupResolver} from "@hookform/resolvers/yup";
import {useForm} from "react-hook-form";
import * as Yup from "yup";
import {LoadingButton} from "@mui/lab";
import CupomImagemModal from '../img/CupomImagemModal.png';
import axios from "../../../../utils/axios";
import {useSnackbar} from "../../../../components/snackbar";
import FormProvider, {
    RHFSelect,
    RHFSelectBandeiras,
    RHFSwitch,
    RHFTextField,
    RHFUploadAvatar,
} from '../../../../components/hook-form';
import {IUserAccountGeneral} from "../../../../@types/user";
import {CustomFile} from "../../../../components/upload";


interface FormValuesProps extends Omit<IUserAccountGeneral, 'avatarUrl'> {
    avatarUrl: CustomFile | string | null;
}


type Props =  {
    openModalCupom: any;
    onCloseModalCupom: any;
    relogardados: any;
}

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

export default function ModalCriarCupom({ openModalCupom, onCloseModalCupom,relogardados }: Props) {

    const { enqueueSnackbar } = useSnackbar();


    const NewUserSchema = Yup.object().shape({
        cupom: Yup.string().required('Nome do Cupom é obrigatorio'),
        taxa: Yup.string().required('Porcetagem é obrigatorio'),
        quatidade: Yup.string().required('Quantidade é obrigatorio'),

    });




    const methods = useForm<FormValuesProps>({
        resolver: yupResolver(NewUserSchema)
    });

    const {
        reset,
        watch,
        control,
        setValue,
        handleSubmit,
        formState: { isSubmitting },
    } = methods;
    const onSubmit = async (data: FormValuesProps) => {
        try {
            const response = await axios.post('/areadoatendente/cupom/criar', data);

            enqueueSnackbar('Cupom criado com sucesso!');
            relogardados();
            onCloseModalCupom();
        } catch (error) {
            enqueueSnackbar(`${error.message}`, { variant: 'error' });
        }
    };


  return (
    <Dialog open={openModalCupom} onClose={onCloseModalCupom}>
        <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <DialogTitle>Criar Cupom</DialogTitle>
      <DialogContent>
        
        
        <Grid
       
        // direction="column"
        justifyContent="center"
        alignItems="center"
        >
            <Grid item>
                <Item> <img src={CupomImagemModal} alt='Imagem Cupom' width={400}/> </Item>
            </Grid>
            <Grid item>
                <Item>

                    <RHFTextField id="nome_cupom" name='cupom' label="Nome do Cupom" variant="outlined" fullWidth />
                </Item>
            </Grid>
            <Grid item>
                <Item>
                    <RHFTextField id="desconto_cupom" name='taxa' type="number" inputProps={{ min:1, max: 100 }} label="Porcentagem de Desconto" variant="outlined" fullWidth />
                </Item>
            </Grid>
            <Grid item>
                <Item>
                    <RHFTextField id="desconto_cupom" name='quatidade' type="number"  label="Digite uma quantidade" variant="outlined" fullWidth />
                    <small>Digite 0 para ficar ilimitado</small>
                </Item>
            </Grid>
        </Grid>


        
        

      </DialogContent>
      <DialogActions>
        <Button onClick={onCloseModalCupom} variant='outlined' color="error" sx={{py: 1, px: 2}}>
            <Iconify icon='eva:close-fill' sx={{mr: 1}} /> Fechar
        </Button>

          <LoadingButton
              type="submit"
              variant='contained' color="success" sx={{py: 1, px: 2}}
              loading={isSubmitting}
          >
              <Iconify icon='eva:checkmark-circle-outline' sx={{mr: 1}} /> Criar Cupom
          </LoadingButton>



        {/* Adicione outros botões ou ações conforme necessário */}
      </DialogActions>
        </FormProvider>
    </Dialog>
  );
};