import * as React from 'react';
import {CssBaseline, Box, Container, Typography, Tooltip, Button, Link, Grid, IconButton, Paper} from "@mui/material";
import {styled} from '@mui/material/styles';
import {useEffect, useState, useCallback, useRef} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {PATH_AUTH, PATH_DASHBOARD} from '../../../../../routes/paths';

import './FimAtendimento.css';

import ImagemFimAtendimento from './img/img-fim-atendimento.png';

// @mui

// eslint-disable-next-line import/no-cycle


// routes


import Iconify from "../../../../../components/iconify";
import anjoChegou from "../../SalaEspera/img/anjoChegou.webp";
// eslint-disable-next-line import/no-cycle

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


// ----------------------------------------------------------------------

type props = {
    Atendente: any;
    verHistorico: any;
};

export default function FimAtendimento({Atendente,verHistorico}:props) {

    const navigate = useNavigate();

    const encerrar = ()=>{
        navigate(PATH_DASHBOARD.dashfirst,{replace:true});
    }



    return (
        <>
            <CssBaseline/>
            <Container maxWidth="xl" className='GlobalChamadaEncerrada'>


                 <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="center"
                        className='DialogFimAtendimentoGeral'
                    >

                        <div className='TopoFimAtendimento'>
                            <IconButton onClick={encerrar}>
                                <Iconify icon="eva:arrow-ios-back-fill"  />
                            </IconButton>
                            <Typography>Ir para dashboard</Typography>
                        </div>

                        
                        <Grid
                        container
                        direction="row"
                        justifyContent="center"
                        alignItems="stretch"
                        >
                            <Grid item>
                                <Item className='imgFimdeAtendimentoItem'>
                                    <img src={ImagemFimAtendimento} width={350} className='imgFimAtendimento' alt='Fim do Atendimento' />
                                </Item>
                                <Item className='TituloFimAtendimentoItem'>
                                    <Typography component='div' className='TitulochatAnjoChegou'> <Iconify className='IconCheckAnjoChegouSalaDeEspera' icon="eva:checkmark-circle-2-fill"/>
                                    Seu atendimento chegou ao fim, mas fique atento as suas notificações, o herói pode fazer um novo pagamento e continuar a conversa com você</Typography>
                                </Item>
                                <Item>
                                    <Button variant='contained' onClick={encerrar} sx={{py: 1.5, px: 2.5}} > <Iconify icon='eva:power-outline' sx={{mr: 1}} /> Encerrar</Button>
                                </Item>
                                <Item>
                                    <Button variant='outlined' onClick={verHistorico} sx={{py: 1.5, px: 2.5}} > <Iconify icon='eva:message-square-outline' sx={{mr: 1}} /> Ver Historico</Button>
                                </Item>
                            </Grid>
                            
                        </Grid>
                            
                        

                        
                    
                    </Grid>


                
            </Container>

        </>
    );
}