import { useNavigate } from 'react-router-dom';
// @mui
import { List, SxProps, Button } from '@mui/material';
// routes
import { PATH_DASHBOARD } from '../../../../routes/paths';
// hooks
import useResponsive from '../../../../hooks/useResponsive';
// @types
import { IChatConversationsState } from '../../../../@types/chat';
// components
import { SkeletonConversationItem } from '../../../../components/skeleton';
//
import ChatNavItem from './ChatNavItem';



// ----------------------------------------------------------------------


type Props = {
    openNav: any;
    conversas: any;
    onCloseNav: any;
    tokenConversa: any;
};

export default function ChatNavList({openNav,conversas, onCloseNav,tokenConversa}:Props) {
  const navigate = useNavigate();

    const handleSelectConversation = (conversationId: string) => {
        window.open( `#${PATH_DASHBOARD.chat.view(conversationId)}`, '_blank');
        /* navigate(PATH_DASHBOARD.chat.view(conversationId)); */
    };


    const isDesktop = useResponsive('up', 'md');

   

  const loading = false;

  return (
    <List >
        {/* eslint-disable-next-line react/destructuring-assignment */}
        {conversas.map((conversa:any,index:any) => (
            <ChatNavItem openNav={openNav}  conversa={conversa}  key={index} tokenConversa={tokenConversa}
                         onSelect={() => {
                             if (!isDesktop) {
                                 onCloseNav();
                             }
                             handleSelectConversation(conversa.tokenConversa);
                         }}
                         lido={false}/>
        ))}
        

    </List>
  );
}
