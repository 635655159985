import { Dialog,DialogTitle ,Grid, DialogContent,ListItemText, Box, Rating, useMediaQuery ,ListItem,List, Divider, AppBar , Toolbar ,IconButton  ,Slider,Slide, Button, TextField, Tabs, Tab, InputAdornment, Paper, Typography} from "@mui/material";
import { TransitionProps } from '@mui/material/transitions';
import React, {useState} from "react";
import {styled, useTheme} from "@mui/material/styles";
import { CustomAvatar } from "src/components/custom-avatar";
import {useSettingsContext} from "../../../../components/settings";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";
import MenuPopover from "../../../../components/menu-popover";
import "./FiltrarAtendente.css";
import BadgeStatus, {BadgeStatusValue} from "../../../../components/badge-status";
import axios from "../../../../utils/axios";

const icon = <Iconify icon="eva:square-outline"/>;
const checkedIcon = <Iconify icon="eva:checkmark-square-2-fill"/>;


const labels: { [index: string]: string } = {
    0.5: '',
    1: '',
    1.5: '',
    2: '',
    2.5: 'Ok',
    3: 'Bom',
    3.5: 'Muito bom',
    4: 'Excelente',
    4.5: 'Espetacular',
    5: 'Atendimento Perfeito',
  };

  function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? 's' : ''}, ${labels[value]}`;
  }

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


  function valuetext(value: number) {
    return `${value}°C`;
  }

  const minDistance = 10;


type props = {
    setassunto: any,
    setquery: any,
    settipoprofissional: any,
    setgenero: any,
    setidade: any,
    setavaliacao: any,
    setpreco: any,
};



export default function FiltrarAtendente({setassunto,setquery,settipoprofissional,setgenero,setidade,setavaliacao,setpreco}:props) {


    const [value, setValue] = React.useState<number | null>(0);
  const [hover, setHover] = React.useState(-1);

    const [busca, setBusca] = useState(true);
    const [valuequery, setvaluequery] = useState('');


    const NovaBusca = () => {
        setBusca(true);
    }



    const [valueProfissional, setValueProfissional] = React.useState('1');
    const handleProficional = (event: React.SyntheticEvent, newValue: any) => {
        setValueProfissional(newValue);
        settipoprofissional(newValue);
    };

    const [valueAssunto, setValueAssunto] = React.useState('1');
    const handleAssunto = (event: React.SyntheticEvent, newValue: any) => {
        setValueAssunto(newValue);
    };

    const [valueGenero, setValueGenero] = React.useState('');
    const handleChange3 = (event: React.SyntheticEvent, newValue: any) => {
        setValueGenero(newValue);
    };


    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('lg'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        Buscar()
    };

    const [value1, setValue1] = React.useState<number[]>([20, 80]);

    const handleChangePreco = (
      event: Event,
      newValue: number | number[],
      activeThumb: number,
    ) => {
      if (!Array.isArray(newValue)) {
        return;
      }

      if (activeThumb === 0) {
        setValue1([Math.min(newValue[0], value1[1] - minDistance), value1[1]]);
      } else {
        setValue1([value1[0], Math.max(newValue[1], value1[0] + minDistance)]);
      }
    };


    const [value2, setValue2] = React.useState<number[]>([10, 58]);

    const handleChangeIdade = (
      event: Event,
      newValue: number | number[],
      activeThumb: number,
    ) => {
      if (!Array.isArray(newValue)) {
        return;
      }

      if (activeThumb === 0) {
        setValue2([Math.min(newValue[0], value2[1] - minDistance), value2[1]]);
      } else {
        setValue2([value2[0], Math.max(newValue[1], value2[0] + minDistance)]);
      }
    };

    const Buscar = () => {
        setBusca(false);

        setassunto(valueAssunto);
        setquery(valuequery);
        settipoprofissional(valueProfissional);
        setgenero(valueGenero);
        setidade(value2);
        setpreco(value1);
        setavaliacao(value);
    }

    const handleChangeSearch = async (event: React.ChangeEvent<HTMLInputElement>) => {
        try {

                setquery(event.target.value);

        } catch (error) {
            console.error(error);
        }
    };



    return (
        <>
            <div className="CardFiltrarAtendente">

                { busca === true ? (
                <div id="cardFiltroAtendenteAtivo">
                    <Typography className="TituloCardFiltroAtendente">Buscar um atendente</Typography>

                    <div className="TipoDeProfissionalScroll">
                        <Typography className="SubTituloCardFiltroAtendente">Tipo de Profissional</Typography>
                        <Tabs className="IconsTipoProfissional" value={valueProfissional} onChange={handleProficional} aria-label="icon tabs example">
                            <Tab className="IconTipoProfissional" value="1"  icon={<Iconify icon="eva:search-outline"/>} aria-label="phone" />
                            <Tab className="IconTipoProfissional" value="2"  icon={<Iconify icon="eva:search-outline"/>} aria-label="favorite" />
                            <Tab className="IconTipoProfissional"  value="3"  icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                            <Tab className="IconTipoProfissional"  value="4" icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                            <Tab className="IconTipoProfissional"  value="5" icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                            <Tab className="IconTipoProfissional"  value="6" icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                            <Tab className="IconTipoProfissional" value="7"  icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                            <Tab className="IconTipoProfissional" value="8"  icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                            <Tab className="IconTipoProfissional"  value="9"  icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                        </Tabs>
                    </div>


                    <div className="AssuntoScroll">
                        <Typography className="SubTituloCardFiltroAtendente">Busca por Assunto</Typography>
                        <Tabs className="IconsFiltroBuscaAssunto" value={valueAssunto} onChange={handleAssunto} aria-label="icon tabs example">
                            <Tab className="IconFiltroBuscaAssunto" value="1"  icon={<Iconify icon="eva:search-outline"/>} aria-label="phone" />
                            <Tab className="IconFiltroBuscaAssunto" value="2"   icon={<Iconify icon="eva:search-outline"/>} aria-label="favorite" />
                            <Tab className="IconFiltroBuscaAssunto"  value="3"  icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                            <Tab className="IconFiltroBuscaAssunto"  value="4"  icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                            <Tab className="IconFiltroBuscaAssunto"  value="5"  icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                            <Tab className="IconFiltroBuscaAssunto"  value="6"  icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                            <Tab className="IconFiltroBuscaAssunto"   value="7" icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                            <Tab className="IconFiltroBuscaAssunto"  value="8"  icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                            <Tab className="IconFiltroBuscaAssunto"   value="9" icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                        </Tabs>
                    </div>

                    <div className="CardInputFiltroPorNomeAtendente">

                        <TextField
                        id="outlined-search" label="Buscar por nome" type="search"
                        onChange={handleChangeSearch}
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <Iconify icon="eva:search-outline"/>
                                </InputAdornment>
                            ),
                        }}
                        />

                    </div>

                    <div className="CardBotaoFiltroAtendente">
                        <Button type="button" id="BotaoFiltroAtendente" onClick={Buscar}>Buscar</Button>
                    </div>

                    <div className="CardBotaoAvancado">
                        <Button type="button" id="BotaoFiltroAvancado" onClick={handleClickOpen}> <Iconify icon="eva:options-2-fill"/> Filtros Avançados</Button>
                    </div>

                </div>
                ) : (
                    <div className="CardBotaoFiltroAtendente">
                        <Button type="button" id="BotaoFiltroAtendenteNovaBusca" onClick={NovaBusca}>Nova Busca</Button>
                    </div>
                )}


    <div className="FiltrosAvancadoAtendentes">

        <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >

        <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        className="TopoFiltroAvancadoAtendente"
        >
             <Item>
                <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                    Filtros avançados
                </Typography>
            </Item>
             <Item>
                <Button autoFocus color="inherit" onClick={handleClose}>
                    <Iconify icon="eva:close-outline"/>
                </Button>
             </Item>
        </Grid>

        <div className="FiltrosProfissionaleAssunto">
            <div className="TipoDeProfissionalScroll">
                <Typography className="SubTituloCardFiltroAtendente">Tipo de Profissional</Typography>
                <Tabs className="IconsTipoProfissional" value={valueProfissional} onChange={handleProficional} aria-label="icon tabs example">
                    <Tab className="IconTipoProfissional" value="1"  icon={<Iconify icon="eva:search-outline"/>} aria-label="phone" />
                    <Tab className="IconTipoProfissional" value="2"  icon={<Iconify icon="eva:search-outline"/>} aria-label="favorite" />
                    <Tab className="IconTipoProfissional"  value="3"  icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                    <Tab className="IconTipoProfissional"  value="4" icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                    <Tab className="IconTipoProfissional"  value="5" icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                    <Tab className="IconTipoProfissional"  value="6" icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                    <Tab className="IconTipoProfissional" value="7"  icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                    <Tab className="IconTipoProfissional" value="8"  icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                    <Tab className="IconTipoProfissional"  value="9"  icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                </Tabs>
            </div>
            <div className="AssuntoScroll">
                <Typography className="SubTituloCardFiltroAtendente">Busca por Assunto</Typography>
                <Tabs className="IconsFiltroBuscaAssunto"  value={valueAssunto} onChange={handleAssunto} aria-label="icon tabs example">
                    <Tab className="IconFiltroBuscaAssunto" value="1"  icon={<Iconify icon="eva:search-outline"/>} aria-label="phone" />
                    <Tab className="IconFiltroBuscaAssunto" value="2"   icon={<Iconify icon="eva:search-outline"/>} aria-label="favorite" />
                    <Tab className="IconFiltroBuscaAssunto"  value="3"  icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                    <Tab className="IconFiltroBuscaAssunto"  value="4"  icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                    <Tab className="IconFiltroBuscaAssunto"  value="5"  icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                    <Tab className="IconFiltroBuscaAssunto"  value="6"  icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                    <Tab className="IconFiltroBuscaAssunto"   value="7" icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                    <Tab className="IconFiltroBuscaAssunto"  value="8"  icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                    <Tab className="IconFiltroBuscaAssunto"   value="9" icon={<Iconify icon="eva:search-outline"/>} aria-label="person" />
                </Tabs>
            </div>
        </div>


        <Divider/>

        <div className="FaixaDePrecoFiltroAvancado">
                <Typography className="SubTituloCardFiltroAtendente">Filtro de Preço</Typography>
                <Typography>Campo de 2 linhas para explicar como funciona o filtro de valores para pagamento.</Typography>

                <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="LineFiltroPrecoInputs"
                >
                    <Item>
                    <TextField
                        id="minPrecoFiltroAvancao"
                        defaultValue={value1[0]}
                        value={value1[0]}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="standard"
                    />
                    </Item>
                    <Item>
                    <TextField
                        id="maxPrecoFiltroAvancao"
                        defaultValue={value1[1]}
                        value={value1[1]}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="standard"
                    />
                    </Item>
                </Grid>

                <Slider
                    getAriaLabel={() => 'Minimum distance'}
                    value={value1}
                    onChange={handleChangePreco}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    disableSwap
                />
        </div>

        <Divider/>

        <div className="GeneroScroll">
                <Typography className="SubTituloCardFiltroAtendente">Busca por Gênero</Typography>
                <Tabs className="IconsFiltroBuscaGenero" value={valueGenero} onChange={handleChange3} aria-label="icon tabs example">
                    <Tab className="IconFiltroBuscaGenero" value="Feminino" label="Feminino" />
                    <Tab className="IconFiltroBuscaGenero"  value="Masculino" label="Masculino" />
                    <Tab className="IconFiltroBuscaGenero"  value="Indiferente" label="Indiferente" />

                </Tabs>
        </div>

        <Divider/>

        <div className="FaixaDeIdadeFiltroAvancado">
                <Typography className="SubTituloCardFiltroAtendente">Idade</Typography>

                <Grid
                container
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                className="LineFiltroIdadeInputs"
                >
                    <Item>
                    <TextField
                        id="minIdadeFiltroAvancao"
                        defaultValue={value2[0]}
                        value={value2[0]}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="standard"
                    />
                    </Item>
                    <Item>
                    <TextField
                        id="maxIdadeFiltroAvancao"
                        defaultValue={value2[1]}
                        value={value2[1]}
                        InputProps={{
                            readOnly: true,
                        }}
                        variant="standard"
                    />
                    </Item>
                </Grid>

                <Slider
                    getAriaLabel={() => 'Minimum distance'}
                    value={value2}
                    onChange={handleChangeIdade}
                    valueLabelDisplay="auto"
                    getAriaValueText={valuetext}
                    disableSwap
                />
        </div>

        <Divider/>

        <div className="Avaliacao">
                <Typography className="SubTituloCardFiltroAtendente">Avaliacao</Typography>
                <Box
                sx={{
                    width: 200,
                    display: 'flex',
                    alignItems: 'center',
                }}
                >
                <Rating
                    name="hover-feedback"
                    value={value}
                    precision={0.5}
                    getLabelText={getLabelText}
                    onChange={(event, newValue) => {
                    setValue(newValue);
                    }}
                    onChangeActive={(event, newHover) => {
                    setHover(newHover);
                    }}
                    emptyIcon={<Iconify icon="eva:star-fill"/>}
                />
                {value !== null && (
                    <Box sx={{ ml: 2 }}>{labels[hover !== -1 ? hover : value]}</Box>
                )}
                </Box>
        </div>

        <Divider/>

        <div className="btnsFiltrosAvancados">
            <Button  color="inherit" className="btnBuscarFiltroAvancado" onClick={handleClose}>
                <Typography>Buscar</Typography>
            </Button>

            <Button className="btnRemoverFiltrosFiltroAvancado" onClick={handleClose}>
                <Typography>Remover Filtros</Typography>
            </Button>
        </div>
      </Dialog>


    </div>



            </div>
        </>
    );
}
