
import { useState, useRef } from 'react';
// @mui
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Iconify from 'src/components/iconify/Iconify';
import { CustomAvatar } from "src/components/custom-avatar";
import { Box, Card, Paper,FormControl,InputLabel,MenuItem,Select,Rating , Button, Typography, CardContent, Divider, IconButton } from '@mui/material';
import { alpha, useTheme } from '@mui/material/styles';
import { PATH_DASHBOARD } from '../../../../routes/paths';
import ProfilePicture from '../img/profile_picture.png';
import {fDate, fHora, fDatacerta} from "../../../../utils/formatTime";


// utils

// components

// ----------------------------------------------------------------------


type props = {
    listaragendamentos: any;
};
export default function AgendamentoMarcado({listaragendamentos}:props) {

    const navigate = useNavigate();

    const agendamentounico = () => {
        navigate(PATH_DASHBOARD.detalhesagendamento(listaragendamentos.id));
    };


  return (
    <>
        <div className='PointData'>
            { listaragendamentos.Status === ('Finalizado') ? (<div className='PointAgendamento PontoStatusRealizado'> </div>) : (<></>) }
            { listaragendamentos.Status === ('Agendado') ? (<div className='PointAgendamento PontoStatusAgendado'> </div>) : (<></>) }
            { listaragendamentos.Status === ('Pendente') ? (<div className='PointAgendamento PontoStatusNaoConfirmado'> </div>) : (<></>) }
            { listaragendamentos.Status === ('Cancelado') ? (<div className='PointAgendamento PontoStatusCancelado'> </div>) : (<></>) }

            <Typography variant='subtitle1' sx={{mr: 1}}>{fHora(listaragendamentos.DataHoraInicio)} - {fHora(listaragendamentos.DataHoraFIm)} </Typography>
            <Typography variant='subtitle2' color='warning'>{fDatacerta(listaragendamentos.DataHoraInicio)}</Typography>
        </div>

        <div className='lineAgendamento'>

        <div className='CardAgendamento'>
            <div className='FotoNomeeStatusAgendamento'>
                <CustomAvatar
                    className="FotoPerfilAtendente"
                    src={listaragendamentos.avatar}
                    sx={{ cursor: 'pointer', width: 40, height: 40 }}
                />

                <div className='NomeeStatusAgendamento'>
                    <Typography className='NomeAgendamento'>{listaragendamentos.nome}</Typography>

                    { listaragendamentos.Status === ('Finalizado') ? (<Typography className='StatusAgendamento StatusTextRealizado'>Realizado</Typography>) : (<></>) }
                    { listaragendamentos.Status === ('Agendado') ? (<Typography className='StatusAgendamento StatusTextAgendado'>Agendado</Typography>) : (<></>) }
                    { listaragendamentos.Status === ('Pendente') ? (<Typography className='StatusAgendamento StatusTextNaoConfirmado'>Não Confirmado</Typography>) : (<></>) }
                    { listaragendamentos.Status === ('Cancelado') ? (<Typography className='StatusAgendamento StatusTextCancelado'>Cancelado</Typography>) : (<></>) }

                    


                </div>
            </div>

            <div>
                <Button variant='outlined'  onClick={agendamentounico}> Ver Detalhes <Iconify icon='eva:arrow-ios-forward-fill' sx={{ml: 0.5}} /> </Button>
            </div>
        </div>
        </div>
    </>
  );
}