// form
import { useFormContext, Controller } from 'react-hook-form';
// @mui
import { DatePicker } from '@mui/x-date-pickers';
import {Stack, TextField, MenuItem, TextFieldProps, FormControl,Typography, FormHelperText} from '@mui/material';
import {IMaskInput} from "react-imask";
import {useState} from "react";
import './hookform.css';
// components


// ----------------------------------------------------------------------

const STATUS_OPTIONS = ['paid', 'unpaid', 'overdue', 'draft'];

// ----------------------------------------------------------------------

type Props = TextFieldProps & {
    name: string;
};
export default function RHFtelefone({ name,label, helperText, ...other }: Props) {
    const { control, watch } = useFormContext();

    const values = watch();

    const [inputFoco, setInputFoco] = useState(false);

    const focusInput = () => {
        setInputFoco(true);
    }

    return (

            <Controller
                name={name}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <FormControl component="fieldset">
                        {field.value ? (<Typography className='TextoApoio'> {label} </Typography>) : ('')}
                                <IMaskInput
                                    className={`banner ${!error ? "inputmasktelefone" : "inputmasktelefoneerro"}`}
                                    type="text"
                                    value={field.value}
                                    mask="(00) 00000-0000"
                                    placeholder='Número do WhatsApp'
                                    label="Date create"
                                    onAccept={
                                        (valormascara, mask) => field.onChange(valormascara)
                                    }

                                />
                        {(!!error || helperText) && (
                            <FormHelperText error={!!error} sx={{ mx: 0 }} className='FormHelperTexttelefone'>
                                {error ? error?.message : helperText}
                            </FormHelperText>
                        )}
                    </FormControl>
                )}
            />

    );
}
