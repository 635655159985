import { useState } from 'react';
// @mui
import {
  Link,
  Stack,
  Button,
  Divider,
  Checkbox,
  TableRow,
  MenuItem,
  TableCell,
  IconButton,
  Typography,
} from '@mui/material';
// utils
import { fDate } from '../../../../../utils/formatTime';
import { fCurrency } from '../../../../../utils/formatNumber';
// @types
import { IInvoice } from '../../../../../@types/invoice';
// components
import Label from '../../../../../components/label';
import Iconify from '../../../../../components/iconify';
import { CustomAvatar } from '../../../../../components/custom-avatar';
import MenuPopover from '../../../../../components/menu-popover';
import ConfirmDialog from '../../../../../components/confirm-dialog';

// ----------------------------------------------------------------------

type Props = {
  row: IInvoice;
  selected: boolean;
  onSelectRow: VoidFunction;
  onViewRow: VoidFunction;
  onEditRow: VoidFunction;
  onDeleteRow: VoidFunction;
};

export default function InvoiceTableRow({
  row,
  selected,
  onSelectRow,
  onViewRow,
  onEditRow,
  onDeleteRow,
}: Props) {
  const {  created_at, DataConclusao, Status, heroi, Valor,taxa,id,Descrisao } = row;

  console.log(row);

  const [openConfirm, setOpenConfirm] = useState(false);

  const [openPopover, setOpenPopover] = useState<HTMLElement | null>(null);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };

  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };

  const handleOpenPopover = (event: React.MouseEvent<HTMLElement>) => {
    setOpenPopover(event.currentTarget);
  };

  const handleClosePopover = () => {
    setOpenPopover(null);
  };

  return (
    <>
      <TableRow hover selected={selected}>


        <TableCell align="left"> #{id}</TableCell>
        <TableCell align="left">

          <Label
              variant="soft"
              color={
                  (Descrisao === 'Saque' && 'success') ||
                  (Descrisao === 'Transferência Eyhe' && 'primary') ||
                  (Descrisao === 'Atendimento' && 'secondary') ||
                  'default'
              }
          >
            {Descrisao}
          </Label>
        </TableCell>

        <TableCell align="left">{fDate(created_at)}</TableCell>

        <TableCell align="left">{fDate(DataConclusao)}</TableCell>

        <TableCell align="center">{fCurrency(Valor)}</TableCell>

        <TableCell align="center" sx={{ textTransform: 'capitalize' }}>

          {(Descrisao === 'Saque' && '0.00') ||
              (Descrisao === 'Transferência Eyhe' && '0.00') ||
              (Descrisao === 'Atendimento' && fCurrency(taxa)) ||
              '0.00'}


        </TableCell>

        <TableCell align="left">
          <Label
            variant="soft"
            color={
              (Status === 'Concluido' && 'success') ||
              (Status === 'Pendente' && 'warning') ||
              'default'
            }
          >
            {Status}
          </Label>
        </TableCell>

        <TableCell align="right">
          <IconButton color={openPopover ? 'inherit' : 'default'} onClick={handleOpenPopover}>
            <Iconify icon="eva:more-vertical-fill" />
          </IconButton>
        </TableCell>
      </TableRow>

      <MenuPopover
        open={openPopover}
        onClose={handleClosePopover}
        arrow="right-top"
        sx={{ width: 160 }}
      >
        <MenuItem
          onClick={() => {
            onViewRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:eye-fill" />
          Ver
        </MenuItem>

        <MenuItem
          onClick={() => {
            onEditRow();
            handleClosePopover();
          }}
        >
          <Iconify icon="eva:edit-fill" />
          Editar
        </MenuItem>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem
          onClick={() => {
            handleOpenConfirm();
            handleClosePopover();
          }}
          sx={{ color: 'error.main' }}
        >
          <Iconify icon="eva:trash-2-outline" />
          Deletar
        </MenuItem>
      </MenuPopover>

      <ConfirmDialog
        open={openConfirm}
        onClose={handleCloseConfirm}
        title="Deletar"
        content="Tem certeza que deseja deletar?"
        action={
          <Button variant="contained" color="error" onClick={onDeleteRow}>
            Deletar
          </Button>
        }
      />
    </>
  );
}
