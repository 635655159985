// ----------------------------------------------------------------------

function path(root: string, sublink: string) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  login: '/login',
  esquecisenha: '/esquecisenha',
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page403: '/403',
  page404: '/404',
  page500: '/500',
  components: '/components',
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  dashfirst: path(ROOTS_DASHBOARD, '/dashfirst'),
  listagem: (tipo: string) => path(ROOTS_DASHBOARD, `/listagem/${tipo}`),
  conversas: path(ROOTS_DASHBOARD, '/conversas'),
  atendentes: path(ROOTS_DASHBOARD, '/atendentes'),
  salaespera: path(ROOTS_DASHBOARD, '/salaespera'),
  atendimentos: path(ROOTS_DASHBOARD, '/atendimentos'),
  avaliacoes: path(ROOTS_DASHBOARD, '/avaliacoes'),
  insights: path(ROOTS_DASHBOARD, '/insights'),
  financeiro: path(ROOTS_DASHBOARD, '/financeiro'),
  chamadaaudio: (id: string) => path(ROOTS_DASHBOARD, `/chamadaaudio/${id}`),
  chamadavideo:(id: string) =>  path(ROOTS_DASHBOARD, `/chamadavideo/${id}`),
  personalizarperfil: path(ROOTS_DASHBOARD, '/personalizarperfil'),
  editarperfil: path(ROOTS_DASHBOARD, '/editarperfil'),
  editarperfilprofissional: path(ROOTS_DASHBOARD, '/editarperfilprofissional'),
  verperfil: path(ROOTS_DASHBOARD, '/verperfil'),
  detalhesagendamento: (id: any) => path(ROOTS_DASHBOARD, `/detalhesagendamento/${id}`),
  rotina: path(ROOTS_DASHBOARD, '/rotina'),
  regras: path(ROOTS_DASHBOARD, '/regras'),
  cupons: path(ROOTS_DASHBOARD, '/cupons'),
  agendamentos: path(ROOTS_DASHBOARD, '/agendamentos'),
  pacotes: path(ROOTS_DASHBOARD, '/pacotes'),
  
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    four: path(ROOTS_DASHBOARD, '/user/four'),
    five: path(ROOTS_DASHBOARD, '/user/five'),
    six: path(ROOTS_DASHBOARD, '/user/six'),
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    view: (name: string) => path(ROOTS_DASHBOARD, `/chat/${name}`),
    video: (name: string) => path(ROOTS_DASHBOARD, `/video/${name}`),
    audio: (name: string) => path(ROOTS_DASHBOARD, `/audio/${name}`),
  },
};
