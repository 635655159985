import * as React from 'react';
import {CssBaseline, Box, Container, Typography, Tooltip, Grid, Paper  } from "@mui/material"; 
import {useEffect, useState, useCallback} from 'react';
import {useLocation, useNavigate, useParams} from 'react-router-dom';

import  "./saladeaudio.css";
// @mui
import { styled } from '@mui/material/styles';
import {useSettingsContext} from '../../../../components/settings';
// eslint-disable-next-line import/no-cycle

import CustomBreadcrumbs from '../../../../components/custom-breadcrumbs';
// routes
import {PATH_DASHBOARD} from '../../../../routes/paths';
import axios from "../../../../utils/axios";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";
// eslint-disable-next-line import/no-cycle
import HeaderSaladeAudio from "./HeaderSaladeAudio";
import RodapeSaladeAudio from "./RodapeSaladeAudio";
import Cronometro from "./Cronometro";
import {CustomAvatar} from "../../../../components/custom-avatar";
import BadgeStatus, {BadgeStatusValue} from "../../../../components/badge-status";

// ----------------------------------------------------------------------

const OT = require("@opentok/client");

// ----------------------------------------------------------------------

let session: any;
let publisher: any;
let subscriber: any;

type props = {
    EncerraAudio: any;
};

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));


export default function AudioAtivo({EncerraAudio}:props) {


    const {tokenConversa = ''} = useParams();
    const [usera,setuser] = useState<any>({});
    const [time, setTime] = useState(0);
    const [recording, setrecording] = useState(true);
    const [status, setStatus] = useState<BadgeStatusValue>('online');
    const navigate = useNavigate();


    useEffect(() => {

        if(!sessionStorage.getItem(`tokenConversa`)){
            navigate(0);
            sessionStorage.setItem(`tokenConversa`, '1');
        }

        setTimeout(() => {
            sessionStorage.removeItem(`tokenConversa`);
        }, 10000);


        let interval: any = null;

        if (recording) {
            interval = setInterval(() => {
                // eslint-disable-next-line @typescript-eslint/no-shadow
                setTime((time) => time + 1000);
            }, 1000);
        } else {
            clearInterval(interval);
        }
        return () => {
            clearInterval(interval);
        };
    }, [recording,navigate]);




    useEffect(  () => {
        const fetchRepos2 = async () => {
            const response = await axios.get(`/areadoatendente/atendente/${tokenConversa}`);

            const {atendente} = response.data;


            setuser(atendente)
        }
        fetchRepos2();

    },[tokenConversa]);


    useEffect(  () => {

        const fetchRepos = async () => {

            try {
                const response = await axios.post(`/areadoatendente/gettokentokbox`,{id:tokenConversa});


                console.log(response.data);
                const {apiKey} = response.data;
                const {sessionId} = response.data;
                const {token} = response.data;


               
            
                // eslint-disable-next-line react-hooks/exhaustive-deps
                session = OT.initSession(apiKey, sessionId);
                // create a publisher
                // eslint-disable-next-line react-hooks/exhaustive-deps
                publisher = OT.initPublisher(
                    "publisheraudio",
                    {
                        insertMode: "append",
                        width: "100%",
                        height: "100%",
                        style: {buttonDisplayMode: 'off'},
                        showControls: true,
                        publishAudio: true,
                        publishVideo: false

                    }
                );
                // subscribe to newly created stream
                session.on("streamCreated", (event: any) => {
                    // eslint-disable-next-line react-hooks/exhaustive-deps
                    subscriber = session.subscribe(
                        event.stream,
                        "subscriberaudio",
                        {
                            insertMode: "append",
                            width: "100%",
                            height: "100%",
                            style: {buttonDisplayMode: 'off'},
                            showControls: true,
                            publishAudio: true,
                            publishVideo: false
                        }
                    );
                });

                // connect to the session
                session.connect(token, (error: any) => {
                    // If the connection is successful, publish to the session
                    if (error) { /* empty */
                    } else {
                        session.publish(publisher);
                    }
                });
                // do some action upon destroying the created stream
                session.on("streamDestroyed", (event: any) => {
                    console.log("The Video chat has ended");
                });




            } catch (error) {
                console.error(error);
            }
        };

        fetchRepos();
    },[tokenConversa] );

    const Encerrarchamada = () =>{
        sessionStorage.setItem(`conversafechada`, '1');
        EncerraAudio('Encerra');
    }
    const Cameraon =  () =>{
        publisher.publishVideo(true);

    }

    const Cameraoff = () =>{
        publisher.publishVideo(false);
    }
    const Microfoneon = () =>{
        publisher.publishAudio(true);
    }
    const Microfoneoff = () =>{
        publisher.publishAudio(false);
    }

    const Texto = () =>{

    }
    const UrlVoltar = () =>{
        sessionStorage.setItem(`conversafechada`, '1');
        EncerraAudio('Encerra');
    }

    return (
            <>
            <HeaderSaladeAudio UrlVoltar={UrlVoltar}/>
            <CssBaseline />
            <Container maxWidth="xl">
                <Box sx={{ bgcolor: '#606060', height: '100vh', width: '100vw'}}>

                <div className='containerInterno'>
                    <CustomAvatar
                        src={usera?.avatar}
                        alt={usera?.nome}
                        name={usera?.nome}
                        BadgeProps={{
                            badgeContent: <BadgeStatus status={status} />,
                        }}
                        sx={{ cursor: 'pointer', width: 120, height: 120 }}
                    />
                        
                            <div id='publisheraudio' />
                            <div id='subscriberaudio' />
                        <div className='InfosChamada'>                    
                            <Typography className='NomeUser'>{usera?.nome}</Typography>
                            <Typography className='TituloChamada'>Chamada de áudio</Typography>
                        </div>

                        <div className='IconCall'>
                            <div className='GreyMaior'> </div>
                            <div className='GreyMenor'> </div>
                            <Iconify icon="eva:phone-fill"/>
                        </div>

                        <div className='Cronometro'>
                         <Cronometro time={time}/>
                        </div>    


                    </div>
                   
                </Box>
            </Container>

            
            <RodapeSaladeAudio Encerrarchamada={Encerrarchamada} Cameraon={() => Cameraon()}  Cameraoff={() => Cameraoff()} Microfoneon={Microfoneon} Microfoneoff={Microfoneoff} Texto={Texto}/>
            </>
    );
}
