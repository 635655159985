import {Divider, IconButton,Button, List, MenuItem, Paper, Box, Grid, Badge, Avatar, Select, Stack, Rating, Tooltip, Typography} from "@mui/material";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import React, {useState} from "react";
import {styled} from "@mui/material/styles";
import { CustomAvatar } from "src/components/custom-avatar";
import {useSettingsContext} from "../../../../components/settings";
import {useAuthContext} from "../../../../auth/useAuthContext";
import Iconify from "../../../../components/iconify";
import MenuPopover from "../../../../components/menu-popover";

import BadgeStatus, {BadgeStatusValue} from "../../../../components/badge-status";

import AvatarImage from "./profile_picture.jpg"



const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));

  const StyledBadge = styled(Badge)(({ theme }) => ({
    '& .MuiBadge-badge': {
      backgroundColor: '#44b700',
      color: '#44b700',
      boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
      '&::after': {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        borderRadius: '50%',
        animation: 'ripple 1.2s infinite ease-in-out',
        border: '1px solid currentColor',
        content: '""',
      },
    },
    '@keyframes ripple': {
      '0%': {
        transform: 'scale(.8)',
        opacity: 1,
      },
      '100%': {
        transform: 'scale(2.4)',
        opacity: 0,
      },
    },
  }));
  
  const SmallAvatar = styled(Avatar)(({ theme }) => ({
    width: 22,
    height: 22,
    border: `2px solid ${theme.palette.background.paper}`,
  }));

type props = {
    atendente: any;
};


export default function Atendente({atendente}:props) {
    const [status, setStatus] = useState<BadgeStatusValue>('online');
    function currencyFormat(num:any) {
        if(num == null){ return `R$0,00`;}
        num = parseFloat(num.replace(",", "."));

        return `R$${  num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}`
    }

    return (
        <>

            <div className='cardAtendente'>
                <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
                >
                    <Item className="ItemAtendentePerfil">

                        {/* FOTO DE PERFIL DO ATENDENTE */}
                        <CustomAvatar
                            className="FotoPerfilAtendente"
                            src={atendente?.avatar}
                            alt={atendente?.nome}
                            name={atendente?.nome}
                            BadgeProps={{
                                badgeContent: <BadgeStatus status={status} />,
                            }}
                            sx={{ cursor: 'pointer', width: 40, height: 40 }}
                        />

                        {/* NOME, ASSUNTOS E AVALIAÇÕES DO ATENDENTE */}
                        <div className="NomeETemasAtendente">
                            <div className="IndicadorTipoProfissionalAtendente">{atendente?.tipoAtedente}</div>
                            <Typography className="NomeDoAtendente">{atendente?.nome}</Typography>
                            <div className="AssuntosAtendente">
                                {atendente?.assuntos.map((assunto:any,index:any) => (
                                <Typography className="Assunto" key={index}> {assunto.NomeAssunto} </Typography>
                                ))}
                            </div>

                            {/* AVALIAÇÕES DO ATENDENTE */}
                            <div className="AvaliacaoAtendimento">
                                <Rating className="AvaliacaoAtendente" name="read-only" value={atendente?.estrelas} readOnly />
                                <Typography className="AvaliacaoAtendenteTexto">{atendente?.avaliacoes} avaliações</Typography>
                            </div>
                        </div>
                    </Item>    

                    {/* VALORES */}
                    <Item>
                        <div className="ValorETempoAtendimento">
                            <Typography className="ValorAtendimento">{currencyFormat(atendente?.ValorAtendimento)}</Typography>
                            <Typography className="TempoAtendimento">20 min</Typography>
                        </div>
                    </Item>
                </Stack>

                {/* BOTÕES DE AÇÃO */}
                <Box sx={{ width: 1 }}>
                    <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2} className="BotoesAcao">
                        <Box gridColumn="span 8">
                            <Item>
                                <Button className="BtnAzulAtendente">Conversar</Button>
                            </Item>
                        </Box>
                        <Box gridColumn="span 4">
                            <Item>
                            <Button  className="BtnBrancoAtendente">Ver Perfil</Button>
                            </Item>
                        </Box>
                    </Box>
                </Box>
            </div>
        </>
    );
}
