import React, { useState, useCallback, useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/pt-br';
import { Grid, Box, Paper, Card, IconButton, Typography, styled, Button } from '@mui/material';
import Iconify from 'src/components/iconify';
import CustomBreadcrumbs from '../../../components/custom-breadcrumbs';
import { PATH_DASHBOARD } from '../../../routes/paths';
import axios from '../../../utils/axios';
import ChatNavItem from '../chat/nav/ChatNavItem';
import WeeklyCalendar from './componenteAgendamento/calendario';

import CardAgendamento from './componenteAgendamento/cardAgendamento';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));
// ----------------------------------------------------------------------

export default function Agendamentos() {
  const navigate = useNavigate();

  const pageDash = () => {
    navigate(PATH_DASHBOARD.dashfirst);
  };

  const [diafiltro, setdia] = useState<any>();
  const [agendamentosdados, setagendamentosdados] = useState<any>([{ dados: [] }]);

  const mudardia = (dia: any) => {
    setdia(dia);
    console.log(dia);
  };

  const getagendamentos = useCallback(async () => {
    try {
      const response = await axios.post('/areadoatendente/agendamentos', {
        diafiltro,
      });

      const { agendamentos } = response.data;

      setagendamentosdados(agendamentos);
    } catch (error) {
      console.error(error);
    }
  }, [setagendamentosdados, diafiltro]);

  useEffect(() => {
    getagendamentos();
  }, [getagendamentos]);

  return (
    <>
      <Box sx={{ p: 2.5 }}>
        <Button
          variant="text"
          onClick={() => {
            navigate(PATH_DASHBOARD.dashfirst, { replace: true });
          }}
        >
          {' '}
          <Iconify icon="eva:arrow-ios-back-outline" /> Dashboard{' '}
        </Button>

        <CustomBreadcrumbs
          heading="Agendamentos"
          links={[
            {
              name: 'Dashboard',
              href: PATH_DASHBOARD.root,
            },
            {
              name: 'Agendamentos',
              href: PATH_DASHBOARD.root,
            },
          ]}
        />

        <Card sx={{ p: 2 }} className="AgendamentosAtendente">
          <Grid container spacing={1}>
            <Grid item md={6} xs={12}>
              <Item>
                <WeeklyCalendar mudardia={mudardia} />
              </Item>
            </Grid>
          </Grid>

          <Grid container>
            {agendamentosdados.length > 0
              ? agendamentosdados.map((agendamento: any, index: any) => (
                  <div key={index}>
                    <Grid item md={12} xs={12}>
                      <Item>
                        <Typography variant="subtitle2" sx={{ textAlign: 'left' }}>
                          {agendamento.datanormal}
                        </Typography>
                      </Item>
                    </Grid>
                    {agendamento.dados.map((agendamento2: any, index2: any) => (
                      <Grid item md={3} xs={12} key={index2} className="cardAgendamentoAtendente">
                        <Item>
                          {' '}
                          <CardAgendamento agendamento={agendamento2} />{' '}
                        </Item>
                      </Grid>
                    ))}
                  </div>
                ))
              : 'Nenhum agendamento encontrado.'}
          </Grid>
        </Card>
      </Box>
    </>
  );
}
